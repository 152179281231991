import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import './Invoice.css'
import config from "../../../functions/config";
import { useLocation } from "react-router-dom";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function InvoiceEdit() {
  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
     const [errors4, setErrors4] = useState({ party: '' });
  const ID = Cookies.get("user_id");
  const user_id = ID
  const [state_of_supply, setStateOfSupply] = useState('');
    const [parties, setParties] = useState([]); // State to hold party names
    const [selectedParty, setSelectedParty] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    const [invoiceNo, setInvoiceNo] = useState("");
    const [items, setItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [activeTab, setActiveTab] = useState('pricing');
  const [taxAmount, setTaxAmount] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [description, setDescription] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedState, setSelectedState] = useState("");
const [isModal1Open, setModal1Open] = useState(false);
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(location.state?.invoice || {});
  const [rows, setRows] = useState([]);
 const [count,setCount] = useState(0)
  const[unit,setUnit] = useState('')
  const [formData1, setFormData1] = useState({ gstin: '' });
  const [error, setError] = useState('');

  // Function to validate GSTIN
  const validateGSTIN = (gstin) => {
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
    return gstinRegex.test(gstin);
  };
   const calculateInvoiceData = () => {
    let subtotal = 0;
    let totalTaxAmount = 0;

    rows.forEach((row) => {
      const discountAmount = (row.quantity * row.rate * row.discount) / 100;
      const rowTotal = row.quantity * row.rate - discountAmount;
      const rowTax = (rowTotal * row.tax) / 100;

      subtotal += rowTotal;
      totalTaxAmount += rowTax;
    });

    const cgst = totalTaxAmount / 2; // Assuming CGST and SGST are equal
    const sgst = totalTaxAmount / 2;
    const grandtotal = subtotal + totalTaxAmount + invoiceData.adjustment;

    setInvoiceData((prev) => ({
      ...prev,
      subtotal,
      cgst,
      sgst,
      total_taxamount: totalTaxAmount,
      grandtotal,
    }));
  };
  useEffect(() => {
    setSelectedPayment(invoiceData.paymenttype || ""); // Set initial value
  }, [invoiceData.paymenttype]); 
  // const [stateOfSupply, setStateOfSupply] = useState("State");
  // const handleStateOfSupplyChange = (e) => {
  //   const stateType = e.target.value; // Get the selected state type
  //   // setStateOfSupply(stateType);  // Update stateOfSupply state
  //   // console.log("state value", stateType);
  //   updateTaxValues(stateType);  // Update tax values dynamically
  //   setInvoiceData((prevData) => ({
  //     ...prevData,
  //     state_of_supply: stateType, // Update the state_of_supply in invoiceData
  //   }));
  
  //   setStateOfSupply(stateType); // Update stateOfSupply
  //   console.log("State value:", stateType);
  
  //   updateTaxValues(stateType); 
  // };
  const handleStateOfSupplyChange = (e) => {
    const stateType = e.target.value; // Get the selected state type
  
    // Update state_of_supply in invoiceData
    setInvoiceData((prevData) => ({
      ...prevData,
      state_of_supply: stateType,
    }));
    // invoiceData.state_of_supply=
    // Update the tax values dynamically based on the selected state
    updateTaxValues(stateType);
    console.log("kkkk",invoiceData.state_of_supply)
  };
  
  const updateTaxValues = (stateType) => {
    const updatedRows = rows.map((row) => {  // Always base updates on fetchedRows
      console.log(stateType);
      const taxValue = stateType === "State"
        ? row.item__item_gst.replace(/\D/g, "") 
        : row.item__item_igst.replace(/\D/g, "")  ;
      console.log("tax value is",taxValue)
      // const taxLabel = stateType === "State"
      //   ? `GST${taxValue}[${taxValue}%]`
      //   : `IGST${taxValue}[${taxValue}%]`;
      const taxLabel = stateType === "State"
      ? `GST${taxValue || 0}[${taxValue || 0}%]`
      : `IGST${taxValue || 0}[${taxValue || 0}%]`;
    
  
      return {
        ...row,
        tax: taxLabel, // Update the tax label in the row
      };
    });
  
    setRows(updatedRows); // Update the rows with new tax values
    console.log("Updated Rows:", updatedRows);
  
    // Recalculate totals
    let totalSubTotal = 0;
    let totalTax = 0;
  console.log("sssss",updatedRows)
    updatedRows.forEach((row) => {
      const itemTotal = (row.rate * row.quantity) - row.discount;
      totalSubTotal += itemTotal;
      console.log("total_sub",totalSubTotal)
      const match = row.tax.match(/(\d+)(?=%)/); // Extract the tax percentage
      const taxRate = match ? parseInt(match[0], 10) : 0;
      console.log(taxRate)
      const taxAmountForRow = (itemTotal * taxRate) / 100;
      totalTax += taxAmountForRow;
      console.log("total_tax",totalTax)
    });
    console.log("total_tax",totalTax)
    const totalCgst = totalTax / 2;
    const totalSgst = totalTax / 2;
  
    const totalAdjustment = parseFloat(adjustment) || 0;
    const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;
   invoiceData.sgst=totalCgst
   invoiceData.cgst=totalCgst
   invoiceData.total_taxamount=totalTax
   console.log(totalGrandTotal)
   invoiceData.grandtotal=totalGrandTotal
  
   console.log("ghjskd",invoiceData.sgst)
    setSubTotal(totalSubTotal);
    setCgst(totalCgst);
    setSgst(totalSgst);
    setTaxAmount(totalTax);
    setGrandTotal(totalGrandTotal);
  };
  
  // Update tax values based on state type (State or OtherState)
//   const updateTaxValues = (stateType) => {
//     const updatedRows = rows.map((row) => {
//       console.log(stateType)
//       const taxValue = stateType === "State"
//       ?  row.item__item_gst :
//       row.item__item_igst ;
//       console.log("tax is ",taxValue)
//       const taxLabel = stateType === "State"
//         ? `GST${taxValue}[${taxValue}%]`
//         : `IGST${taxValue}[${taxValue}%]`;
  
//       return {
//         ...row,
//         tax: taxLabel,  // Update the tax label in the row
//       };
      
//     });
    
//     setRows(updatedRows);
//     console.log("rows",updatedRows)
//      // Set updated rows state if needed
//      let totalSubTotal = 0;
//      let totalTax = 0;
  
//      // Calculate SubTotal and total tax
//      rows.forEach((row) => {
//       console.log("hjkas",row.item_sale_price)
//        const itemTotal = (row.item_sale_price * row.qty) - row.discount; // Calculate item total
//        totalSubTotal += itemTotal;
//        // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, ''));
//        // const taxAmountForRow = (itemTotal * row.tax) / 100;
//        // const taxValue = parseFloat(row.tax.match(/(\d+)(?=%)/)[0]);
//   //       const taxMatch = row.tax.match(/(\d+)(?=%)/); // Match digits before %
//   // const taxValue = taxMatch ? parseFloat(taxMatch[0]) : 0;
//   // // Calculate tax amount
//   // print("//taxmatch",taxMatch)
//        const text = row.tax;
  
//        const match = text.match(/([a-zA-Z]+)(\d+)\[/); // Match letters followed by digits ending with '['
//        const result = match ? parseInt(match[2], 10) : 0; // Extract the digits (group 2)
  
//        console.log("bnbnl",result);
//   // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, '')) || 0;
//        const taxAmountForRow = (itemTotal * result) / 100;
//        totalTax += taxAmountForRow;
//      });
  
//      // Calculate CGST and SGST (both are half of total tax)
//      const totalCgst = totalTax / 2;
//      const totalSgst = totalTax / 2;
  
//      // Calculate Grand Total
//      const totalAdjustment = parseFloat(adjustment) || 0;
//      const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;
  
//      // Set state with calculated values
//      setSubTotal(totalSubTotal);
//      setCgst(totalCgst);
//      setSgst(totalSgst);
//      setTaxAmount(totalTax);
//      setGrandTotal(totalGrandTotal);
//   };
//   // Fetch related `SalesInvoiceItem` rows if not passed through state
//   // const handleStateOfSupplyChange = (e) => {
//   //   const stateType = e.target.value; // Get the selected state type
//   //   setStateOfSupply(stateType);  // Update stateOfSupply state
//   //   console.log("state value", stateType);
//   //   updateTaxValues(stateType);  // Update tax values dynamically
//   // };
// //   const handleStateOfSupplyChange = (e) => {
//     const stateType = e.target.value;
  
//     // Update both invoiceData and stateOfSupply
//     setInvoiceData((prevData) => ({
//       ...prevData,
//       state_of_supply: stateType, // Update the state_of_supply in invoiceData
//     }));
  
//     setStateOfSupply(stateType); // Update stateOfSupply
//     console.log("State value:", stateType);
  
//     updateTaxValues(stateType); // Update tax values dynamically
//   };
//   const [taxLabel, setTaxLabel] = useState([]);
//   // Update tax values based on state type (State or OtherState)
//   const updateTaxValues = (stateType) => {
//     const updatedRows = rows.map((row) => {
//       console.log("rows+++",row)
//       console.log(row.tax)
//       const taxValue = row.tax.startsWith("IGST") 
//                         ? row.item__item_gst  // If tax starts with "IGST"
//                         : row.item__item_igst; 
//       // const taxValue = stateType === "State"
//       //   ? (row.gst && !isNaN(row.gst.replace('%', '')) ? row.gst.replace('%', '') : '0')
//       //   : (row.igst && !isNaN(row.igst.replace('%', '')) ? row.igst.replace('%', '') : '0');
//   console.log(taxValue)
//   const tax = parseFloat(taxValue.toString().replace(/[^0-9.]/g, ''));
//   console.log(tax)
//   // const taxLabel=useState([]);


//   if (taxValue[0] === "I") {
//     setTaxLabel(`IGST${tax}[${tax}%]`)
       
//   }
//   else{
// setTaxLabel(`GST${tax}[${tax}%]`)
//   }
//       return {
//         ...row,
//         invoiceData.total_taxamount: taxLabel,
//         // invoiceData.total_taxamount: taxLabel,  // Update the tax label in the row
//       };
      
//     });
    
//     setRows(updatedRows);
//     console.log("rows",updatedRows)
//      // Set updated rows state if needed
//      let totalSubTotal = 0;
//      let totalTax = 0;
  
//      // Calculate SubTotal and total tax
//      rows.forEach((row) => {
//       console.log("hjkas",row.item_sale_price)
//        const itemTotal = (row.item_sale_price * row.qty) - row.discount; // Calculate item total
//        totalSubTotal += itemTotal;
//        // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, ''));
//        // const taxAmountForRow = (itemTotal * row.tax) / 100;
//        // const taxValue = parseFloat(row.tax.match(/(\d+)(?=%)/)[0]);
//   //       const taxMatch = row.tax.match(/(\d+)(?=%)/); // Match digits before %
//   // const taxValue = taxMatch ? parseFloat(taxMatch[0]) : 0;
//   // // Calculate tax amount
//   // print("//taxmatch",taxMatch)
//        const text = row.tax;
  
//        const match = text.match(/([a-zA-Z]+)(\d+)\[/); // Match letters followed by digits ending with '['
//        const result = match ? parseInt(match[2], 10) : 0; // Extract the digits (group 2)
  
//        console.log("bnbnl",result);
//   // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, '')) || 0;
//        const taxAmountForRow = (itemTotal * result) / 100;
//        totalTax += taxAmountForRow;
//      });
  
//      // Calculate CGST and SGST (both are half of total tax)
//      const totalCgst = totalTax / 2;
//      const totalSgst = totalTax / 2;
  
//      // Calculate Grand Total
//      const totalAdjustment = parseFloat(adjustment) || 0;
//      const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;
  
//      // Set state with calculated values
//      setSubTotal(totalSubTotal);
//      setCgst(totalCgst);
//      setSgst(totalSgst);
//      setTaxAmount(totalTax);
//      setGrandTotal(totalGrandTotal);
//   };
  // useEffect(() => {
  //   if (invoiceData.id) {
  //     console.log("Invoice ID:", invoiceData.id);
  //     console.log("Invoice ID:", invoiceData.party_name);
  //     // fetch(`/api/invoices/${invoiceData.id}/items/`)
  //     fetch(`${config.base_url}api/invoices/${invoiceData.id}/items/`)

  //       .then((res) => {
  //         if (!res.ok) {
  //           throw new Error(`HTTP error! Status: ${res.status}`);
  //         }
  //         return res.json();
  //       })
  //       .then((data) => {
         

  //         setRows(data);
  //         console.log("Fetched Items:", data);
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching items:", err);
  //         // setError("Failed to fetch items. Please try again.");
  //       });
  //   }
  // }, [invoiceData]);
  const [fetchedRows, setFetchedRows] = useState([]);

  useEffect(() => {
    async function fetchdata() {

      if (invoiceData.id) {

        console.log("Invoice ID:", invoiceData.id);
        console.log("Invoice Party Name:", invoiceData.party_name);
        try{

        const response = await axios.get(`${config.base_url}api/invoices/${invoiceData.id}/items/`);

        // const response=await axios.get(`${config.base_url}api/invoices/${invoiceData.id}/items/`)
        
        console.log("///",response)
        if (response.status===200)
        {
          setFetchedRows(response.data);  // Store fetched rows separately
            setRows(response.data);         // Initialize rows with fetched data
            console.log("Fetched Items:", response.data);
            console.log("supply",invoiceData.state_of_supply)
            
        }
        else{
          console.error("Error fetching items:");
        }
      }
      catch(err){
        console.error("Error fetching items:", err);
      }
        // fetch(`${config.base_url}api/invoices/${invoiceData.id}/items/`)
          // .then((res) => {
          //   if (!res.ok) {
          //     throw new Error(`HTTP error! Status: ${res.status}`);
          //   }
          //   return res.json();
          // })
          // .then((data) => {
          //   setFetchedRows(data);  // Store fetched rows separately
          //   setRows(data);         // Initialize rows with fetched data
          //   console.log("Fetched Items:", data);
          //   console.log("supply",invoiceData.state_of_supply)
          // })
          // .catch((err) => {
          //   console.error("Error fetching items:", err);
          // });
      }

    }
    fetchdata()
  }, [invoiceData.id]);  // Only fetch when ID changes
  
  // const [rows, setRows] = useState([]);
  useEffect(() => {
    calculateTotals();
  }, [rows]);
  const calculateTotals = () => {
    let totalSubTotal = 0;
    let totalTax = 0;

    // Calculate SubTotal and total tax
    rows.forEach((row) => {
      const itemTotal = (row.rate * row.quantity) - row.discount; // Calculate item total
      totalSubTotal += itemTotal;
      const taxAmountForRow = (itemTotal * row.item_tax) / 100;
      totalTax += taxAmountForRow;
    });

    // Calculate CGST and SGST (both are half of total tax)
    const totalCgst = totalTax / 2;
    const totalSgst = totalTax / 2;

    // Calculate Grand Total
    const totalAdjustment = parseFloat(adjustment) || 0;
    const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;

    // Set state with calculated values
    setSubTotal(totalSubTotal);
    setCgst(totalCgst);
    setSgst(totalSgst);
    setTaxAmount(totalTax);
    setGrandTotal(totalGrandTotal);
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  const handleCloseModalUnit = () => {
    setAddUnit(""); // Clear the input value
    // setModal1Open1(false);
    setModal1Open(false)
    setErrors5({
      addunit:''
       // Reset other error fields as needed
     });
  };
  const handleSaveItem = async(e,id)=>{
    // if(valid===false){
        e.preventDefault();
        const user = Cookies.get("role");
        var is_company = false;
        if (user === "Company") {
          is_company = true;
        }
        const ID = Cookies.get("user_id");
        const user_id = ID
    const data={
        item_name: itemName,
        item_hsn: hsn,
        item_sac:sac,
        done_by_name:user_id,
        item_type: itemType,
        item_unit: ItemData.unit_name,
        item_gst: gst,
        item_igst: igst,
        item_sale_price: isTaxable ? salesPrice : nonTaxableSalePrice, // Use conditional value
        item_purchase_price: isTaxable ? purchasePrice : nonTaxablePurchasePrice, // Use conditional value
        item_opening_stock: stockInHand,
        item_current_stock: stockInHand,
        item_at_price: atPrice,
        item_min_stock_maintain: minStockMaintain,
        item_date: date,
        trans_qty: stockInHand, 
        // company_id:user_id,

        // staff_id:user_id,
        role:user
    }
    console.log(data)
    const missingFields = [];

  if (!itemName) missingFields.push("Item Name");
  if (!hsn && !sac) missingFields.push("HSN or SAC");
  if (hsn && sac) missingFields.push("Only one of HSN or SAC should be entered (not both)");
  if (!itemType) missingFields.push("Item Type");
  // if (!gst && !igst) missingFields.push("Tax (GST or IGST)");
  // if (!salesPrice && !nonTaxableSalePrice) missingFields.push("Sales Price (Taxable or Non-Taxable)");
  // if (!purchasePrice) missingFields.push("Purchase Price");
  if (isTaxable && !gst && !igst) {
    missingFields.push("Tax (GST or IGST)");
  }
  
  // Validate sales price based on taxable or non-taxable
  if (isTaxable && !salesPrice) {
    missingFields.push("Sales Price (Taxable)");
  } else if (!isTaxable && !nonTaxableSalePrice) {
    missingFields.push("Sales Price (Non-Taxable)");
  }
  
  // Validate purchase price based on taxable or non-taxable
  if (isTaxable && !purchasePrice) {
    missingFields.push("Purchase Price (Taxable)");
  } else if (!isTaxable && !nonTaxablePurchasePrice) {
    missingFields.push("Purchase Price (Non-Taxable)");
  }
  if (!stockInHand) missingFields.push("Stock in Hand");
  if (!atPrice) missingFields.push("At Price");
  // if (!minStockMaintain) missingFields.push("Minimum Stock to Maintain");
  if (!date) missingFields.push("Date");

  if (missingFields.length > 0) {
    alert(`Please fill the following fields before saving:\n- ${missingFields.join("\n- ")}`);
    return; // Stop execution if validation fails
  }
  if (
    Object.values(errors).some(error => error !== "Valid") && 
    Object.values(errors3).some(error => error !== "")
) {
    alert("Please resolve the errors before saving.");
    return; // Stop execution if there are errors
}
    try {
        const response = await axios.post(`${config.base_url}add_item_detail`,data,{
          headers:{
            'Content-Type':'multipart/form-data',
          }
        });
        console.log(response)
        if(response.data.status===200){
          console.log(response)
          // navigate('/invoice')
          // window.location.reload(); 
          // await 
          fetchItems();
          // setShowModalItem1(false); 
          closeModal1()

          alert("Item details added successfully!");
          setErrors({
            hsn: "",
            sac: "",
            // Reset other error fields as needed
          });
          setErrors3({
            itemName: ''
           // Reset other error fields as needed
         });
          // setCount(id)
          // toast.success("Item Added Successfully!",{
          //   autoClose:1000,
          //   position:'top-right',
          // });
        }
        else{
          console.log("error1")
         
        }
        
      } catch (err) {
        console.log("error2",err)
        alert("failed to save invoice");
        setShowModalItem3(false);
    setItemName('')
setHsn("");
setSac('')
setItemType('')
setGst('')
setIgst('')
setSalesPrice(0)
setPurchasePrice(0)
setAtprice(0)
setStockInHand(0)
setminStockMaintain(0)
// setUnit('')
setnonTaxablePurchasePrice(0)
setnonTaxableSalePrice(0)
setErrors({
  hsn: "",
  sac: "",
  // Reset other error fields as needed
});
setItemData({
  item_name: "",
item_hsn: "",
item_type: "",
unit_name:"",
opening: "",
at_price: "",
date: "",
min_stock: "",
igst:"",gst:"",
taxable:"",
gst:"",
igst:"",
sale_price:"",
purchase_price: "",
unit_name:"",
});
        // toast.error("Item cannot be added",{
        //   autoClose:1000,
        //   position: "top-right",
        // })
      }
    // }else{
    //     console.log("error3")
    //     // toast.error("Item not added",{
    //     //   autoClose:1000,
    //     //   position: "top-right",
    //     // })

    // }
    
}
//14-01-2025
const navigateToInvoice = true; // Example value
// const handleGSTChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({ ...formData, [name]: value });

//   if (name === "gstin") {
//     if (isValidGSTIN(value)) {
//       setErrors({ ...errors, gstin: "" });
//     } else {
//       setErrors({
//         ...errors,
//         gstin: "Invalid GSTIN format. Example: 29ABCDE1234F2Z5",
//       });
//     }
//   }
// };
const handleGSTChange = async (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });

  if (name === "gstin") {
      // Validate GSTIN format
      if (isValidGSTIN(value)) {
          setErrors({ ...errors, gstin: "" });
          console.log("Valid GSTIN format entered");
          const user = Cookies.get("role");
          const user_id = Cookies.get("user_id");
          // Check if GSTIN already exists in the database
          try {
              const response = await axios.get(`${config.base_url}api/check_gstin/`, {
                  params: { gstin: value ,'user_id':user_id,"role":user}, // Pass GSTIN as query parameters
              });

              if (response.data.exists) {
                  setErrors((prevErrors) => ({
                      ...prevErrors,
                      gstin: "GSTIN already exists.",
                  }));
                  const user = Cookies.get("role");
                  const user_id = Cookies.get("user_id");
                
                  console.log("Checking party name:", value);
                
                  try {
                    const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                      params: {
                        // party_name: value,
                        gstin: value , 
                        party_name:formData.partyName,// Use value directly instead of formData.party_name
                        user_id: user_id,
                        role: user,
                      },
                    });
                
                    console.log("API Response:", response.data);
                
                    if (response.data.party_exists) {
                      setErrors4((prev) => ({
                        ...prev,
                        party: "Party name already exists", // Show validation error
                      }));
                    } else {
                      setErrors4((prev) => ({
                        ...prev,
                        party: "", // Clear error when valid
                      }));
                    }
                  } catch (error) {
                    console.error("Validation error:", error.response ? error.response.data : error.message);
                    setErrors4((prev) => ({
                      ...prev,
                      party: "", // Show generic error message
                    }));
                  }
              } else {
                  setErrors((prevErrors) => ({ ...prevErrors, gstin: "" }));
                  setErrors4((prev) => ({
                    ...prev,
                    party: "", // Clear error when valid
                  }));
              }
          } catch (error) {
              console.error("Error checking GSTIN existence:", error);
              setErrors((prevErrors) => ({
                  ...prevErrors,
                  gstin: "Unable to check GSTIN availability. Please try again.",
              }));
              setErrors4((prev) => ({
                ...prev,
                party: "", // Clear error when valid
              }));
          }
      } else {
          setErrors({
              ...errors,
              gstin: "Invalid GSTIN format. Example: 29ABCDE1234F2Z5",
          });
          setErrors4((prev) => ({
            ...prev,
            party: "", // Clear error when valid
          }));
      }
  }
};
const isValidGSTIN = (gstin) => {
  const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  return gstinRegex.test(gstin);
};
// const handleSave = async (row) => {
//   try {
//     const companyId = Cookies.get("companyId"); // Retrieve company ID
//     const staffId = user === "staff" ? Cookies.get("staffId") : null;

//     // Prepare updated invoice data
//     const updatedInvoice = {
//       party_name: invoiceData.party_name,
//       contact: invoiceData.contact,
//       address: invoiceData.address,
//       invoice_no: invoiceData.invoice_no,
//       subtotal: invoiceData.subtotal,
//       grandtotal: invoiceData.grandtotal,
//       date: invoiceData.date,
//       adjustment: invoiceData.adjustment,
//       total_taxamount: invoiceData.total_taxamount,
//       user_id: user_id,
//       role: user,
//       cgst: invoiceData.cgst,
//       sgst: invoiceData.sgst,
//       igst: invoiceData.igst,
//       state_of_supply: invoiceData.state_of_supply,
//     };

//     // Prepare invoice items
//     const invoiceItems = rows.map((row) => ({
//       item_name: row.item__item_name || "N/A",
//       hsn: row.hsn,
//       quantity: row.quantity,
//       rate: row.rate,
//       discount: row.discount,
//       tax: row.tax,
//       total: row.totalamount,
//       user_id: user_id,
//       role: user,
//     }));

//     // Combine the data into one object
//     // const data = {
//     //   updatedInvoice,
//     //   invoiceItems,
//     // };
//     const requestData = {
//       updatedInvoice: updatedInvoice,
//       invoiceItems: invoiceItems,
//     };

//     console.log("Request Data:", requestData);

//     // Send the request
//     // const response = await axios.post(`${config.base_url}update_invoice/${invoiceData.invoice_no}/`,requestData,{
//     //   headers:{
//     //     "Content-Type":"application/json"
//     //   }
//     // })
//     console.log("Request Data//:", JSON.stringify(requestData, null, 2));

//     const response = await axios.post(
//       `${config.base_url}update_invoice/${invoiceData.invoice_no}/`,
//       JSON.stringify(requestData),
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
    
//     if (!response) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     // const data = await response.json();
//     // console.log("Response Data:", data);

//     // Navigate or reload page based on conditions
//     if (navigateToInvoice) {
//       window.location.href = "/show_invoice"; // Navigate to invoice page
//     } else {
//       window.location.reload(); // Reload the current page
//     }
//   } catch (error) {
//     console.error("Error updating invoice:", error.message);
//     alert("Failed to save the invoice. Please try again.");
//   }
// };
//14-01-2025


//14-01-2025 new
// const handleSave = async (row) => {
//   try {
//     const companyId = Cookies.get("companyId"); // Retrieve company ID
//     const staffId = user === "staff" ? Cookies.get("staffId") : null;

//     // Prepare a single array containing both updated invoice and items
//     const invoiceDataArray = [
//       {
//         type: "invoice",
//         party_name: invoiceData.party_name,
//         contact: invoiceData.contact,
//         address: invoiceData.address,
//         invoice_no: invoiceData.invoice_no,
//         subtotal: invoiceData.subtotal,
//         grandtotal: invoiceData.grandtotal,
//         date: invoiceData.date,
//         adjustment: invoiceData.adjustment,
//         total_taxamount: invoiceData.total_taxamount,
//         user_id: user_id,
//         role: user,
//         cgst: invoiceData.cgst,
//         sgst: invoiceData.sgst,
//         igst: invoiceData.igst,
//         state_of_supply: invoiceData.state_of_supply,
//       },
//       ...rows.map((row) => ({
//         type: "item",
//         item_name: row.item__item_name || "N/A",
//         hsn: row.hsn,
//         quantity: row.quantity,
//         rate: row.rate,
//         discount: row.discount,
//         tax: row.tax,
//         total: row.totalamount,
//         user_id: user_id,
//         role: user,
//       })),
//     ];

//     console.log("Request Data:", invoiceDataArray);

//     // Send the request
//     const response = await axios.post(
//       `${config.base_url}update_invoice/${invoiceData.invoice_no}/`,
//       JSON.stringify(invoiceDataArray),
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     if (!response) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     // Navigate or reload page based on conditions
//     if (navigateToInvoice) {
//       window.location.href = "/show_invoice"; // Navigate to invoice page
//     } else {
//       window.location.reload(); // Reload the current page
//     }
//   } catch (error) {
//     console.error("Error updating invoice:", error.message);
//     alert("Failed to save the invoice. Please try again.");
//   }
// };

//14-01-2025 new
const handleEmailChange = async (value) => {
  // Update formData with the new value
  setFormData((prev) => ({
    ...prev,
    email: value, // Correctly update state
  }));
  console.log("email",value)
  // if (name === "email") {
  //   console.log(1)
      // Validate GSTIN format
      const emailRegex1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex1.test(value)) {
        // alert("Invalid email format");
        setErrors1((prev) => ({
          ...prev,
          email: "Invalid email format", // Show validation error
        }));
        setErrors4((prev) => ({
          ...prev,
          party: "", // Clear error when valid
        }));
        return; // Stop further processing if email is invalid
      }else {
        try {
          const response = await axios.get(`${config.base_url}api/validate_email/`, {
            params: {
              email: value, // Use value directly instead of formData.party_name
              user_id: user_id,
              role: user,
            },
          });
      
          console.log("API Response:", response.data);
      
          if (response.data.email_exists) {
            setErrors1((prev) => ({
              ...prev,
              email: "email  already exists", // Show validation error
            }));
            const user = Cookies.get("role");
            const user_id = Cookies.get("user_id");
          
            console.log("Checking party name:", value);
          
            try {
              const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                params: {
                  // party_name: value,
                  // gstin: value , 
                  party_name:formData.partyName,// Use value directly instead of formData.party_name
                  user_id: user_id,
                  role: user,
                  email: value,
                },
              });
          
              console.log("API Response:", response.data);
          
              if (response.data.party_exists) {
                setErrors4((prev) => ({
                  ...prev,
                  party: "Party name already exists", // Show validation error
                }));
              } else {
                setErrors4((prev) => ({
                  ...prev,
                  party: "", // Clear error when valid
                }));
              }
            } catch (error) {
              console.error("Validation error:", error.response ? error.response.data : error.message);
              setErrors4((prev) => ({
                ...prev,
                party: "", // Clear error when valid
              }));
            }
          } else {
            setErrors1((prev) => ({
              ...prev,
              email: "", // Clear error when valid
            }));
            setErrors4((prev) => ({
              ...prev,
              party: "", // Clear error when valid
            }));
          }
        } catch (error) {
          console.error("Validation error:", error.response ? error.response.data : error.message);
          setErrors1((prev) => ({
            ...prev,
            email: "", // Show generic error message
          }));
          setErrors4((prev) => ({
            ...prev,
            party: "", // Clear error when valid
          }));
        }
      }
  // }
};
const handlePhoneNoChange = async (value) => {
// Update formData with the new value
setFormData((prev) => ({
  ...prev,
  partyPhNo: value, // Correctly update state
}));
console.log("email",value)
// if (name === "email") {
//   console.log(1)
    // Validate GSTIN format
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(value)) {
      // alert("Invalid email format");
      setErrors2({
        ...errors2,
        partyPhNo: "Please enter a valid 10-digit phone number.",
      });
      setErrors4((prev) => ({
        ...prev,
        party: "", // Clear error when valid
      }));
      return; // Stop further processing if email is invalid
    }else {
      try {
        const response = await axios.get(`${config.base_url}api/validate_phone/`, {
          params: {
            partyPhNo: value, // Use value directly instead of formData.party_name
            user_id: user_id,
            role: user,
          },
        });
    
        console.log("API Response:", response.data);
    
        if (response.data.phone_exists) {
          setErrors2((prev) => ({
            ...prev,
            partyPhNo: "Phone number  already exists", // Show validation error
          }));
          const user = Cookies.get("role");
            const user_id = Cookies.get("user_id");
          
            console.log("Checking party name:", value);
          
            try {
              const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                params: {
                  // party_name: value,
                  partyPhNo: value, 
                  party_name:formData.partyName,// Use value directly instead of formData.party_name
                  user_id: user_id,
                  role: user,
                },
              });
          
              console.log("API Response:", response.data);
               
              if (response.data.party_exists) {
                console.log("party exists")
                setErrors4((prev) => ({
                  ...prev,
                  party: "Party name already exists", // Show validation error
                }));
              }
              else {
                setErrors4((prev) => ({
                  ...prev,
                  party: "", // Clear error when valid
                }));
              }
            } catch (error) {
              console.error("Validation error:", error.response ? error.response.data : error.message);
              setErrors4((prev) => ({
              ...prev,
              party: "", // Clear error when valid
            }));
            }
        } else {
          setErrors2((prev) => ({
            ...prev,
            partyPhNo: "", // Clear error when valid
          }));
          setErrors4((prev) => ({
            ...prev,
            party: "", // Clear error when valid
          }));
        }
      } catch (error) {
        console.error("Validation error:", error.response ? error.response.data : error.message);
        setErrors2((prev) => ({
          ...prev,
          partyPhNo: "", // Show generic error message
        }));
        setErrors4((prev) => ({
          ...prev,
          party: "", // Clear error when valid
        }));
      }
    }
// }
}; 
const handlePartyNameChange = async (value) => {
  // Update formData with the new value
  setFormData((prev) => ({
    ...prev,
    partyName: value, // Correctly update state
  }));

  const user = Cookies.get("role");
  const user_id = Cookies.get("user_id");

  console.log("Checking party name:", value);

  // ✅ Check if any of the three fields contain a value before making the API call
  if (!formData.gstin && !formData.partyPhNo && !formData.email) {
    console.log("No GSTIN, Phone Number, or Email provided. Skipping validation.");
    return; // Exit function early
  }

  try {
    const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
      params: {
        party_name: value,
        user_id: user_id,
        role: user,
        gstin:formData.gstin,
        email:formData.email,
        partyPhNo:formData.partyPhNo
      },
    });

    console.log("API Response:", response.data);

    if (response.data.party_exists) {
      setErrors4((prev) => ({
        ...prev,
        party: "Party name already exists", // Show validation error
      }));
    } else {
      setErrors4((prev) => ({
        ...prev,
        party: "", // Clear error when valid
      }));
    }
  } catch (error) {
    console.error("Validation error:", error.response ? error.response.data : error.message);
    setErrors4((prev) => ({
      ...prev,
      party: "", // Show generic error message
    }));
  }
};
// const handlePartyNameChange = async (value) => {
//   // Update formData with the new value
//   setFormData((prev) => ({
//     ...prev,
//     partyName: value, // Correctly update state
//   }));

//   const user = Cookies.get("role");
//   const user_id = Cookies.get("user_id");

//   console.log("Checking party name:", value);

//   try {
//     const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
//       params: {
//         party_name: value, // Use value directly instead of formData.party_name
//         user_id: user_id,
//         role: user,
//       },
//     });

//     console.log("API Response:", response.data);

//     if (response.data.party_exists) {
//       setErrors4((prev) => ({
//         ...prev,
//         party: "Party name already exists", // Show validation error
//       }));
//     } else {
//       setErrors4((prev) => ({
//         ...prev,
//         party: "", // Clear error when valid
//       }));
//     }
//   } catch (error) {
//     console.error("Validation error:", error.response ? error.response.data : error.message);
//     setErrors4((prev) => ({
//       ...prev,
//       party: "party name is required", // Show generic error message
//     }));
//   }
// };
const handleSave = async (row) => {
  try {
    const companyId = Cookies.get("companyId"); // Retrieve company ID
    const staffId = user === "staff" ? Cookies.get("staffId") : null;

    // Prepare data to be sent via FormData
    const formData = new FormData();

    // Add invoice data
    formData.append("party_name", invoiceData.party_name);
    formData.append("contact", invoiceData.contact);
    formData.append("address", invoiceData.address);
    formData.append("invoice_no", invoiceData.invoice_no);
    formData.append("subtotal", invoiceData.subtotal);
    formData.append("grandtotal", invoiceData.grandtotal);
    formData.append("date", invoiceData.date);
    formData.append("adjustment", invoiceData.adjustment);
    formData.append("total_taxamount", invoiceData.total_taxamount);
    formData.append("user_id", user_id);
    formData.append("role", user);
    formData.append("cgst", invoiceData.cgst);
    formData.append("sgst", invoiceData.sgst);
    formData.append("igst", invoiceData.igst);
    formData.append("state_of_supply", invoiceData.state_of_supply);
    formData.append("payment_type", selectedPayment);

    // Conditionally add payment-specific data
    if (selectedPayment === "Cheque") {
      formData.append("cheque", invoiceData.cheque || ""); // Add cheque ID
    } else if (selectedPayment === "UPI") {
      formData.append("upi", invoiceData.upi || ""); // Add UPI ID
    } else if (selectedPayment === "Bank") {
      formData.append("bank_account", invoiceData.bankAccount || ""); // Add bank account number
    }
    const hasInvalidQuantity = rows.some((row) => row.quantity === 0);
    if (hasInvalidQuantity) {
      alert("Quantity cannot be 0. Please update the quantities before saving.");
      
      // return; // Exit the function early
      // window.location.reload();
    }

    // Add item data
    // console.log("items are", row.item__id  )
    rows.forEach((row) => {
      formData.append("item_id[]", row.item__id);
      formData.append("item_name[]", row.item__item_name || "N/A");
      formData.append("hsn[]", row.hsn);
      formData.append("sac[]", row.sac);
      formData.append("quantity[]", row.quantity);
      formData.append("rate[]", row.rate);
      formData.append("discount[]", row.discount);
      formData.append("tax[]", row.tax);
      formData.append("total[]", row.totalamount);
      formData.append("user_id[]", user_id);
      formData.append("role[]", user);
    });

    // Send the request
    
    const response = await axios.post(
      `${config.base_url}update_invoice/${invoiceData.invoice_no}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Check if the response status is not 200
    if (response.status !== 200) {
      const errorData = response.data;
      alert("Error: " + errorData.error);
      throw new Error(errorData.error);  // If error message is returned from backend, throw it
    }

    // Success - Redirect or reload page
    if (navigateToInvoice) {
      window.location.href = "/show_invoice";
      alert("Invoice updated successfully!");
    } else {
      window.location.reload();
    }
  } catch (error) {
    // Show the error message in an alert
    // alert("Error: " + error.message);
    console.error("Error updating invoice:", error.message);
  }

    // Check for error response from the backend
    
   
};

//   const handleSave = (row) => {
    
//     const companyId = Cookies.get("companyId"); // Retrieve company ID
//     const staffId = user === "staff" ? Cookies.get("staffId") : null;
//     const updatedInvoice = {
//       party_name: invoiceData.party_name,
//       contact: invoiceData.contact,
//       address: invoiceData.address,
//       invoice_no: invoiceData.invoice_no,
//       subtotal: invoiceData.subtotal,
//       grandtotal: invoiceData.grandtotal,
//       date: invoiceData.date,
//       adjustment: invoiceData.adjustment,
//       total_taxamount:invoiceData.total_taxamount,
//       user_id: user_id,
//       role:user,
//       cgst:invoiceData.cgst,
//       sgst:invoiceData.sgst,
//       igst:invoiceData.total_taxamount,
//       state_of_supply:invoiceData.state_of_supply

//     };
//     console.log(updatedInvoice)
//     const invoiceItems = rows.map((row) => ({
//       // itemId: row.item__id, // Corrected to reference the item ID (not `row.id`)
//       item_name: row.item__item_name || 'N/A', 
//       // itemId: row.id,
//       // item_name:row.item__item_name||item_name,
//       hsn: row.hsn,
//       quantity: row.quantity,
//       rate: row.rate,
//       discount: row.discount,
//       tax: row.tax,
//       total: row.totalamount,
//       user_id: user_id,
//       role:user,
     
//     }));
//     console.log("Response Data:", invoiceItems);
//     console.log("Response Data:", updatedInvoice);
//     const requestData = {
//       updatedInvoice,
//       invoiceItems,
//     };
//     console.log("Response Data1:", requestData);
   
//     // fetch(`${config.base_url}update_invoice/${invoiceData.invoice_no}/`, {
//     //   method: "POST",
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //   },
//     //   body: JSON.stringify(requestData),
//     //   // body: JSON.stringify(updatedInvoice),
//     //   // body: JSON.stringify(invoiceItems)
//     // })
//     try {
//     const response = await fetch(`${config.base_url}update_invoice/${invoiceData.invoice_no}/`, {
//       method: 'POST',
//       headers: {
//           'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestData),
//       body: JSON.stringify(updatedInvoice),
//       body: JSON.stringify(invoiceItems)
//   });
//   if (response.ok) {
//     // If the response is successful, reload the page to reflect the changes
//     // window.location.reload();
//     if (navigateToInvoice) {
//       // Navigate to /show_invoice after successful save
//       window.location.href = "/show_invoice";
//     } else {
//       // Reload the current page for "Save and Next"
//       window.location.reload();
//     }
   
//   } else {
//     // If the response is not successful, show an error message
//     alert('Failed to save the invoice. Please try again.');
//   }
 
// // If the invoice was saved successfully, fetch the last invoice number and update the invoice number
// } 
// catch (error) {
//   console.error('Error:', error.message); // Log error
// }
//       // .then((response) => {
//         // if (!response.ok) {
//         //   throw new Error(`HTTP error! status: ${response.status}`);
//         // }
//         // return response.json();
//       // })
//       // .then((data) => {
//       //   console.log("Response Data:", data);
//       //   navigate(`/show_invoice`);
        
//       // })
//     // }catch (error) {
//     //     console.error('Error:', error.message); // Log error
//     //   }
//       // .catch((error) => {
        
//       //   console.error("Error updating invoice:", error);
//       // });
//   };
  
const [formData, setFormData] = useState({
    party_name: "",
    phone_number: "",
    gstin: "",
    gst_type: "",
    billing_address: "",
    state: "",
    email: "",
    opening_balance: "",
    credit_limit: "",
    to_pay: false,
    to_receive: false,
    date: "",
    company: "", // You need to pass the company ID
    staff: "",   // You need to pass the staff ID
  });
   const [errors1, setErrors1] = useState({
        email: "",
        // other field errors
      });
          const [errors2, setErrors2] = useState({
            partyPhNo: "",
            // other field errors
          });
  
  const handleChangeparty = (e) => {
    const { name, value, type, checked } = e.target;
    const emailRegex1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;
    if (name === "partyPhNo") {
      if (!phoneRegex.test(value)) {
        setErrors2({
          ...errors2,
          partyPhNo: "Please enter a valid 10-digit phone number.",
        });
      } else {
        setErrors2({
          ...errors2,
          partyPhNo: "", // Clear the error message if valid
        });
      }
    }
      // Handle email validation
      if (name === "email") {
        if (!emailRegex1.test(value)) {
          setErrors1({
            ...errors1,
            email: "Please enter a valid email address.",
          });
        } else {
          setErrors1({
            ...errors1,
            email: "", // Clear the error message if valid
          });
        }
      }
    if (type === "radio") {
      console.log("to_pay",formData.to_pay)
      console.log("name",name)
      // Check if it's the "to_pay" or "to_receive" radio button and set the values accordingly
      if (name === "to_pay" ) {
        formData.to_pay= true, // If clicked 'to_pay', set it to true
        formData.to_receive=false 
        // Update both fields to ensure only one can be true at a time
        setFormData({
          ...formData,
          to_pay: name === "to_pay" ? checked : true, // If clicked 'to_pay', set it to true
          to_receive: name === "to_receive" ? checked : false, // If clicked 'to_receive', set it to true
        });
      }
      if (name === "to_receive" ) {
        // Update both fields to ensure only one can be true at a time
        
          formData.to_pay= false, // If clicked 'to_pay', set it to true
          formData.to_receive=true // If clicked 'to_receive', set it to true
        // });
        setFormData({
          ...formData,
          to_pay: name === "to_pay" ? checked : false, // If clicked 'to_pay', set it to true
          to_receive: name === "to_receive" ? checked : true, // If clicked 'to_receive', set it to true
        });
      }
    } else {
      // For other inputs, update the state as usual
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
    // setFormData({
    //   ...formData,
    //   [name]: type === "checkbox" ? checked : value,
    // });
    console.log(formData)
  };
  const fetchParty=async()=>{
    try {
      const user = Cookies.get("role");
      const user_id = Cookies.get("user_id");
      const response = await axios.get(
        `${config.base_url}api/parties_details/`,
        {
          headers: {
            "Content-Type": "application/json",
          }
          , params:{
            'user_id':user_id,"role":user
          }
        }
      );
      console.log(response);
      if (response.data.status === 200) {
       
        setParties(response.data.data);
        // setStockInHand(response.data.data.item_stock_in_hand)
        // setDate(response.data.data.item_date)
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleSubmitparty = async (e) => {
    console.log("hello")
    e.preventDefault();
    const user = Cookies.get("role");
    var is_company = false;
    if (user === "Company") {
      is_company = true;
    }
    const ID = Cookies.get("user_id");
    const user_id = ID
    let missingFields = [];
    if (!formData.partyName) missingFields.push("Party Name");
      if (!formData.partyPhNo) missingFields.push("Phone Number");
      if (!formData.gstin) missingFields.push("GSTIN");
      if (!formData.gstType) missingFields.push("GST Type");
      if (!formData.bAddress) missingFields.push("Billing Address");
      if (!formData.splyState) missingFields.push("State");
      if (!formData.email) missingFields.push("Email");
      if (!formData.openbalance1) missingFields.push("Opening Balance");
      if (!formData.crdLmt) missingFields.push("Credit Limit");
      if (!formData.partyDate) missingFields.push("Date");
    
      // If any required fields are missing, show an alert with the missing fields
      if (missingFields.length > 0) {
        alert("Please fill in the following required fields: " + missingFields.join(", "));
        return;
      }
      if (!formData.to_pay && !formData.to_receive) {
        alert("Please select either 'To Pay' or 'To Receive'.");
        return;
      }
      if (
        errors.gstin !== "" ||
        errors1.email !== "" ||
        errors2.partyPhNo !== "" ||
        errors4.party !== ""
    ) {
        alert("Please resolve all errors before saving.");
        return;
    }
    try {
      const updatedFormData = {
        ...formData,
        user_id: user_id,
        role: user
      };
      const response = await axios.post(`${config.base_url}api/parties_adding/`, updatedFormData); // Update with your endpoint
      if (response.status === 201) {
        alert("Party details added successfully!");
        // window.location.reload(); 
        fetchParty()
        setIsCredit(false)
        closeModal()
        
        setFormData({
          party_name: "",
          phone_number: "",
          gstin: "",
          gst_type: "",
          billing_address: "",
          state: "",
          email: "",
          opening_balance: "",
          credit_limit: "",
          to_pay: false,
          to_receive: false,
          date: "",
          company: "",
          staff: "",
        });
      }
    } catch (error) {
      console.error("Error adding party details:", error);
      alert("Failed to add party details. Please try again.");
    }
  };
  const states = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
   ' Assam',
   ' Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Maharashtra',
    'Madhya Pradesh',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    
    // Add more states as needed
  ];
  // const states = [
  //   'kerala',
  //   'karnataka',
  //   'Tamilnadu',
  //   'maharashtra',
  //   'punjab',
  //   'madhyapradesh',
  //   'gujarath',
  //   'utharpradesh',
  //   'sikkim',
  //   'Assam',
  //   // Add more states as needed
  // ];
  const [isCredit, setIsCredit] = useState(false);
   const [showModal, setShowModal] = useState(false); 
  const closeModal = () => {
    setShowModal(false);
    setIsCredit(false) // Close modal by setting state to false
    setFormData({
      party_name: "",
      phone_number: "",
      gstin: "",
      gst_type: "",
      billing_address: "",
      state: "",
      email: "",
      opening_balance: "",
      credit_limit: "",
      to_pay: false,
      to_receive: false,
      date: "",
      company: "",
      staff: "",
    });

    setErrors({
      gstin: ""
    });
    setErrors1({
      email: ""
    });
    setErrors2({
     partyPhNo: ""
    });
    setErrors4({
      party: ""
     });
  };
  const handleCreditPrev = () => {
    setIsCredit(false);
    
  };
  
  const [showModalItem3, setShowModalItem3] = useState(0);
  const closeModal1 = () => {
  
    setShowModalItem3(false);
    setShowStock(false)
    setItemName('')
setHsn("");
setSac('')
setItemType('')
setGst('')
setIgst('')
setSalesPrice(0)
setPurchasePrice(0)
setAtprice(0)
setStockInHand(0)
setminStockMaintain(0)
setnonTaxablePurchasePrice(0)
setnonTaxableSalePrice(0)
// setUnit('')
setErrors({
  hsn: "",
  sac: "",
  // Reset other error fields as needed
});
setErrors3({
  itemName: ''
 // Reset other error fields as needed
});
setItemData({
  item_name: "",
item_hsn: "",
item_type: "",
unit_name:"",
opening: "",
at_price: "",
date: "",
min_stock: "",
igst:"",gst:"",
taxable:"",
gst:"",
igst:"",
sale_price:"",
purchase_price: "",
unit_name:"",
}); // Close modal by setting state to false
  };
    // const [activeTab, setActiveTab] = useState('gst');
     const [activeTab1, setActiveTab1] = useState('pricing');
     const [addunit,setAddUnit] = useState('')
     const [allUnit,setAllUnit] = useState([])
      const handleNextClick = () => {
        setActiveTab('credit'); // Update the activeTab state to 'credit'
      };
      const handleItemNextClick = () => {
        setActiveTab1('stock'); // Update the activeTab state to 'credit'
      };
      const handlePreviousClick = () => {
        setActiveTab('gst'); // Update the activeTab state to 'credit'
      };
    const [ItemData, setItemData] = useState({
        item_name: "",
        item_hsn: "",
        item_type: "",
    unit_name:"",
        opening: "",
        at_price: "",
        date: "",
        min_stock: "",
        
        taxable: "",
        // non_taxable: false,
        sale_price:"",
        purchase: "", 
        igst:"",
        gst:""  // You need to pass the staff ID
      });
      const handleChangeItem = (e) => {
        const { name, value, type, checked } = e.target;
        
        setItemData({
          ...ItemData,
          [name]: type === "checkbox" ? checked : value,
        });
        console.log(ItemData)
      };
      const handleSubmitItem = async (e) => {
        e.preventDefault();
        const user = Cookies.get("role");
        var is_company = false;
        if (user === "Company") {
          is_company = true;
        }
        const ID = Cookies.get("user_id");
        const user_id = ID
        try {
          const updatedItemData = {
            ...ItemData,
            user_id: user_id,
            role: user
          };
          console.log(updatedItemData)
          const response = await axios.post(`${config.base_url}api/item/`, updatedItemData); // Update with your endpoint
          if (response.status === 201) {
            alert("Item added successfully!");
            setItemData({
              item_name: "",
        item_hsn: "",
        item_type: "",
      unit_name:"",
        opening: "",
        at_price: "",
        date: "",
        min_stock: "",
        igst:"",gst:"",
        taxable:"",
        gst:"",
        igst:"",
        sale_price:"",
        purchase_price: "",
        unit_name:"",
            });
          }
        } catch (error) {
          console.error("Error adding item details:", error);
          alert("Failed to add item details. Please try again.");
        }
      };
      
        const handleSubmit = async (e) => {
          e.preventDefault(); // Prevent default form submission
          const companyId = Cookies.get("companyId"); // Retrieve company ID
          const staffId = user === "staff" ? Cookies.get("staffId") : null;
          let paymentDetails = {};
          if (selectedPayment === "Cheque") {
            paymentDetails.paymentType = "Cheque"; // Ensure payment type is set
            paymentDetails.chequeId = document.getElementById("chequeId").value;
          } else if (selectedPayment === "UPI") {
            paymentDetails.paymentType = "UPI"; // Ensure payment type is set
            paymentDetails.upiId = document.getElementById("upiId").value;
          } else if (selectedPayment === "Bank") {
            paymentDetails.paymentType = "Bank"; // Ensure payment type is set
            paymentDetails.bankAccount = document.getElementById("bankAccount").value;
          } else if (selectedPayment === "Cash") {
            paymentDetails.paymentType = "Cash"; // Ensure payment type is set for Cash
          }
          let supplyDetails = "";
          
          if (selectedState === "state") {
            supplyDetails = "state";  // Just pass the value as a string
          } else if (selectedState === "otherState") {
            supplyDetails = "other state";  // Just pass the value as a string
          }
          console.log("Rows before submission:", rows);
          const invoiceItems = rows.map((row) => ({
            
            itemId: row.id,
            hsn: row.item_hsn,
            quantity: row.qty,
            rate: row.item_sale_price,
            discount: row.discount,
            tax: row.tax,
            total: row.total,
          }));
         
              
        const handleFieldChange = (e) => {
            const { name, value } = e.target;
            setItemData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          };
          
          const invoiceNo = document.getElementById("invoiceNo").value;
          const invoiceData = {
            invoiceNo: invoiceNo,
            party: selectedParty,
            subtotal: subTotal,
            cgst: cgst,
            sgst: sgst,
            taxAmount: taxAmount,
            adjustment: adjustment,
            grandTotal: grandTotal,
            description: description,
            user_id: user_id,
            role:user,
            paymentDetails: paymentDetails,
            contact:phoneNumber,
            address:billingAddress,
            state_of_supply: supplyDetails,
            invoiceItems: invoiceItems, 
            // company_id: user === "company" ? companyName : companyId, // Company ID is always needed
            // staff_id: user === "staff" ? staffId : null,
            date: new Date().toISOString().split('T')[0], // Add current date
            
          };
          
          
          try {
            //  `${config.base_url}/api/save_invoice/`
            const response = await fetch( `${config.base_url}api/save_invoice/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(invoiceData),
          });
          
            // });
          
          
            // const responseData = await response.json();
            // console.log(responseData);
            if (response.ok) {
              // If the response is successful, reload the page to reflect the changes
              window.location.reload();
            } else {
              // If the response is not successful, show an error message
              alert('Failed to save the invoice. Please try again.');
            }
           
          // If the invoice was saved successfully, fetch the last invoice number and update the invoice number
         } catch (error) {
            console.error('Error:', error.message); // Log error
          }
          //  catch (error) {
          //   console.error('Error:', error);
          //   alert(`Error: ${error.message}`);
          // }
          
          
      };
      const fetchItems = async () => {
        try {
          const user = Cookies.get("role");
          const user_id = Cookies.get("user_id");
      
          const url = `${config.base_url}all_items_filter`;
          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              'user_id': user_id,
              "role": user
            }
          });
      console.log("lll")
          console.log("Fetched Items:", response.data); // Log the fetched items
          setItems(response.data.data);
        } catch (err) {
          console.error("Fetch Error:", err);
        }
      };
  useEffect(() => {
    async function fetchdata() {
        
      try {
          const user = Cookies.get("role");
          const user_id = Cookies.get("user_id");
        const response = await axios.get(
          `${config.base_url}api/parties_details/`,
          // `${config.base_url}api/parties/`,
          {
            headers: {
              "Content-Type": "application/json",
            }
            , params:{
              'user_id':user_id,"role":user
            }
          }
        );
        if (response.data.status === 200) {
          console.log(response);
          setParties(response.data.data);
          // setStockInHand(response.data.data.item_stock_in_hand)
          // setDate(response.data.data.item_date)
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, [config.base_url]);
  // const [rows, setRows] = useState([
  //   { id: '', item_hsn: '', item_sale_price: '', item_tax: '', qty: 0, discount: 0, total: 0 }
  // ]);
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, i) => i !== index);
    setRows(updatedRows);
    invoiceData.subtotal = updatedRows.reduce((sum, row) => {
      // const amount = parseFloat(row.totalamount) || 0; // Convert totalamount to a number or default to 0
      // console.log("amount is",amount)
      // console.log("+++",sum + amount)
      const amount= parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)))
      return sum + amount; // Add the numeric value
    }, 0);
    
    invoiceData.cgst = updatedRows.reduce((sum, row) => {
      // Ensure row.tax is a valid number and row.totalamount is defined
      // const Total1=updatedRows[index].totalamount
      // console.log
      console.log("tax",row.tax)
      console.log("disc",row.discount)
      console.log("subtotal",row.rate)
      const taxRate =row.tax.match(/(\d+)(?=%)/)[0]  ;
      // const taxValue = stateType === "State"
      // ? (row.gst ? row.gst.replace(/\D/g, "") : "") 
      // : (row.igst ? row.igst.replace(/\D/g, "") : "");
      console.log(taxRate)
      
      // const taxValue1= parseFloat((row.rate*row.tax))/100
      const taxValue1 = parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)*(parseFloat(taxRate) || 0)) / 100);

      console.log("tax is ",taxValue1)
      // const taxRate = parseFloat(row.tax) || 0; // Convert tax to a number or default to 0
      // const amount = parseFloat(row.totalamount) || 0; // Ensure totalamount is a number
      // console.log("sum is",sum)
      // console.log("tax is" , (amount * taxRate) / 100 )
      return sum + taxValue1; // Calculate tax and add to the sum
     
    }, 0) / 2; // Divide the total GST by 2
    
    // invoiceData.cgst = updatedRows.reduce((sum, row) => sum + row.totalamount, 0);
    invoiceData.sgst = invoiceData.cgst // Example 9% SGST
    invoiceData.total_taxamount = invoiceData.cgst + invoiceData.sgst;
    // invoiceData.adjustment
    invoiceData.grandtotal = invoiceData.subtotal + invoiceData.total_taxamount -invoiceData.adjustment
  
  };
  const addItem = () => {
    setRows([...rows, { item__id: "", // Initialize as empty
      item__item_name: "",hsn: '', qty: 0, price: 0, tax: 0, discount: 0, total: 0 }]);
};

  // useEffect(() => {
  //   async function fetchdata() {
  //     try {
        
  //       const response = await axios.get(
  //         `${config.base_url}all_items`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           }
  //         }
  //       );
  //       if (response.data.status === 200) {
  //         console.log(response);
  //         setItems(response.data.data);
  //         // setStockInHand(response.data.data.item_stock_in_hand)
  //         // setDate(response.data.data.item_date)
  //       } else {
  //         console.log(response);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchdata();
  // }, [config.base_url]);
  
  useEffect(() => {
    async function fetchdata() {
      try {
        const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
        const response = await axios.get(
          `${config.base_url}all_unit1`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params:{
              'user_id':user_id,"role":user
            }
          }
        );
        if (response.data.status === 200) {
          console.log(response);
          setAllUnit(response.data.data);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, [config.base_url,count]);
  const[itemType,setItemType] = useState('')
  // const[unit,setUnit] = useState('')
  const[gst,setGst] = useState('')
  const[igst,setIgst] = useState('')
  const[salesPrice,setSalesPrice] = useState(0)
  const[nonTaxableSalePrice,setnonTaxableSalePrice] = useState(0)  
  const[nonTaxablePurchasePrice,setnonTaxablePurchasePrice] = useState(0) 
  const[purchasePrice,setPurchasePrice] = useState(0)
  const[atPrice,setAtprice] = useState(0)
  const[stockInHand,setStockInHand] = useState(0)
  const[minStockMaintain,setminStockMaintain] = useState(0)
  const[date,setDate] = useState(new Date().toISOString().split('T')[0])
 
  const [allItems,setAllItems] = useState([])
  // const [addunit,setAddUnit] = useState('')
  // const [allUnit,setAllUnit] = useState([])
  // const [count,setCount] = useState(0)
  const [valid,setValid] = useState(false)
  const [errors, setErrors] = useState({
      itemName: "",
      hsn: "",
      sac: ""
  });
//   const handleItemNameChange = (value) => {
//     setItemName(value);
//     setErrors((prev) => ({ ...prev, itemName: validateItemName(value) }));
// };
// const handleHsnChange = async (value) => {
//   setHsn(value);
//  const user = Cookies.get("role");
//         const user_id = Cookies.get("user_id");
//   // Check if the HSN already exists in the database
//   const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
//       params: { hsn: value ,'user_id':user_id,"role":user },
//   });

//   if (response.data.hsn_exists) {
//       setErrors((prev) => ({ ...prev, hsn: 'HSN already exists' }));
//   } else {
//       setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
//       setErrors3({
//         itemName: ''
//        // Reset other error fields as needed
//      });
//   }
// };

// const handleSacChange = async (value) => {
//   setSac(value);
//  const user = Cookies.get("role");
//         const user_id = Cookies.get("user_id");
//   // Check if the SAC already exists in the database
//   const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
//       params: { sac: value ,'user_id':user_id,"role":user },
//   });

//   if (response.data.sac_exists) {
//       setErrors((prev) => ({ ...prev, sac: 'SAC already exists' }));
//   } else {
//       setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
//       setErrors3({
//         itemName: ''
//        // Reset other error fields as needed
//      });
//   }
// };
const handleHsnChange = async (value) => {
  setHsn(value);
  const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
  // Check if the HSN already exists in the database
  if (/^\d+$/.test(value)) {
  const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
      params: { hsn: value ,'user_id':user_id,"role":user },
  });
  
  if (response.data.hsn_exists) {
    setErrors((prev) => ({ ...prev, hsn: 'HSN already exists' }));
} else {
    setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
    // setErrors3({
    //   itemName: ''
     // Reset other error fields as needed
  //  });
}
}
  else{
    setHsn(value);
    setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
  }
};

const handleSacChange = async (value) => {
  setSac(value);
  const user = Cookies.get("role");
  const user_id = Cookies.get("user_id");
  // Check if the SAC already exists in the database
  if (/^\d+$/.test(value)) {
  const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
      params: { sac: value  ,'user_id':user_id,"role":user},
  });

  if (response.data.sac_exists) {
      setErrors((prev) => ({ ...prev, sac: 'SAC already exists' }));
  } else {
      setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
    //   setErrors3({
    //     itemName: ''
    //    // Reset other error fields as needed
    //  });
  }}
  else{
    setSac(value);
    setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
  }
};
// const handleHsnChange = (value) => {
//     setHsn(value);
//     setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
// };

// const handleSacChange = (value) => {
//     setSac(value);
//     setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
// };
// validations
const validateItemName = (value) => {
  if (!value.trim()) return "Item name is required.";
  if (allItems.find(item=>item.item_name===value)) {   
      setValid(true)
      return (
      <p style={{width:'65%'}} className='text-danger'>Item Name already exist. Choose another name</p>
      );
  } 
  setValid(false)
  return (
      <p className='text-success'>Valid</p>
  ); // Returning string "Valid" for successful validation
};

const validateHsn = (value) => {
  if (!value.trim()) return "HSN is required.";
  if (!/^\d{6,}$/.test(value)) return "HSN must be 6 digits.";
  if (allItems.find(item=>item.item_hsn==value)){
      setValid(true)
      return "hsn already exist"
  } 
  setValid(false)
  setErrors3({
      itemName: ''
     
   });
  return (
      <p className='text-success'>Valid</p>
      
  ); // Return an empty string if validation is successful
};

const validateSac = (value) => {
  if (!value.trim()) return "SAC is required.";
  if (!/^\d{6,}$/.test(value)) return "SAC must be 6 digits.";
  if (allItems.find(item=>item.item_sac==value)){
      setValid(true)
      return "SAC already exist"
  } 
  setValid(false)
  setErrors3({
    itemName: ''
   
 });
  return (
      <p className='text-success'>Valid</p>
  );;
};
const [isTaxable, setIsTaxable] = useState(true);
  // const [activeTab, setActiveTab] = useState('pricing');

  // const [isTaxable, setIsTaxable] = useState(true);
  // const [activeTab, setActiveTab] = useState('pricing');

  const[itemName,setItemName] = useState('')
  const[hsn,setHsn] = useState('')
   const[sac,setSac] = useState('')
 const [showStock, setShowStock] = useState(false);
 const fetchUnits = async () => {
  try{
    const user = Cookies.get("role");
    const user_id = Cookies.get("user_id");
    const response = await axios.get(
      `${config.base_url}all_unit1`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params:{
          'user_id':user_id,"role":user
        }
      }
    );
    if (response.data.status === 200) {
      console.log(response);
      console.log("units:",response.data)
      setAllUnit(response.data.data);
      return response.data.data;
      // console.log()
   
    } else {
      console.log(response);
    }
  }
  catch (err) {
    console.error("Fetch Error:", err);
  }

} 
const [errors5, setErrors5] = useState({
    addunit:''
});
const handleAddUnit = async (e, id) => {
  e.preventDefault();
  const ID = Cookies.get("user_id");
  const user = Cookies.get("role");
  const data = {
      unit_name: addunit, // Ensure addunit is not empty
      user: ID, // Ensure ID is valid
      role: user, // Ensure role is not undefined
  };

  console.log("Data being sent:", data); // Debugging

  try {
      const response = await axios.post(`${config.base_url}add_unit1`, data, {
          headers: {
              "Content-Type": "application/json", // Updated to JSON
          },
      });
      console.log(response.data);
      if (response.data.status === 200) {
          alert("Unit added successfully!");
          setModal1Open(false);
          fetchUnits();
          setAddUnit('');
          setErrors5({
            addunit:''
             // Reset other error fields as needed
           });
      } else {
          console.log("Error:", response.data.message);
      }
  } catch (err) {
      // alert("unit already exist")
      setErrors5((prev) => ({
        ...prev,
        addunit: "Unit already exists", // Set the error for item name
      }));
      console.error("Error:", err);
  }
};


//   const handleAddUnit = async(e,id)=>{
//     e.preventDefault();
//     const ID = Cookies.get("user_id");
//     const user = Cookies.get("role");
//     const user_id = ID
//     const data={
//         unit_name:addunit,
//         user: user_id,
//         role: user
//     }
//     try {
//         const response = await axios.post(`${config.base_url}add_unit1`,data,{
//           headers:{
//             'Content-Type':'multipart/form-data',
//           }
//         });
//         console.log(response)
//         if(response.data.status===200){
//             setCount(id)
//           console.log(response)
//           alert("Unit added successfully!");
//           setModal1Open(false)
         
//           fetchUnits()
        
//         }
//         else{
//           console.log("error1")
          
//         }
        
//       } catch (err) {
//         console.log("error2",err)
       
//       }
// }
 
  // const handleStateOfSupplyChange = (e) => {
  //   const selectedState = e.target.value; // Get the selected state from the input
  //   setSelectedState(selectedState); // Update selectedState
  
  //   // Now, you can use selectedState to update rows or other actions
  //   const updatedRows = rows.map((row) => ({
  //     ...row,
  //     item_tax: selectedState === "state" ? row.item_gst : row.item_igst,
  //   }));
  //   setRows(updatedRows); // Update rows with the new tax values
  // };
  const handleAdjustmentChange = (e) => {
    const newAdjustment = parseFloat(e.target.value) || 0; // Ensure adjustment is a number
    setInvoiceData((prev) => {
      const subtotal = parseFloat(prev.subtotal) || 0; // Convert subtotal to a number
      const totalTax = parseFloat(prev.total_taxamount) || 0; // Convert total_taxamount to a number
  
      const updatedGrandtotal = subtotal + totalTax - newAdjustment;
      console.log("Updated Grand Total:", updatedGrandtotal); // Debugging log
      return {
        ...prev,
        adjustment: newAdjustment,
        grandtotal: updatedGrandtotal,
      };
    });
  };
  
  
//   const handleDecriptionChange = (event) => {
//     const descriptionValue = parseFloat(event.target.value) || 0; // Ensure value is a number
//     setDescription(descriptionValue);
//     // setGrandTotal(subTotal + taxAmount - adjustmentValue); // Uncomment this if needed to update grand total
// };
const handleDecriptionChange = (event) => {
  const descriptionValue = event.target.value; // Treat value as text
  setDescription(descriptionValue); // Store text input directly
};
const handlePartyChange = (e) => {
  const selectedPartyId = e.target.value;
  // console.log("1111",selectedPartyId)
  // const { name, value } = e.target;
  setSelectedParty(selectedPartyId);
  console.log(selectedPartyId)
  // Find the selected party details
  const selectedPartyDetails = parties.find(
    (party) => party.id === parseInt(selectedPartyId)
  );

  if (selectedPartyDetails) {
    const email = selectedPartyDetails.email;

    // Check if the email is valid
    // if (!emailRegex.test(email)) {
    //   alert("Invalid email format");
    //   return; // Stop further processing if email is invalid
    // }
    // setPhoneNumber(selectedPartyDetails.phone_number);
    // setBillingAddress(selectedPartyDetails.billing_address);
    console.log(selectedPartyDetails.phone_number)
    setInvoiceData((prev) => ({
            ...prev,
            party_name: selectedPartyDetails.party_name || "",
            contact: selectedPartyDetails.phone_number || "",
            address: selectedPartyDetails.billing_address || "",
            
          }));
  } else {
    setPhoneNumber("");
    setBillingAddress("");
  }
};
// const handlePartyChange = async (event) => {
//   const selectedPartyId = event.target.value;
//   setInvoiceData({ ...invoiceData, party_name: selectedPartyId });
//   console.log(selectedPartyId)
//   try {

//     // axios.get(`http://localhost:8000/api/items/${itemId}/`)
//     // Make an AJAX request to fetch party details
//     //  `${config.base_url}/api/save_invoice/`
//     const response = await axios.get(`${config.base_url}api/party_details/${selectedPartyId}/`);
//     const { phone_number, billing_address } = response.data;
// console.log(response.data)
//     // Update phone number and billing address
//     setInvoiceData((prev) => ({
//       ...prev,
//       contact: phone_number || "",
//       address: billing_address || "",
//     }));
    
//   } catch (error) {
//     console.error("Error fetching party details:", error);
//   }
// };
    // const handlePartyChange = (e) => {
    //     const selectedPartyId = e.target.value;
    //     setSelectedParty(selectedPartyId);
    
    //     // Find the selected party details
    //     const selectedPartyDetails = parties.find(
    //       (party) => party.id === parseInt(selectedPartyId)
    //     );
    
    //     if (selectedPartyDetails) {
    //       setPhoneNumber(selectedPartyDetails.phone_number);
    //       setBillingAddress(selectedPartyDetails.billing_address);
    //     } else {
    //       setPhoneNumber("");
    //       setBillingAddress("");
    //     }
    //   };
    const handleQuantityChange = (index, value) => {
      const updatedRows = [...rows];
      
      // Update the quantity
      updatedRows[index].quantity = parseFloat(value) || 0;
    
      // Calculate and update the totalamount
      const salePrice = parseFloat(updatedRows[index].rate) || 0;
      console.log("sale price is",salePrice)
      const discount = parseFloat(updatedRows[index].discount) || 0;
      console.log("discount is",discount)
      updatedRows[index].totalamount = (salePrice * updatedRows[index].quantity) - discount;
    
      // Update the state
      setRows(updatedRows);
      invoiceData.subtotal = updatedRows.reduce((sum, row) => {
        // const amount = parseFloat(row.totalamount) || 0; // Convert totalamount to a number or default to 0
        // console.log("amount is",amount)
        // console.log("+++",sum + amount)
        const amount= parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)))
        return sum + amount; // Add the numeric value
      }, 0);
      
      invoiceData.cgst = updatedRows.reduce((sum, row) => {
        // Ensure row.tax is a valid number and row.totalamount is defined
        // const Total1=updatedRows[index].totalamount
        // console.log
        console.log("tax",row.tax)
        console.log("disc",row.discount)
        console.log("subtotal",row.rate)
        const taxRate =row.tax.match(/(\d+)(?=%)/)[0]  ;
        // const taxValue = stateType === "State"
        // ? (row.gst ? row.gst.replace(/\D/g, "") : "") 
        // : (row.igst ? row.igst.replace(/\D/g, "") : "");
        console.log(taxRate)
        
        // const taxValue1= parseFloat((row.rate*row.tax))/100
        const taxValue1 = parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)*(parseFloat(taxRate) || 0)) / 100);

        console.log("tax is ",taxValue1)
        // const taxRate = parseFloat(row.tax) || 0; // Convert tax to a number or default to 0
        // const amount = parseFloat(row.totalamount) || 0; // Ensure totalamount is a number
        // console.log("sum is",sum)
        // console.log("tax is" , (amount * taxRate) / 100 )
        return sum + taxValue1; // Calculate tax and add to the sum
       
      }, 0) / 2; // Divide the total GST by 2
      
      // invoiceData.cgst = updatedRows.reduce((sum, row) => sum + row.totalamount, 0);
      invoiceData.sgst = invoiceData.cgst // Example 9% SGST
      invoiceData.total_taxamount = invoiceData.cgst + invoiceData.sgst;
      // invoiceData.adjustment
      invoiceData.grandtotal = invoiceData.subtotal + invoiceData.total_taxamount -invoiceData.adjustment
    
      // setInvoiceData({
      //   ...invoiceData,
      //   subtotal,
      //   cgst,
      //   sgst,
      //   total_taxamount,
      //   grandtotal,
      // });
      // updateInvoiceData(updatedRows);
      console.log("Updated Rows:", invoiceData.subtotal);
    };
    
    const updateInvoiceData = (updatedRows) => {
      // console.log("Updated Rows:", updatedRows);
      
      // const subtotal = updatedRows.reduce((sum, row) => sum + row.totalamount, 0);
      // const cgst = (subtotal * 9) / 100; // Example 9% CGST
      // const sgst = (subtotal * 9) / 100; // Example 9% SGST
      const total_taxamount = cgst + sgst;
      // const grandtotal = subtotal + total_taxamount + (invoiceData.adjustment || 0);
    
      setInvoiceData({
        ...invoiceData,
        // subtotal,
        cgst,
        sgst,
        total_taxamount,
        // grandtotal,
      });
      // console.log("Updated Rows:", updatedRows);
    };
    const [errors3, setErrors3] = useState({ itemName: '' });

  const handleItemNameChange = async (value) => {
      setItemName(value); // Update the item name state
      const user = Cookies.get("role");
      const user_id = Cookies.get("user_id");
      console.log("error.hsn",errors.hsn)
      
      try {
        // Make the API call
        const response = await axios.get(`${config.base_url}api/validate_item_name/`, {
          params: {
            item_name: value,
            user_id: user_id,
            role: user,
          },
        });
    
        console.log("API Response:", response.data); // Debugging: check the response
    
        // Check if the item exists
        if (response.data.item_exists) {
          setErrors3((prev) => ({
            ...prev,
            itemName: "Item already exists", // Set the error for item name
          }));
        } else {
          // Clear the error if item is valid
          setErrors3((prev) => ({
            ...prev,
            itemName: "",
          }));
        }
      } catch (error) {
        console.error("Validation error:", error); // Log the error for debugging
        setErrors3((prev) => ({
          ...prev,
          itemName: "An error occurred. Please try again.", // Set a generic error message
        }));
      }
    };
      const handleItemChange = (index, itemId) => {
        const state_of_supply = document.getElementById("stateOfSupply").value;
        // const isDuplicate = rows.some(row => row.item__item_name === itemId);
        // if (isDuplicate) {
        //   alert("This item has already been added.");
        //   return; // Prevent further processing if duplicate
        // }
        // const isDuplicate = rows.some(
        //   (row, i) => row.hsn && i !== index 
        // );
        // if (isDuplicate) {
        //   alert("This item has already been added.");
        //   return; // Prevent further processing if duplicate
        // }
        const extractTaxValue = (taxString) => {
          return parseFloat(taxString.replace(/[^0-9.]/g, '')); // Remove non-numeric characters
        };
         const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
        axios.get(`${config.base_url}api/items1/${itemId}/`, {
          params: {  // Add query parameters
              user: user,
              user_id: user_id
          }
      })
    .then(response => {
        console.log(response.data); // Debug API response
        const selectedItem = response.data;
        const updatedRows = [...rows];
        const isDuplicate = rows.some(
          (row, i) => row.hsn === selectedItem.item_hsn && i !== index
        );
  
        if (isDuplicate) {
          alert("Duplicate item detected: This item's HSN code has already been added.");
          return; // Exit the function if duplicate is found
        }
        // console.log("item details ",itemId)
        updatedRows[index] = {
            ...updatedRows[index],
            item__id: itemId,
            // item__item_name: itemId,
            hsn: selectedItem.item_hsn,
            sac: selectedItem.item_sac,
            // tax : state_of_supply === "State"
            //             ? `GST${extractTaxValue(selectedItem.item_gst)}[${extractTaxValue(selectedItem.item_gst)}%]`  // Extract integer from GST
            //          : `IGST${extractTaxValue(selectedItem.item_igst)}[${extractTaxValue(selectedItem.item_igst)}%]`,
            tax: state_of_supply === "State"
  ? `GST${extractTaxValue(selectedItem.item_gst || "0")}[${extractTaxValue(selectedItem.item_gst || "0")}%]`
  : `IGST${extractTaxValue(selectedItem.item_igst || "0")}[${extractTaxValue(selectedItem.item_igst || "0")}%]`,

            item__item_gst:selectedItem.item_gst,
            item__item_igst:selectedItem.item_igst,

            // tax: selectedItem.item_gst.replace('%', ''),
            rate: selectedItem.item_sale_price,
            quantity: 0,
            discount: 0,
            totalamount: 0,
        };
        setRows(updatedRows);
        invoiceData.subtotal = updatedRows.reduce((sum, row) => {
          // const amount = parseFloat(row.totalamount) || 0; // Convert totalamount to a number or default to 0
          // console.log("amount is",amount)
          // console.log("+++",sum + amount)
          const amount= parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)))
          return sum + amount; // Add the numeric value
        }, 0);
        
        invoiceData.cgst = updatedRows.reduce((sum, row) => {
          // Ensure row.tax is a valid number and row.totalamount is defined
          // const Total1=updatedRows[index].totalamount
          // console.log
          console.log("tax",row.tax)
          console.log("disc",row.discount)
          console.log("subtotal",row.rate)
          const taxRate =row.tax.match(/(\d+)(?=%)/)[0]  ;
          // const taxValue = stateType === "State"
          // ? (row.gst ? row.gst.replace(/\D/g, "") : "") 
          // : (row.igst ? row.igst.replace(/\D/g, "") : "");
          console.log(taxRate)
          
          // const taxValue1= parseFloat((row.rate*row.tax))/100
          const taxValue1 = parseFloat(((parseFloat(row.rate) || 0) * (parseFloat(row.quantity) || 0)*(parseFloat(taxRate) || 0)) / 100);
  
          console.log("tax is ",taxValue1)
          // const taxRate = parseFloat(row.tax) || 0; // Convert tax to a number or default to 0
          // const amount = parseFloat(row.totalamount) || 0; // Ensure totalamount is a number
          // console.log("sum is",sum)
          // console.log("tax is" , (amount * taxRate) / 100 )
          return sum + taxValue1; // Calculate tax and add to the sum
         
        }, 0) / 2; // Divide the total GST by 2
        
        // invoiceData.cgst = updatedRows.reduce((sum, row) => sum + row.totalamount, 0);
        invoiceData.sgst = invoiceData.cgst // Example 9% SGST
        invoiceData.total_taxamount = invoiceData.cgst + invoiceData.sgst;
        // invoiceData.adjustment
        invoiceData.grandtotal = invoiceData.subtotal + invoiceData.total_taxamount -invoiceData.adjustment
      
    })
    .catch(error => {
        console.error("Error fetching item details:", error);
    });

      };
      const handlePriceChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].rate = value;
        // Recalculate the total with discount
        updatedRows[index].totalamount = updatedRows[index].rate * updatedRows[index].quantity - updatedRows[index].discount;
        setRows(updatedRows);
      };
     
      const handleDiscountChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].discount = value;
        // Recalculate the total with discount
        updatedRows[index].totalamount = updatedRows[index].rate * updatedRows[index].quantity - value;
        
        setRows(updatedRows);
        invoiceData.cgst = updatedRows.reduce((sum, row) => {
          // Ensure row.tax is a valid number and row.totalamount is defined
          const taxRate = parseFloat(row.tax) || 0; // Convert tax to a number or default to 0
          const amount = parseFloat(row.totalamount) || 0; // Ensure totalamount is a number
          return sum + (amount * taxRate) / 100; // Calculate tax and add to the sum
        }, 0) / 2; // Divide the total GST by 2
        
        // invoiceData.cgst = updatedRows.reduce((sum, row) => sum + row.totalamount, 0);
        invoiceData.sgst = invoiceData.cgst // Example 9% SGST
        invoiceData.total_taxamount = invoiceData.cgst + invoiceData.sgst;
        // invoiceData.adjustment
        invoiceData.grandtotal = invoiceData.subtotal + invoiceData.total_taxamount -invoiceData.adjustment
        // updateInvoiceData(updatedRows);
        
        // recalculateInvoice(updatedRows);
      };
    
      
      // Fetch item names from the backend
      // useEffect(() => {
      //   console.log("data123",invoiceData.party_name)
      //   axios.get(`${config.base_url}/get_item_names/`)
      //     .then(response => {
      //       setItems(response.data); // Populate item names
      //     })
      //     .catch(error => {
      //       console.error('Error fetching items:', error);
      //     });
      // }, []);
       useEffect(() => {
    async function fetchdata() {
      try {
          const user = Cookies.get("role");
                const user_id = Cookies.get("user_id");
        const response = await axios.get(
          `${config.base_url}all_items_filter`,
          {
            headers: {
              "Content-Type": "application/json",
            },params:{
              'user_id':user_id,"role":user
            }
          }
        );
        if (response.data.status === 200) {
          console.log(response);
          setItems(response.data.data);
          // setStockInHand(response.data.data.item_stock_in_hand)
          // setDate(response.data.data.item_date)
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, [config.base_url]);
  return (
    <div className="container-scroller"  style={{ background: "#636e99"}}>
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex"  style={{ background: "#636e99"}}>
        <Sidebar />
        {/* ( */}
        
     <div className='main-panel ml-2 bg-white'  style={{ width: "100%" }}>
     <h3
              className=" text-light "
              style={{
                backgroundColor: "#3d4465",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                marginTop:"0px"
                // marginBottom:"10px"
              }}
            >
              <span style={{ flex: 1,  fontWeight: "bold" }}>
                Edit Sales Invoice
              </span>
              <button
                className="btn "
                onClick={handleBack} // Toggle button state
                style={{
                  borderRadius: "8px",
                  marginLeft: "auto",backgroundColor:"white",color:"black"
                }}
              >
                <i className="fas fa-arrow-left" style={{ marginRight: "8px" }}></i>
              Back
              </button>
            </h3>
            
                    <form className='m-4 bg-white '>
                    <div className="row mb-4 align-items-center">
      {/* Party Name */}
     
      <div className="col-md-4 mt-3">
        <div className="floating-label d-flex gap-2">
          <label
            htmlFor="partyName"
            className="form-label  mb-5 pb-5"
            style={{
              display: "block",
              
            }}
          >
            Party Name
          </label>
          <select
            id="partyName"
            className="form-control ml-2 w-100"
            // value={invoiceData.party_name && party_name.toLowerCase() !== "null")|| ""} 
            value={invoiceData.party_name && invoiceData.party_name.toLowerCase() !== "null" ? invoiceData.party_name : ""}

            onChange={handlePartyChange} 
            style={{border: "1px solid rgb(86, 4, 248)" }}// Attach the handler
          >
            <option value={invoiceData.party_name || ""} disabled>
            {invoiceData.party_name || "Select Party"}
            </option>
            {/* {parties
  .filter((party) => party.party_name !== invoiceData.party_name)  // Filter out the selected party
  .map((party) => (
    <option key={party.id} value={party.party_name}>
      {party.party_name}
    </option>
  ))} */}
 {parties.map((party) => (
          <option key={party.id} value={party.id}>
            {party.party_name}
          </option>
        ))}
          </select>
          <button
          type="button"
          className="btn " style={{ backgroundColor: '#3d4465',color:'white'}}
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModal2"
          onClick={() => setShowModal(true)}
        >
       <b>   +</b> 
        </button>
        </div>
        
       
        </div>
        {showModal && ( 

//       </div>
//     </div>  
<div className="modal " id="customercreation" aria-modal="true" style={{ display: 'block',overflow:"auto" }}>
<div className="modal-dialog modal-xl">
  <div className="modal-content">
    <div className="modal-body" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
      <form action="" method="POST" autoComplete="off" id="modalCustomer">
        <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
        <div className="modal-header" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
          <h3 className="m-3 text" style={{ color: 'white' }}>ADD PARTY</h3>
          <button
      type="button"
      className="btn-close"
      onClick={closeModal}
      style={{ border: 'none', background: 'none' }}
    ><i class="fas fa-times"></i></button>
        </div>

        <div className="bg-light bs p-4 rounded mb-5 ">
          <div id="main_form_div">
            <div className="row" style={{ marginLeft: '-1vh' }}>
              <div className="col-md-4 ">
                <label className="col-form-label mb-5 pb-5 ml-3 mb-5" htmlFor="partyname" style={{ color: 'black' }}>
                  Party Name
                </label>
                <input
                  type="text"
                  className="form-control border-secondary mt-5"
                  id="partyname"
                  name="partyName"
                  value={formData.partyName}

                  // onChange={handleChangeparty}
                  onChange={(e) => handlePartyNameChange(e.target.value)}
                  style={{ color: 'black' }}
                  required
                />
                  {errors4.party && (
  <small style={{ color: 'red' }}>{errors4.party}</small> // Render the error message
)}
              </div>
              <div className="col-md-4">
                <label className="col-form-label mb-5  pb-5 ml-3" style={{ color: 'black' }}>
                  GSTIN
                </label>
                <input
                  type="text"
                  
                  // className={`form-control border-secondary mt-5 ${error ? 'is-invalid' : ''}`}
                  className={`form-control border-secondary mt-5 ${
                    errors.gstin ? "is-invalid" : ""
                  }`}
                  id="gstin"
                  name="gstin"
                  
                  value={formData.gstin}
                  // onChange={handleChangeparty}
                  onChange={handleGSTChange}
                  style={{ color: 'black' }}
                  placeholder="29APPCK7465F1Z1"
                  required
                />{errors.gstin && (
                  <div className="invalid-feedback" style={{ color: "red" }}>
                    {errors.gstin}
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <label className="col-form-label mb-5 pb-5 ml-3" htmlFor="partyphno" style={{ color: 'black' }}>
                  Mobile
                </label>
                <input
                  type="text"
                  className="form-control border-secondary mt-5"
                  id="partyphno"
                  name="partyPhNo"
                  value={formData.partyPhNo
                  }
                  // onChange={handleChangeparty}
                  onChange={(e) => handlePhoneNoChange(e.target.value)}
                  style={{ color: 'black' }}
                  required
                />
                   {errors2.partyPhNo && <span style={{ color: 'red' }}>{errors2.partyPhNo}</span>}
              </div>
            </div>

            <div>
              <p></p>
              <hr className="p-0 m-0" />
              <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                <div className="row pb-0 mb-0">
                  <div id="gsthead" className="col text-center pb-2"style={{
 borderBottom: !isCredit 
   ? '3px solid rgba(0, 0, 0, 0.55)' // Border when isCredit is true
   : 'none' 
  // No border when isCredit is false
}}>
                    <button
                      className="fw-bolder fs-4 "
                      style={{ color: 'white', background: 'none', border: 'none' ,color: !isCredit ? 'black' : 'white' }}
                      // onClick={() => setActiveTab('gst')}
                      // onClick={() => setIsCredit(false)}
                      onClick={(e) => {e.preventDefault();handleCreditPrev()}}
                    >
                      GST & ADDRESS
                    </button>
                  </div>
                  <div id="credithead" className="col text-center pb-2"  style={{
 borderBottom: isCredit 
   ? '3px solid rgba(0, 0, 0, 0.55)' // Border when isCredit is true
   : 'none' // No border when isCredit is false
}}>
                    <button
                      className="fw-bolder fs-4"
                      style={{ color: 'white', background: 'none', border: 'none' ,color: !isCredit ? 'white' : 'black' }}
                      // onClick={() => setActiveTab('credit')}

                      onClick={(e) =>{e.preventDefault(); setIsCredit(true)}}
                    >
                      CREDIT & BALANCES
                    </button>
                  </div>
                </div>
              </div>

              {!isCredit && (
               <div id="gsttemp">
               <div className="row mt-5">
                 {/* First Column - GST Type, Supply State, Email Section */}
                 <div className="col-md-6  d-flex flex-wrap">
{/* GST Type */}


<label className="pb-5 mb-5 pl-3" style={{ color: 'black'}}>
GST Type<br/><br/><br/><br/><br/><br/><br/><br/><br/>
</label>

<select
className="form-control border-secondary mb-3 mt-4"
name="gstType"
value={formData.gstType}
onChange={handleChangeparty}
style={{ color: 'black', backgroundColor: 'white' }}
>
<option value="" hidden>Select</option>
<option value="Registered Party">Registered Party</option>
<option value="Unregistered or Consumer">Unregistered or Consumer</option>
<option value="Registered Business or Combosision">Registered Business or Combosision</option>
</select>
{/* Supply State */}
<label className="col-floating-label mb-5 pl-3" style={{ color: 'black' }}>
Supply State<br/><br/><br/><br/>
</label>

<select
className="form-control border-secondary mt-4"
name="splyState"
value={formData.splyState}
onChange={(e) => {
setSelectedState(e.target.value);
handleChangeparty(e);
}}
style={{ color: 'black', backgroundColor: 'white' }}
>
<option value="">Select a State</option>
{states.map((state, index) => (
<option key={index} value={state}>
  {state}
</option>
))}
</select>

{/* Email Section */}
<label className="col-floating-label mt-5 pl-3" style={{ color: 'black'}}>
Email Address
</label><br/>
<input
type="email"
className="form-control border-secondary mt-5"
name="email"
value={formData.email}
// onChange={handleChangeparty}
onChange={(e) => handleEmailChange(e.target.value)}
style={{ color: 'black', backgroundColor: 'white' }}
placeholder="example@email.com"
/>
{errors1.email && <span style={{ color: 'red' }}>{errors1.email}</span>}
</div>

             
                 {/* Second Column - Billing Address */}
                 <div className="col-md-6 mt-5">
                   <label className="col-form-label ml-3 pb-5" style={{ color: 'black' }} htmlFor="baddress">
                     Billing Address<br/><br/><br/><br/><br/><br/><br/><br/><br/>
                   </label>
                   <textarea
                     className="form-control border-secondary"
                     name="bAddress"
                     value={formData.
                      bAddress}
                     onChange={handleChangeparty}
                     id="baddress"
                     rows="6"
                     style={{ color: 'black', backgroundColor: 'white' }}
                   />
                 </div>
               </div>
             
               <div className="text-start mt-5">
                 <button
                   id="gstnxt"
                   className="btn"
                   style={{ backgroundColor: 'rgb(61, 68, 101)' ,color:"white"}}
                   type="button"
                   onClick={() => setIsCredit(true)}
                 >
                  <b> NEXT</b>
                 </button>
               </div>
             </div>
             
              )}

              {isCredit && (
              <div id="credittemp">
              <div className="row">
                {/* Opening Balance with Radio Buttons */}
             

<div className="col-md-6 mt-2">
<div className='d-flex flex-wrap '>
              <div className="form-check d-flex align-items-center w-50 justify-content-between">
           <div> <label className="form-check-label text-black  ml-2 mt-2" htmlFor="flexRadioDefault1" style={{fontSize:"15px"}} >
Opening Balance - To Pay
</label>  </div>
<div>
<input
className="form-check-input mb-4 pb-5"
type="radio"
// name="flexRadioDefault"
// id="flexRadioDefault1"
// value="to_pay"
// onChange={handleChangeparty}
name="to_pay"
    id="flexRadioDefault1"
    // value="to_pay"
    // onChange={handleChangeparty}
     value="true"
    checked={formData.to_pay}  // Ensure the radio button is checked when to_pay is true
    onChange={handleChangeparty}
/>
</div>
</div>

                <div className="form-check d-flex align-items-center justify-content-between ">
                 <div>
                  <p className="form-check-label text-black  mt-3" htmlFor="flexRadioDefault2"  style={{fontSize:"15px"}}>
                   To Receive
                  </p></div>
                  <div>
                  <input
                    className="form-check-input  mb-5 pb-5 ml-5"
                    type="radio"
                    // name="flexRadioDefault"
                    // id="flexRadioDefault2"
                    // value="to_receive"
                    // onChange={handleChangeparty}
                    name="to_receive"
                    id="flexRadioDefault2"
                    // value="to_receive"
                    onChange={handleChangeparty}
                    value="true"
                    checked={formData.to_receive}  
                    // checked={!isTaxable}
                    // onChange={() => setIsTaxable(false)}
                  />
                  </div>
                </div>
              </div>
              <input
type="text"
className="form-control border-secondary mt-3 ml-2"
id="openbalance1"
name="openbalance1" // Name matches formData key
value={formData.openbalance1}
onChange={handleChangeparty}
style={{ color: 'black', backgroundColor: 'white' }}
/>

</div>
            
                {/* Credit Limit Field */}
                 <div className="col-md-6 ">
                 <label className="col-form-label ml-1 mt-1 pb-5" style={{ color: 'black' }} htmlFor="crd_lmt">
                    Credit Limit
                  </label>
                <br/>  <div >
                  <input
                    type="number"
                    className="form-control border-secondary mt-5 p-3"
                    id="crd_lmt"
                    name="crdLmt"
                    value={formData.crdLmt}
                    onChange={handleChangeparty}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  />
                  </div>
                </div>
              </div>
            
              {/* Date Field */}
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label  ml-4" style={{ color: 'black' }} htmlFor="partydate">
                    Date<br/><br/>
                  </label>
                  <input
                    type="date"
                    className="form-control border-secondary mt-5 ml-2"
                    id="partydate"
                    name="partyDate"
                    value={formData.partyDate}
                    onChange={handleChangeparty}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  />
                </div>
              </div>
            
              {/* Buttons for Save and Previous */}
              <div className="text-start mt-5 ">
                <button
                  id="creditprev"
                  className="btn fw-bold "
                  style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                  type="button"
                  onClick={handleCreditPrev}
                >
                  PREVIOUS
                </button>
                <button
                  className="btn fw-bold ml-2"
                  style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                  type="button"
                  id="customersave"
                  onClick={handleSubmitparty}
                >
                  SAVE
                </button>
              </div>
            </div>
            
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>  )}
      {/* <div className="modal fade custom-modal" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header" style={{background:'#3d4465',color:'white'}}>
            <h1 className="modal-title fs-5" id="exampleModalLabel" style={{background:'#3d4465',color:'white'}}>Add Party</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >&times;
            </button>
          </div>
          <div className="modal-body">
      {/* Modal Header 
      <div className='bg-white mt-4'>
        {/* Input Fields 
        <form className='input-field d-flex'>
          <div>
            <p style={{ color: '#3d4465' }}>Party Name:</p>
            <input
              type="text"
              className='item-input form-control'
              name="party_name"
              value={formData.party_name}
              onChange={handleChangeparty}
            />
          </div>
          <div>
            <p style={{ color: '#3d4465' }}>GSTIN:</p>
            <input
              type="text"
              className='item-input form-control'
              name="gstin"
              value={formData.gstin}
              onChange={handleChangeparty}
            />
          </div>
          <div>
            <p style={{ color: '#3d4465' }}>Mobile:</p>
            <input
              type="text"
              className='item-input form-control'
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChangeparty}
            />
          </div>
        </form>
    
        {/* Tab Navigation
        <div className='unit d-flex flex-wrap gap-2'>
          <div style={{ backgroundColor: '#8290c6' }} className='p-s d-flex flex-wrap gap-4 p-3 mt-5'>
            <div
              className='gst m-auto'
              onClick={() => setActiveTab('gst')}
              style={{ cursor: 'pointer' }}
            >
              <h1
                className='fw-bold'
                style={{ color: activeTab === 'gst' ? '#181e3d' : '#fff' }}
              >
                GST & ADDRESS
              </h1>
              {activeTab === 'gst' && <hr style={{ borderColor: '#181e3d', borderWidth: '2px' }} />}
            </div>
    
            <div
              className='credit m-auto'
              onClick={() => setActiveTab('credit')}
              style={{ cursor: 'pointer' }}
            >
              <h1
                className='fw-bold'
                style={{ color: activeTab === 'credit' ? '#181e3d' : '#fff' }}
              >
                CREDIT & BALANCES
              </h1>
              {activeTab === 'credit' && <hr style={{ borderColor: '#181e3d', borderWidth: '2px' }} />}
            </div>
          </div>
        </div>
    
        {/* Tab Content 
        {activeTab === 'gst' ? (
          <form className='pricing-field p-4'>
            <div className='d-flex non-t gap-3'>
              <div className='w-50'>
                <p className='text-black fs-5 sale-p'>GST Type</p>
                <select
                  className='form-control'
                  name="gst_type"
                  value={formData.gst_type}
                  onChange={handleChangeparty}
                >
                  <option value="" disabled>Select</option>
                  <option value="Registered Party">Registered Party</option>
                  <option value="Unregistered">Unregistered Or Customer</option>
                  <option value="Registered Business">Registered Business Or Composition</option>
                </select>
    
                <p className='text-black fs-5 sale-p'>Supply State</p>
                <select
                  className='form-control'
                  name="state"
                  value={formData.state}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    handleChangeparty(e);
                  }}
                >
                  <option value="">Select a State</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>{state}</option>
                  ))}
                </select>
    
                <p className='text-black fs-5 sale-p'>Email</p>
                <input
                  type="text"
                  className='form-control'
                  name="email"
                  value={formData.email}
                  onChange={handleChangeparty}
                />
              </div>
    
              <div className='w-50'>
                <p className='text-black fs-5 sale-p'>Billing Address</p>
                <textarea
                  className='form-control'
                  name="billing_address"
                  value={formData.billing_address}
                  onChange={handleChangeparty}
                  style={{background:"white"}}
                />
              </div>
            </div>
            <button
              type="button"
              className='btn btn-primary mt-3'
              onClick={handleNextClick}
            >
              Next
            </button>
          </form>
        ) : (
          <form className='stocks-field p-4'>
            <div className='s-input d-flex gap-3'>
              <div className='w-50'>
                <p className='text-black fs-5'>Opening Balance</p>
                <div className="d-flex align-items-center mb-2">
                  <label className="me-3">To Pay</label>
                  <input
                    type="radio"
                    name="to_pay"
                    checked={formData.to_pay}
                    onChange={handleChangeparty}
                  />
    
                  <label className="me-3 " >To Receive</label>
                  <input
                    type="radio"
                    name="to_receive"
                    checked={formData.to_receive}
                    onChange={handleChangeparty}
                  />
                </div>
                <input
                  type="text"
                  className='form-control'
                  name="opening_balance"
                  value={formData.opening_balance}
                  onChange={handleChangeparty}
                  placeholder="Enter balance value"
                />
              </div>
    
              <div className='w-50'>
                <p className='text-black fs-5'>Credit Limit</p>
                <input
                  type="text"
                  className='form-control'
                  name="credit_limit"
                  value={formData.credit_limit}
                  onChange={handleChangeparty}
                  placeholder="Enter credit limit"
                />
              </div>
            </div>
    
            <div className='mt-3 s-input w-50'>
              <p className='text-black fs-5'>Date</p>
              <input
                type="date"
                className='form-control'
                name="date"
                value={formData.date}
                onChange={handleChangeparty}
              />
            </div>
    
            <div className='d-flex flex-wrap gap-3 mt-2'>
              <button
                type="button"
                className='btn btn-secondary mt-3'
                onClick={handlePreviousClick}
              >
                Previous
              </button>
    
              <button
                type="submit"
                className='btn btn-primary mt-3'
                onClick={handleSubmitparty}
              >
                Save
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
    
      </div>
    </div>
                                            {/* <button
                                                onClick={() => setShowModal(true)}
                                                className="btn btn-sm btn-primary"
                                            >
                                                +
                                            </button> 
                                        </div>/*}
      {/* + Button */}
      {/* <div className="col-md-1 justify-content-center ">
      
        
      </div> */}
    
      {/* Phone Number */}
      <div className="col-md-4  mt-3">
        <div className="floating-label">
          <label
            htmlFor="phoneNumber"
            className="form-label pb-5 mb-5 "
            style={{
              display: "block",
             
            }}
          >
            Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            className="form-control ml-2"
            placeholder="Enter Phone Number"
            style={{
              borderRadius: "4px",
              // padding: "10px",
              fontSize: "16px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
            }}
            value={invoiceData.contact || ""}
            readOnly
    
          />
        </div>
      </div>
    
      {/* Billing Address */}
      <div className="col-md-4 mt-4 ">
        <div className="floating-label">
          <label
            htmlFor="billingAddress"
            className="form-label pb-5 mb-5"
            style={{
              display: "block",
              // marginBottom: "8px",
              // padding: "12px"
            }}
          >
            Billing Address
          </label>
          <textarea
            id="billingAddress"
            className="form-control ml-2 mb-2 pb-2"
            rows="3"
            placeholder="Enter Billing Address"
            style={{
              borderRadius: "4px",
              // padding: "10px",
              fontSize: "14px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
            }}
            value={invoiceData.address || ""}
            readOnly
    
          ></textarea>
        </div>
      </div>
    </div>
    
    <br/>
      <div className="row mb-4">
        <div className="col-md-4 mb-4">
          <div className="floating-label ">
          <label htmlFor="invoiceNo" className="mb-5 pb-5" >Invoice No</label>
            <input
              type="text"
              className="form-control ml-2"
              id="invoiceNo"
              value={invoiceData.invoice_no || ""}
            
    
              placeholder="Enter Invoice No"
              // defaultValue="1"
              style={{ border: "1px solid rgb(86, 4, 248)"}}
            />
            {/* <label htmlFor="invoiceNo"  style={{ padding: "12px",marginBottom:"20px" }}>Invoice No</label> */}
          </div>
        </div>
        <div className="col-md-4  mb-4">
          <div className="floating-label">
          <label htmlFor="date" className="mb-5 pb-5 ">Date</label>
                    
            <input
              type="date"
              className="form-control ml-2"
              id="date"
              value={invoiceData.date || ""}

              // defaultValue={new Date().toISOString().split("T")[0]}
              style={{border: "1px solid rgb(86, 4, 248)" }}
            />
            {/* <label htmlFor="date">Date</label> */}
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="floating-label">
          <label htmlFor="stateOfSupply" className="mb-5 pb-5">State Of Supply</label>
                    
            <select
              className="form-control ml-2"
              id="stateOfSupply"
              style={{ border: "1px solid rgb(86, 4, 248)"}} 
              onChange={handleStateOfSupplyChange}
              value={invoiceData.state_of_supply || ""}
              // value={stateOfSupply}
              // onChange={handleStateOfSupplyChange}
            >
              <option value="State">State</option>
              <option value="otherState">Other State</option>
            </select>
            {/* <label htmlFor="stateOfSupply">State Of Supply</label> */}
          </div>
        </div>
      </div>
    
     
    
  
      <div className="row  mb-2 d-flex">
        <div className="col-md-6 mb-2">
          <div className="floating-label">
            <label
              htmlFor="paymentType"
              className="form-label mb-5 pb-5"
              // style={{ display: "block" }}
            >
              Payment Type
            </label>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "15px" }}> */}
          {/* Dropdown for Payment Type */}
          <select
            className="form-select mt-2 ml-2"
            id="paymentType"
            style={{
              borderRadius: "4px",
              // width: "450px",
              padding: "5px",
              fontSize: "14px",
            border: "1px solid rgb(86, 4, 248)"
            }}
            // value={invoiceData.paymenttype || ""}
            value={selectedPayment} 
            onChange={(e) => setSelectedPayment(e.target.value)}
          >
            <option value="" disabled selected>
              Select Payment Type
            </option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="UPI">UPI</option>
            <option value="Bank">Bank</option>
          </select>
          {/* </div> */}
          </div>
          </div>
        
          {/* Conditional Input Fields */}
          {selectedPayment === "Cheque" && (
          //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          //     <label htmlFor="chequeId" style={{ display: "block", marginBottom: "5px", padding:"300px" }} className='ml-4'>
          //       ChequeID: <br/><br/><br/>
          //     </label>
          //     {/* <input
          //       type="text"
          //       id="chequeId"
          //       className="form-control mt-2"
          //       style={{
          //         borderRadius: "8px",
          //         padding: "5px",
          //         width: "200px",
                  
          //       }}
          //       value={invoiceData.cheque|| ""}
          //       onChange={(e) => setInvoiceData({ ...invoiceData, cheque: e.target.value })} 
          //     /> */}
          //       <input
          //   type="text"
          //   id="chequeId"
          //   className="form-control mt-2"
          //   // placeholder="Enter Phone Number"
          //   style={{
          //     borderRadius: "4px",
          //     // padding: "10px",
          //     width:"250px",
          //     fontSize: "16px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
          //   }}
          //   value={invoiceData.cheque|| ""}
          //       onChange={(e) => setInvoiceData({ ...invoiceData, cheque: e.target.value })} 
    
          // />
          //   </div>
          <div className="col-md-6"> {/* Same row */}
          <div className="floating-label ">
            <label htmlFor="chequeId" className="form-label  mb-5 pb-5">
            ChequeID
            </label>
            <input
              type="text"
              id="chequeId"
              className="form-control mt-1 ml-2"
              style={{
                borderRadius: "4px",
                  border: "1px solid rgb(86, 4, 248)"
                // padding: "5px",
                // border: "1px solid #ccc",
              }}
              value={invoiceData.cheque|| ""}
                onChange={(e) => setInvoiceData({ ...invoiceData, cheque: e.target.value })}   // Update state
            />
          </div>
        </div>
          )}
    
          {selectedPayment === "UPI" && (
            // <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //   <label htmlFor="upiId" style={{  marginBottom: "5px", padding:"300px" }} className='ml-4'>
            //     UPI_ID: <br/><br/><br/>
            //   </label>
            //   <input
            //     type="text"
            //     id="upiId"
            //     className="form-control mt-2"
            //     style={{
            //       borderRadius: "4px",
            //   // padding: "10px",
            //   width:"250px",
            //   fontSize: "16px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
            //     }}
            //     value={invoiceData.upi|| ""}
            //     onChange={(e) => setInvoiceData({ ...invoiceData, upi: e.target.value })} 
            //   />
            // </div>
            <div className="col-md-6"> {/* Same row */}
            <div className="floating-label ">
              <label htmlFor="upiId" className="form-label  mb-5 pb-5">
              UPI ID
              </label>
              <input
                type="text"
                id="upiId"
                className="form-control mt-1 ml-2"
                style={{
                  borderRadius: "4px",
                    border: "1px solid rgb(86, 4, 248)"
                  // padding: "5px",
                  // border: "1px solid #ccc",
                }}
                value={invoiceData.upi|| ""}
                onChange={(e) => setInvoiceData({ ...invoiceData, upi: e.target.value })}   // Update state
              />
            </div>
          </div>
          )}
    
          {selectedPayment === "Bank" && (
            // <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            //   <label htmlFor="bankAccount" style={{ display: "block", marginBottom: "5px", padding:"330px" }} className='mr-2'>
            //     BankAccountNumber: <br/><br/><br/>
            //   </label>
            //   <input
            //     type="text"
            //     id="bankAccount"
            //     className="form-control mt-2"
            //     style={{
            //       borderRadius: "4px",
            //   // padding: "10px",
            //   width:"250px",
            //   fontSize: "16px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
            //     }}
            //     value={invoiceData.bankAccount||""}
            //     onChange={(e) => setInvoiceData({ ...invoiceData, bankAccount: e.target.value })} 
             
            //   />
            // </div>
            <div className="col-md-6"> {/* Same row */}
            <div className="floating-label ">
              <label htmlFor="bankAccount" className="form-label  mb-5 pb-5">
              Bank Account Number
              </label>
              <input
                type="text"
                id="bankAccount"
                className="form-control mt-1 ml-2"
                style={{
                  borderRadius: "4px",
                    border: "1px solid rgb(86, 4, 248)"
                  // padding: "5px",
                  // border: "1px solid #ccc",
                }}
                value={invoiceData.bankAccount||""} // Controlled input
                onChange={(e) => setInvoiceData({ ...invoiceData, bankAccount: e.target.value })}  // Update state
              />
            </div>
          </div>
          )}
            {/* </div> */}
        {/* </div> */}
        {/* </div>
        </div> */}
      </div>
    
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="mb-2">
            <label
              htmlFor="barcodeInput"
              className="floating-label"
              style={{
                display: "block",
                
                fontSize: "16px"
              }}
            >
              Add Item With Barcode
            </label>
            <input
              type="text"
              id="barcodeInput"
              className="form-label   mb-1"
              placeholder="Scan or enter barcode"
              style={{
                borderRadius: "4px",
                
                fontSize: "14px",
                fontStyle: "italic",
                border:'1px solid green'
              }}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive  " >
                <table className="table table-bordered ">
        <thead>
          <tr style={{ backgroundColor: '#3d4465',color:"white" }}
          >
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>#</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Product</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>HSN/SAC</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Qty</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Price</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Tax (%)</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Discount</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Total</th>
            <th  style={{ backgroundColor: '#3d4465',color:"white" }}>Field</th>
          </tr>
        </thead>
        <tbody>
            {rows.map((row, index) => (
              <tr key={row.id} onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")} // Hover color
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")} // Reset color
          >
                <td>{index+1}</td>
                <td>
      <div style={{ display: "flex", alignItems: "center", gap: "8px"}}>
        <select
          className="form-control"
          onChange={(e) => handleItemChange(index, e.target.value)}
          style={{ flex: "1" ,  border: "1px solid rgb(86, 4, 248)"}} /* Make the select box take up available space */
          // value={row.item__item_name || "N/A"}
          value={row.item__id || ""}
        >
           {/* <option value={row.item__id || "N/A"} >Select Item</option> */}
           <option value="" disabled>
           {row.item__item_name || "Select Item"}
    </option>
    {items.map((item) => (
      <option key={item.id} value={item.id} >
        {item.item_name}
      </option>
    ))}

        </select>
        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#3d4465",
            color: "white",
            padding: "8px 12px",
          }}
          onClick={() => setShowModalItem3(index+1)}
         
        >
        <b> +</b> 
        </button>
        {showModalItem3===index+1 && ( 
         <div class="modal fade show" id="showItem" 
         //  aria-modal="true"
        
           style={{ display: 'block',overflow:"auto" }}>
          <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <form 
                // onSubmit={handleSubmit} 
                className="needs-validation" noValidate autoComplete="off" id="modalItem" >
                  <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
                  <div className="bs p-4 rounded mb-5" style={{ backgroundColor: '#3d4465' }}>
                    <div className="row d-md-flex d-sm-block">
                      <h5 className="col mt-3">
                        <strong className="fw-bolder text-white fs-3">ADD ITEM </strong>
                      </h5>
                      {/* <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button> */}
                                   <button
     type="button"
     className="btn-close"
     onClick={closeModal1}
     style={{ border: 'none', background: 'none' }}
   ><i class="fas fa-times"></i></button>
                      {/* <a type="button" className="btn-close mr-5" data-dismiss="modal" aria-label="Close"
                      onClick={closeModal1}><b><i class="fas fa-times"></i></b></a> */}
                    </div>
                    <br /><br />
                    <div id="main_form_div" style={{ backgroundColor: 'white', padding: '3%' }}>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <p>Item Type:</p>
                          <select
                            style={{ color: 'black' }}
                            className="form-control border-dark bg-light"
                            // name="type"
                            // id="type"
                            // value={ItemData.type}
                            // onChange={handleChangeItem}
                            value={itemType}
                            onChange={(e)=>setItemType(e.target.value)} name="item-type" id="" 
                            // onChange={(e) => setItemType(e.target.value)}
                            required
                          >
                            <option selected value="" hidden>Select Type</option>
                            <option value="Goods">Goods</option>
                                  <option value="Service">Service</option>
      
                          </select>
                        </div>
                        <div className="col-md-3">
                        <p style={{color:'#3d4465'}} className=''>Item Name:</p>
                          <input
                            style={{ color: 'black' }}
                            className="form-control border-dark"
                            type="text"
                            value={itemName} 
                          onChange={(e) => handleItemNameChange(e.target.value)}   placeholder='Item Name' 
      
                  
                            required
                          />
                            {errors3.itemName && (
  <small style={{ color: 'red' }}>{errors3.itemName}</small> // Render the error message
)}
                        </div>
                        <div className="col-md-3">
                        {itemType === "" && (
                                  <div >
                                      <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                      <input 
                                          value={hsn} 
                                          onChange={(e) => handleHsnChange(e.target.value)}  
                                          type="text" 
                                          placeholder='Item HSN' 
                                          className='border-dark form-control ' 
                                      />
                                      {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                      
                                  </div>
                              )}
                          {itemType === "Goods" && (
                                  <div>
                                      <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                      <input 
                                          value={hsn} 
                                          onChange={(e) => handleHsnChange(e.target.value)} 
                                          type="text" 
                                          placeholder='Item HSN' 
                                          className='item-input form-control ' 
                                      />
                                      {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                  </div>
                              )}
                              {itemType === "Service" && (
                                  <div>
                                      <p style={{color:'#3d4465'}} className=''>Item SAC :</p>
                                      <input 
                                          value={sac} 
                                          onChange={(e)=>handleSacChange(e.target.value)}
                                          type="text" 
                                          placeholder='Item SAC' 
                                          className='item-input form-control ' 
                                      />
                                      {errors.sac && <small className="text-danger">{errors.sac}</small>}
                                  </div>
                              )}</div>
                        {/* <div className="col-md-3">
                          <label id="hsnLabel">Item HSN:<br/><br/><br/><br/></label>
                          <input
                            style={{ color: 'black' }}
                            className="form-control border-dark"
                            type="number"
                            placeholder="ITEM HSN"
                            id="hsn"
                            // name="hsn"
                            name="item_hsn"
                            value={ItemData.item_hsn}
                            onChange={handleChangeItem}
           
                            // value={hsn}
                            // onChange={(e) => setHsn(e.target.value)}
                            required
                          />
                        </div> */}
                        <div className="col-md-3">
                          <p>Item Unit:</p>
                          <div className="d-flex">
                            <select
                              style={{ color: 'black' }}
                              className="form-control border-dark bg-light"
                              id="units"
                              name="unit_name"  
                              value={ItemData.unit_name}
                              
                              // onChange={(e)=>setUnit(e.target.value)}  
                              onChange={handleChangeItem}
                              required
                            >
                                                             <option value="" disabled selected>Select Unit</option>
                                      <option value="Packages" >Packages</option>
                                      <option value="Numbers" >Numbers</option>
                                      <option value="Box" >Box</option>
                                      
                                      {allUnit.map((itm)=>(
                                          <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                      ))
                                          }
                                      
      
      
                            </select>
                            <button type="button" 
                            className="btn ml-2" style={{ backgroundColor: 'rgb(60, 61, 101)',height:"40px" }} 
                            data-toggle="modal"  
                            data-target="#exampleModal"
                            
                          
                            onClick={()=>{setModal1Open(true)}}
                           >
                               <span className="fa fa-plus" style={{ color: '#ffffff' }} ></span>
                            </button>
                            {isModal1Open &&(
<div
className="modal fade z-4"
id="exampleModal"
//  data-bs-backdrop="static"

//  tabindex="-1"
aria-labelledby="exampleModalLabel"
// aria-hidden="true"
>
<div className="modal-dialog">
  <div className="modal-content">
    <div
      style={{ backgroundColor: "#3d4465" }}
      className="modal-header"
    >
      <h1
        className="modal-title text-white fw-bold fs-5"
        id="exampleModalLabel"
      >
        Add Unit
      </h1>
      <button
        type="button"
        // class="btn-close"
        // data-bs-dismiss="modal"
        // aria-label="Close"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={handleCloseModalUnit}
        // onClick={()=>{setModal1Open1(false)}}
      ></button>
    </div>
    <div className="modal-body">
      <div>
          <p>New unit :</p>
          <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
      </div>
      {errors5.addunit && (
  <small style={{ color: 'red' }}>{errors5.addunit}</small> // Render the error message
)}
    </div>
    <div className="modal-footer">
      {/* <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Close
      </button> */}
      <button style={{ background:"#3d4465",color:"white" }} onClick={(e) => handleAddUnit(e)} type="button" class="btn  "
          
          data-bs-dismiss="modal">
        ADD
      </button>
    </div>
  </div>
</div>
</div>
                         //         <div
                         //    className="modal fade show z-4"
                         //    id="exampleModal"
                         //   //  id="showItem"
                         //    tabindex="2"
                         //    aria-labelledby="exampleModalLabel"
                           
                         //  >
                         //    <div className="modal-dialog">
                         //      <div className="modal-content">
                         //        <div
                         //          style={{ backgroundColor: "#3d4465" }}
                         //          className="modal-header"
                         //        >
                         //          <h1
                         //            className="modal-title text-white fw-bold fs-5"
                         //            id="exampleModalLabel"
                         //          >
                         //            Add Unit
                         //          </h1>
                         //          <button
                         //            type="button"
                         //            className="btn-close"
                         //            data-bs-dismiss="modal"
                         //            aria-label="Close"
                         //            onClick={handleCloseModalUnit}
                         //           //  onClick={()=>{setModal1Open(false)}}
                         //          ></button>
                         //        </div>
                         //        <div className="modal-body">
                         //          <div>
                         //              <p >New unit :</p>
                         //              <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                         //          </div>
                         //        </div>
                         //        <div className="modal-footer">
                         //          {/* <button
                         //            type="button"
                                   
                         //            class="btn-close"
                         //            data-bs-dismiss="modal"
                         //            aria-label="Close"
                         //            onClick={()=>{setModal1Open(false)}}
                         //          >
                         //            Close
                         //          </button> */}
                         //          <button style={{ background:"#3d4465" }} onClick={(e) => handleAddUnit(e)} type="button" className="btn btn-primary">
                         //            ADD
                         //          </button>
                         //        </div>
                         //      </div>
                         //    </div>
                         //  </div>
                          )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr className="p-0 m-0" />
                        <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                          <div className="row pb-0 mb-0" >
                            <div className="col text-center pb-2" style={{ borderBottom: !showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }}>
                              <a className="fw-bolder fs-4 " 
                               onClick={() => setShowStock(false)}
                               style={{ color: showStock ? 'white' : 'black' , cursor: 'pointer'
                                
                                }}
                              // onClick={() => setActiveTab1('pricing')}
                              // style={{ color: showStock ? 'black' : 'white' }}
      
                               ><b>PRICING</b></a>
                            </div>
                            <div className="col text-center pb-2"style={{ borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }} >
                              <a className="fw-bolder fs-4" 
                                              // onClick={() => setActiveTab1('stocks')}
                                              onClick={() => setShowStock(true)}
                                              style={{ color: showStock ? 'black' : 'white' , cursor: 'pointer' ,
                                                // borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none'
                                              }} 
                              ><b>STOCKS</b></a>
                            </div>
                          </div>
                        </div>
      
                        {showStock ? (
                                 <form action=""
                                //  onSubmit={handleSave} 
                                 className='stocks-field p-4'>
                                 <div className=' s-input'>
                                       <div className='w-50'>
                                         <p className='text-black fs-5'>Opening Stock</p>
                                         <input value={stockInHand}onChange={(e)=>setStockInHand(e.target.value)} type="text" className='form-control'  />
                                       </div>
                                       <div className='w-50'>
                                         <p className='text-black fs-5'>AT PRICE</p>
                                         <input value={atPrice} onChange={(e)=>setAtprice(e.target.value)} type="text" className='form-control'  />
                                       </div>
                                   </div>
                                   <div className='mt-3 s-input'>
                                   <div className='w-50'>
                                         <p className='text-black fs-5'>Date</p>
                                         <input onChange={(e)=>setDate(e.target.value)} type="date" defaultValue={new Date().toISOString().split("T")[0]}
                                           className='form-control'  />
                                   </div>
                                   <div className='w-50'>
                                         <p className='text-black fs-5'>MINIMUM STOCK TO MAINTAIN</p>
                                         <input value={minStockMaintain} onChange={(e)=>setminStockMaintain(e.target.value)} type="text" className='form-control'  />
                                       </div>
                                       
                                   </div>
                                   <button 
        className="btn btn-primary btn-sm mb-1 pt-0 pb-0 mt-3" 
        type="button" 
        // onClick={() => openBarcode()} 
        title="ADD BARCODE"
      >
        ADD <i className="fa fa-barcode"
         // aria-hidden="true"
         ></i>
      </button>
                                 <div className='d-flex flex-wrap gap-3 mt-2 justify-content-center'>
                               
      
                       
                                     {/* <button
                                     onClick={(e) => handleSaveNew(e)}
                                       className='btn btn-primary mt-3'
                                     >
                                       Save & New
                                     </button> */}
                       
                                     <button
                                     type='submit'
                                       className='btn  mt-3'
                                       
                                       onClick={(e) => handleSaveItem(e)}style={{background:" #3d4465",color:"white"}}
                                     >
                                      Save
                                     </button>
                                 </div>
                               </form>
                       
                        ) : (
                          <form action=""
                          // onSubmit={handleSave} 
                          className='pricing-field p-4'>
                          {/* Radio Buttons */}
                          <div className='d-flex flex-wrap gap-5'>
                          <div className="form-check d-flex align-items-center gap-5">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          checked={isTaxable}
          onChange={() => setIsTaxable(true)}
        />
        <label className="form-check-label text-black ml-4" htmlFor="flexRadioDefault1">
          Taxable
        </label>
      </div>
      
                            <div className="form-check d-flex align-items-center gap-5">
                              <input
                                className="form-check-input ml-3"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={!isTaxable}
                                onChange={() => setIsTaxable(false)}
                              />
                              <p className="form-check-label text-black ml-5" htmlFor="flexRadioDefault2">
                                Non Taxable
                              </p>
                            </div>
                          </div>
                
                          {/* Conditional Fields */}
                          {isTaxable ? (
                            <form 
                           //  onSubmit={handleSave} 
                            className='fields-taxable mt-5'>
                              <div className=' taxable '>
                                <div className='w-50'>
                                  <p className='text-black fs-5'>GST</p>
                                  <select value={gst} onChange={(e)=>setGst(e.target.value)} className='form-control non-t-i'>
                                    <option>GST@0%</option>
                                    <option>GST@3%</option>
                                    <option>GST@5%</option>
                                    <option>GST@12%</option>
                                    <option>GST@18%</option>
                                    <option>GST@28%</option>
                                  </select>
                                </div>
                                <div className='w-50'>
                                  <p className='text-black fs-5'>IGST</p>
                                  <select value={igst} onChange={(e)=>setIgst(e.target.value)} className='form-control non-t-i'>
                                    <option>IGST@0%</option>
                                    <option>IGST@3%</option>
                                    <option>IGST@5%</option>
                                    <option>IGST@12%</option>
                                    <option>IGST@18%</option>
                                    <option>IGST@28%</option>
                                  </select>
                                </div>
                              </div>
                
                              <form 
                             //  onSubmit={handleSave} 
                              className=' taxable mt-4'>
                                <div className='w-50'>
                                  <p className='text-black fs-5'>SALES PRICE</p>
                                  <input value={salesPrice} onChange={(e)=>setSalesPrice(e.target.value)} type="text" className='form-control non-t-i' />
                                </div>
                                <div className='w-50'>
                                  <p className='text-black fs-5'>PURCHASE PRICE</p>
                                  <input value={purchasePrice} onChange={(e)=>setPurchasePrice(e.target.value)} type="text" className='form-control non-t-i' />
                                </div>
                              </form>
                              <button
                                className='btn  mt-3'
                                onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                                // onClick={() => setActiveTab1('stocks')}
      
                              >
                                Next
                              </button>
                            </form>
                          ) : (
                            <form className='fields-non-taxable mt-5'>
                              <div className='d-flex non-t gap-3'>
                                <div className='w-50'>
                                  <p className='text-black fs-5 sale-p'>SALES PRICE</p>
                                  <input value={nonTaxableSalePrice}  onChange={(e)=>setnonTaxableSalePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Sales Price" />
                                </div>
                                <div className='w-50 '>
                                  <p className='text-black fs-5 sale-p'>PURCHASE PRICE</p>
                                  <input value={nonTaxablePurchasePrice}  onChange={(e)=>setnonTaxablePurchasePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Purchase Price" />
                                </div>
                              </div>
                              <button
                                className='btn  mt-3'
                                onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                                // onClick={() => setActiveTab1('stocks')}
                              >
                                Next
                              </button>
                            </form>
                          )}
                        </form>
                
                        )}
                      </div>
                     
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
           )
           }
   
      </div>
    </td>
    
                <td> <input className="form-control" value={row.hsn !== null ? row.hsn : row.sac}/></td>
                <td>
                  <input
                    type="number"
                    value={row.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    className="form-control"
                    min="1"
                  />
                </td>
                <td>
                  <input
                    // type="number"
                    value={row.rate}
                    readOnly
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                    className="form-control bg-white"
                    min="1"
                  />
                </td>
                <td>
              
                <input className="form-control" value= {row.tax}
                //  value={row.tax}  // The tax will be updated dynamically in the state
                //  onChange={(e) => handleTaxChange(e, index)} 
              />
                  
                </td>
                <td> <input
                    type="number"
                    value={row.discount}

                    onChange={(e) => handleDiscountChange(index, e.target.value)}
                    className="form-control"
                    min="1"
                  /></td>
                <td><input className="form-control" value={row.totalamount || "0.0"}/></td>
                <td> <i class="fas fa-xmark text-danger"onClick={() => handleRemoveRow(index)} ></i></td>
              </tr>
            ))}
          </tbody>
      </table>
    
                  
                    <button
                        className="btn mb-4"
                        onClick={addItem}
                        style={{
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            backgroundColor: '#3d4465',
                            color: 'white',
                        }}
                    >
                     <b> +</b> 
                    </button>
                    {/* <div className="modal fade custom-modal" id="exampleModal123" tabindex="-1" aria-labelledby="exampleModalLabel"
                    //  aria-hidden="true" 
                     >
      <div className="modal-dialog modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Item</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >&times;
            </button>
          </div>
          <div className="modal-body " >
               <div  className=' bg-white mt-4 '>
           
                <form className='input-field d-flex ' >
                        <div>
                            <p style={{color:'#3d4465'}} className=''>Item Type :</p>
                            <select  
                            // onChange={(e)=>setItemType(e.target.value)} 
                            name="item_type" id="" className='item-input form-control '  
                            //  value={ItemData.item_type}
                            value={itemType}
                            onChange={handleChangeItem}>
                                <option value=""  selected>Select Type</option>
                                <option value="Goods">Goods</option>
                                <option value="Service">Service</option>
                            </select>
                            
                        </div>
                        
                        <p style={{color:'#3d4465'}} className=''>Item Name :</p>
                        <div>
                       
             <input
              type="text"
              className='item-input form-control'
              name="item_name"
              value={ItemData.item_name}
              onChange={handleChangeItem}
            />
                        </div>
                        
                                <div>
                                    <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                    <input 
                      name="item_hsn"
                     value={ItemData.item_hsn}
                     onChange={handleChangeItem}
                      // value={nonTaxableSalePrice} onChange={(e)=>setnonTaxableSalePrice(e.target.value)} 
                      type="text" className='form-control non-t-i' placeholder="Item Hsn" />
                                  
                                </div>
                       
                        <div className=''>
                        <p style={{color:'#3d4465'}} className='unit'>Select Unit :</p>
                            <div className='unit d-flex flex-wrap gap-2'>
                            <select  
                            // onChange={(e)=>setUnit(e.target.value)}
                              name="unit_name" id="" className='item-input form-control'
                                value={ItemData.unit_name}
                                onChange={handleChangeItem}
                                >
                                    <option value="" disabled selected>Select Unit</option>
                                  
                                    
                                    {allUnit.map((itm)=>(
                                        <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                    ))
                                        }
                                    
                                </select>
                                <button type='button' data-bs-toggle="modal" data-bs-target="#exampleModal12" style={{height:'40px'}} className="btn btn-primary border  border-2 border-primary  text-white fw-bold">
                            
                              <i
                                className="mdi mdi-plus menu-icon mb-0"
                                style={{ color: "white" }}
                              ></i>
                          </button>
                        
                          <div style={{ backgroundColor: '#8290c6' }} className='p-s d-flex  flex-wrap gap-4 p-3 mt-5'>
                <div
                    className='pricing  m-auto'
                    onClick={() => setActiveTab1('pricing')}
                    style={{ cursor: 'pointer' }}
                >
                    <h1
                    className='fw-bold'
                    style={{
                        color: activeTab1 === 'pricing' ? '#181e3d' : '#fff', // Black when active, white otherwise
                    }}
                    >
                    PRICING
                    </h1>
                    {activeTab1 === 'pricing' && <hr style={{ borderColor: '#181e3d', borderWidth: '2px' }} />}
                </div>
       
                <div
                    className='stock  m-auto'
                    onClick={() => setActiveTab1('stocks')}
                    style={{ cursor: 'pointer' }}
                >
                    <h1
                    className='fw-bold'
                    style={{
                        color: activeTab1 === 'stocks' ? '#181e3d' : '#fff', 
                    }}
                    >
                    STOCKS
                    </h1>
                    {activeTab1 === 'stocks' && <hr style={{ borderColor: '#181e3d', borderWidth: '2px' }} />}
                </div>
            
          
            </div>
            {activeTab1 === 'pricing' ? (
            <form 
            // onSubmit={handleSave} 
            className='pricing-field p-4'>
             
              <div>
          <div className="d-flex flex-wrap gap-4">
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="taxable"
                id="flexRadioDefault1"
                checked={ItemData.taxable} 
                onChange={(e) =>
                  setItemData((prevData) => ({
                    ...prevData,
                    taxable: e.target.value !== "non_taxable",
                    non_taxable: e.target.value === "non_taxable",
                  }))
                }
                // onChange={handleChangeItem}
              />
              <p className="form-check-label text-black ml-3" htmlFor="flexRadioDefault1">
                Taxable
              </p>
            </div>
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="non_taxable"
                id="flexRadioDefault2"
                checked={ItemData.non_taxable} 
                onChange={(e) =>
                  setItemData((prevData) => ({
                    ...prevData,
                    taxable: e.target.value === "taxable",
                    non_taxable: e.target.value !== "taxable",
                  }))
                }
               
              />
              <p className="form-check-label text-black" htmlFor="flexRadioDefault2">
                Non Taxable
              </p>
            </div>
          </div>
    
         
          {ItemData.taxable ? (
            <form className="fields-taxable mt-5">
              <div className="d-flex taxable-fields gap-3">
                <div className="w-50">
                  <p className="text-black fs-5">SALES PRICE</p>
                  <input
                    name="sale_price"
                    value={ItemData.price}
                    // onChange={handleFieldChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter Sales Price"
                    onChange={handleChangeItem}
                  />
                </div>
                <div className="w-50">
                  <p className="text-black fs-5">PURCHASE PRICE</p>
                  <input
                    name="purchase"
                    value={ItemData.purchase_price}
                    // onChange={handleFieldChange}
                    onChange={handleChangeItem}
                    type="text"
                    className="form-control"
                    placeholder="Enter Purchase Price"
                  />
                </div>
              </div>
              <div className="d-flex taxable-fields gap-3 mt-3">
                <div className="w-50">
                  <p className="text-black fs-5">GST</p>
                  <input
                    name="gst"
                    value={ItemData.gst}
                    // onChange={handleFieldChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter GST"
                    onChange={handleChangeItem}
                  />
                </div>
                <div className="w-50">
                  <p className="text-black fs-5">IGST</p>
                  <input
                    name="igst"
                    value={ItemData.igst}
                    // onChange={handleFieldChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter IGST"
                    onChange={handleChangeItem}
                  />
                </div>
              </div>
              <button className="btn btn-primary mt-3" onClick={handleItemNextClick}>
                Next
              </button>
            </form>
          ) : (
            <form className="fields-non-taxable mt-5">
              <div className="d-flex non-taxable-fields gap-3">
                <div className="w-50">
                  <p className="text-black fs-5">SALES PRICE</p>
                  <input
                    name="sale_price"
                    value={ItemData.price}
                    // onChange={handleFieldChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter Sales Price"
                  />
                </div>
                <div className="w-50">
                  <p className="text-black fs-5">PURCHASE PRICE</p>
                  <input
                    name="purchase"
                    value={ItemData.purchase_price}
                    // onChange={handleFieldChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter Purchase Price"
                  />
                </div>
              </div>
              <button className="btn btn-primary mt-3" onClick={handleItemNextClick}>
                Next
              </button>
            </form>
          )}
        </div>
            
            </form>
          ) : (
            <form 
            // onSubmit={handleSave} 
            className='stocks-field p-4'>
              <div className=' s-input'>
                    <div className='w-50'>
                      <p className='text-black fs-5'>Opening Stock</p>
                      <input 
                      name="opening"
                      value={ItemData.opening}
                     onChange={handleChangeItem}
                      // value={nonTaxableSalePrice} onChange={(e)=>setnonTaxableSalePrice(e.target.value)} 
                      type="text" className='form-control non-t-i'  />
                      
                    </div>
                    <div className='w-50'>
                      <p className='text-black fs-5'>At Price</p>
                      <input 
                      name="at_price"
                      value={ItemData.at_price}
                     onChange={handleChangeItem}
                      // value={nonTaxableSalePrice} onChange={(e)=>setnonTaxableSalePrice(e.target.value)} 
                      type="text" className='form-control non-t-i'  />
                     
                    </div>
                </div>
                <div className='mt-3 s-input'>
                <div className='w-50'>
                      <p className='text-black fs-5'>Date</p>
                      <input 
                      name="date"
                     
                      onChange={handleChangeItem}
                       value={ItemData.date}
                      // onChange={(e)=>setDate(e.target.value)}
                       type="date"
                        className='form-control'  />
                        
              
                </div>
                <div className='w-50'>
                      <p className='text-black fs-5'>MINIMUM STOCK TO MAINTAIN</p>
                      <input 
                      name="min_stock"
                      value={ItemData.min_stock}
                     onChange={handleChangeItem}
                      // value={nonTaxableSalePrice} onChange={(e)=>setnonTaxableSalePrice(e.target.value)} 
                      type="text" className='form-control non-t-i'  />
                     
                    </div>
                </div>
              <div className='d-flex flex-wrap gap-3 mt-2'>
               
    
                  <button
                  // onClick={(e) => handleSaveNew(e)}
                    className='btn btn-primary mt-3'
                  >
                  Add
                  </button>
    
                  <button
                  type='submit'
                    className='btn btn-primary mt-3'
                    onClick={handleSubmitItem}
                  >
                   Save
                  </button>
              </div>
            </form>)}
                            </div>
                          
                          <div
                          class="modal fade"
                          id="exampleModal"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                    
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div
                                style={{ backgroundColor: "#3d4465" }}
                                class="modal-header"
                              >
                                <h1
                                  className="modal-title text-white fw-bold fs-5"
                                  id="exampleModalLabel"
                                >
                                  Add Unit
                                </h1>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleCloseModalUnit}
                                ></button>
                              </div>
                              <div class="modal-body">
                                <div>
                                    <p>New unit :</p>
                                    <input type="text" 
                                    // value={addunit} onChange={(e)=>setAddUnit(e.target.value)}
                                     className='form-control' />
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                //  onClick={(e) => handleAddUnit(e)}
                                  type="button" class="btn btn-primary">
                                  ADD
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                </form>
          </div>
          
        </div>
      </div>
    </div>
    </div> */}
                    
                
                </div>
                
    </form>
    <div className="col-md-6 p-3 m-2" style={{ backgroundColor: "#3d4465", color: "white" }}>
    <div className="row mt-4 ">   
       <div className="table-responsive "> 
    <table className="table table-borderless ">
                          <tbody>
                            <tr>
                              <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "white"}}>Sub Total</td>
                           
                            <input
                              type="text"
                              className="form-control"
                              id="SubTotal"
                              value={invoiceData.subtotal || 0} readOnly
                              style={{ borderRadius: "8px",marginleft: "12px",background:"white"}}
                            />
                            
                            </tr>
                            {/* <tr> */}
                              {/* <td className="text-light"  style={{ border: 'none', backgroundColor: "#3d4465", color: "white"}}>CGST</td>
                              
                            <input
                              type="text"
                              className="form-control"
                              id="cgst"  value={invoiceData.cgst || ""}
                              readOnly
                              style={{ borderRadius: "8px",marginleft: "12px",padding: "15px" }}
                            />
                            */}
                            {/* </tr> */}
                            {/* <tr> */}
                              {/* <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "white" }}>SGST</td>
                             
                            <input
                              type="text"
                              className="form-control"
                              id="sgst"  value={invoiceData.sgst || ""}

                              readOnly
                              style={{ borderRadius: "8px",marginleft: "12px",padding: "15px" }}
                            /> */}
                           
                            {/* </tr> */}
                            {invoiceData.state_of_supply === "State" && ( 
  <>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        CGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="cgst"
          // value={cgst} // Display CGST value
          readOnly
          style={{
            borderRadius: "8px",
            background:"white" // Fixed typo (marginleft -> marginLeft)
            // padding: "15px"
          }}
          value={invoiceData.cgst ||0}
        />
      {/* </td> */}
    </tr>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        SGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="sgst"
          value={invoiceData.sgst || 0}
          // value={sgst} // Display SGST value
          readOnly
          style={{
            borderRadius: "8px",background:"white"
            // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
            // padding: "15px"
          }}
        />
      {/* </td> */}
    </tr>
  </>
)}

{/* Conditionally render IGST if stateOfSupply is "otherState" */}

  {invoiceData.state_of_supply === "otherState" && (
    <tr>
    <td 
      className="text-light" 
      style={{ border: "none", backgroundColor: "#3d4465", color: "black" }}
    >
      IGST
    </td>
    {/* <td> */}
      <input
        type="text"
        className="form-control"
        id="igst"
        value={invoiceData.total_taxamount || 0}
        // value={taxAmount} // Display IGST value
        readOnly
        style={{
          borderRadius: "8px",background:"white"
          // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
          // padding: "15px"
        }}
      />
    {/* </td> */}
    </tr>
)}
                            <tr>
                              <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "white"}}>Tax Amount</td>
                              <input
                              type="text"
                              className="form-control"
                              id="sgst"  value={invoiceData.total_taxamount || 0}
                              readOnly
                              style={{ borderRadius: "8px",marginleft: "12px",background:"white" }}
                            />
                            </tr>
                            <tr>
                              <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "white"}}>Adjustment</td>
                              <input
                              type="text"
                              value={invoiceData.adjustment || 0}

                              className="form-control"
                              id="adjustment"  onChange={handleAdjustmentChange}
                              style={{ borderRadius: "8px",marginleft: "12px",background:"white" }}
                            />
                            </tr>
                            <tr>
                              
                                <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "white" }}>Grand Total</td>
                              
                              <input
                              type="text"
                              className="form-control"
                              id="gtotal" value={invoiceData.grandtotal || 0}

                              readOnly
                              style={{ borderRadius: "8px",marginleft: "12px",background:"white" }}
                            />
                            </tr>
                            <tr>
                             
                                <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "white"}}>Description</td>
                              
                              <input
                              type="text"
                              className="form-control"
                           value={invoiceData.description || ""}  onChange={handleDecriptionChange}
                              style={{ borderRadius: "8px",marginleft: "12px",background:"white" }}
                            />
                            </tr>
                          </tbody>
                        </table></div>
                      </div>
                      </div>
    <div>
               
                <div className="d-flex flex-wrap justify-content-start mt-4 p-2" style={{ gap: "10px" }}>
      <button
        className="btn "
        // onClick={handlesave()}
        onClick={(e) => handleSave(e)}
        style={{background:" #3d4465",color:"lightgray"}}
      >
        Save
      </button>
      {/* <button
        className="btn " style={{background:" #3d4465",color:"lightgray"}}
        
        onClick={(e) => handleSave(e)}
      >
        Save and Next
      </button> */}
    </div>
              
            </div> 
                  </div>
            {/* ) */}
        </div>
      
    </div>
  )
}
