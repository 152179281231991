import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../../functions/config";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import './Invoice.css'
// import $ from 'jquery';


function Invoice() {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("COMPANY NAME");
  const [activeTab, setActiveTab] = useState('gst');
  const [activeTab1, setActiveTab1] = useState('pricing');
  
  const [addunit,setAddUnit] = useState('')
  const [allUnit,setAllUnit] = useState([])
  const [count,setCount] = useState(0)
  const[unit,setUnit] = useState('')
  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");
  const user_id = ID
  const [loginName, setLoginName] = useState("");
  const [loginImage, setLoginImage] = useState("");
  const [row, setRow] = useState([]);
  // const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ id: '', item_name: '' }); // State for the new item
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showModalItem, setShowModalItem] = useState(false); 
  const [showModalItem1, setShowModalItem1] = useState(false);
  
  // const [loginImage, setLoginImage] = useState("");
  // const PartyForm = () => {
    const [isOn, setIsOn] = useState(false); // State to toggle form
    const [parties, setParties] = useState([]); // State to hold party names
    const [selectedParty, setSelectedParty] = useState(""); // State for selected party
    // const [parties, setParties] = useState([]);
    // const [selectedParty, setSelectedParty] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    // Fetch party names when isOn becomes true
  //   useEffect(() => {
  //     axios.get("api/parties/")  // Your API endpoint
  //         .then(response => {
  //             setParties(response.data);  // Set the parties data to state
  //         })
  //         .catch(error => {
  //             console.error("Error fetching party names:", error);
  //         });
  // }, []);  // Dependency array for fetching data once when component mounts
  
  // }
  // const ItemTable = () => {
    const [ItemData, setItemData] = useState({
      item_name: "",
      item_hsn: "",
      item_type: "",
  unit_name:"",
      opening: "",
      at_price: "",
      date: "",
      min_stock: "",
      
      taxable: "",
      // non_taxable: false,
      sale_price:"",
      purchase: "", 
      igst:"",
      gst:""  // You need to pass the staff ID
    });
    const handleChangeItem = (e) => {
      const { name, value, type, checked } = e.target;
      
      setItemData({
        ...ItemData,
        [name]: type === "checkbox" ? checked : value,
      });
      console.log(ItemData)
    };
    const handleSubmitItem = async (e) => {
      e.preventDefault();
      const user = Cookies.get("role");
      var is_company = false;
      if (user === "Company") {
        is_company = true;
      }
      const ID = Cookies.get("user_id");
      const user_id = ID
      try {
        const updatedItemData = {
          ...ItemData,
          user_id: user_id,
          role: user
        };
        // console.log("updateddata",updatedItemData)
        // const response = await axios.post("http://127.0.0.1:8000/api/item/", updatedItemData); // Update with your endpoint
        const response = await axios.post(`${config.base_url}api/item/`, updatedItemData);
        if (response.status === 201) {
          alert("Item added successfully!");
          setItemData({
            item_name: "",
      item_hsn: "",
      item_type: "",
    unit_name:"",
      opening: "",
      at_price: "",
      date: "",
      min_stock: "",
      igst:"",gst:"",
      taxable:"",
      gst:"",
      igst:"",
      sale_price:"",
      purchase_price: "",
      unit_name:"",
          });
        }
      } catch (error) {
        console.error("Error adding item details:", error);
        alert("Failed to add item details. Please try again.");
      }
    };
    const [formData, setFormData] = useState({
      party_name: "",
      phone_number: "",
      gstin: "",
      gst_type: "",
      billing_address: "",
      state: "",
      email: "",
      opening_balance: "",
      credit_limit: "",
      to_pay: false,
      to_receive: false,
      date: "",
      company: "", // You need to pass the company ID
      staff: "", 
      // balanceType: '',  // You need to pass the staff ID
    });
    const [errors1, setErrors1] = useState({
      email: "",
      // other field errors
    });
    const [errors2, setErrors2] = useState({
      partyPhNo: "",
      // other field errors
    });
    const handleChangeparty = (e) => {
      const { name, value, type, checked } = e.target;
      const emailRegex1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^[0-9]{10}$/;
      // Handle email validation
      if (name === "partyPhNo") {
        if (!phoneRegex.test(value)) {
          setErrors2({
            ...errors2,
            partyPhNo: "Please enter a valid 10-digit phone number.",
          });
        } else {
          setErrors2({
            ...errors2,
            partyPhNo: "", // Clear the error message if valid
          });
        }
      }
      if (name === "email") {
        if (!emailRegex1.test(value)) {
          setErrors1({
            ...errors1,
            email: "Please enter a valid email address.",
          });
        } else {
          setErrors1({
            ...errors1,
            email: "", // Clear the error message if valid
          });
        }
      }
      // setFormData({
      //   ...formData,
      //   [name]: type === "checkbox" ? checked : value,
      // });
      
      if (type === "radio") {
        console.log("to_pay",formData.to_pay)
        console.log("name",name)
        // Check if it's the "to_pay" or "to_receive" radio button and set the values accordingly
        if (name === "to_pay" ) {
          formData.to_pay= true, // If clicked 'to_pay', set it to true
          formData.to_receive=false 
          // Update both fields to ensure only one can be true at a time
          setFormData({
            ...formData,
            to_pay: name === "to_pay" ? checked : true, // If clicked 'to_pay', set it to true
            to_receive: name === "to_receive" ? checked : false, // If clicked 'to_receive', set it to true
          });
        }
        if (name === "to_receive" ) {
          // Update both fields to ensure only one can be true at a time
          
            formData.to_pay= false, // If clicked 'to_pay', set it to true
            formData.to_receive=true // If clicked 'to_receive', set it to true
          // });
          setFormData({
            ...formData,
            to_pay: name === "to_pay" ? checked : false, // If clicked 'to_pay', set it to true
            to_receive: name === "to_receive" ? checked : true, // If clicked 'to_receive', set it to true
          });
        }
      } else {
        // For other inputs, update the state as usual
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      }
      console.log(formData)
    };
  
    const handleSubmitparty = async (e) => {
      e.preventDefault();
      const user = Cookies.get("role");
      var is_company = false;
      if (user === "Company") {
        is_company = true;
      }
      const ID = Cookies.get("user_id");
      const user_id = ID
      let missingFields = [];

      // Check for missing required fields
      if (!formData.partyName) missingFields.push("Party Name");
      if (!formData.partyPhNo) missingFields.push("Phone Number");
      if (!formData.gstin) missingFields.push("GSTIN");
      if (!formData.gstType) missingFields.push("GST Type");
      if (!formData.bAddress) missingFields.push("Billing Address");
      if (!formData.splyState) missingFields.push("State");
      if (!formData.email) missingFields.push("Email");
      if (!formData.openbalance1) missingFields.push("Opening Balance");
      if (!formData.crdLmt) missingFields.push("Credit Limit");
      if (!formData.partyDate) missingFields.push("Date");
    
      // If any required fields are missing, show an alert with the missing fields
      if (missingFields.length > 0) {
        alert("Please fill in the following required fields: " + missingFields.join(", "));
        return;
      }
      if (!formData.to_pay && !formData.to_receive) {
        alert("Please select either 'To Pay' or 'To Receive'.");
        return;
      }
      if (
        errors.gstin !== "" ||
        errors1.email !== "" ||
        errors2.partyPhNo !== "" ||
        errors4.party !== ""
    ) {
        alert("Please resolve all errors before saving.");
        return;
    }
      try {
        const updatedFormData = {
          ...formData,
          user_id: user_id,
          role: user
        };
        if (!formData.gstin) {
          setErrors((prev) => ({
            ...prev,
            gstin: 'GSTIN is required',
          }));
          return;
        }
        console.log("user id is ",updatedFormData)
       
        
        if (!gstinRegex.test(formData.gstin)) {
          setErrors((prev) => ({
            ...prev,
            gstin: 'Invalid GSTIN format',
          }));
          return;
        }
    
        const response = await axios.post(`${config.base_url}api/parties_adding/`, updatedFormData); 
        console.log("user id is ",user_id)// Update with your endpoint
        if (response.status === 201) {
          alert("Party details added successfully!");
          // window.location.reload(); 
          fetchParty()
          closeModal()
          setIsCredit(false)
          setFormData({
            party_name: "",
            phone_number: "",
            gstin: "",
            gst_type: "",
            billing_address: "",
            state: "",
            email: "",
            opening_balance: "",
            credit_limit: "",
            to_pay: false,
            to_receive: false,
            date: "",
            company: "",
            staff: "",
          });
        }
      } catch (error) {
        console.error("Error adding party details:", error);
        alert("Failed to add party details. Please try again.");
      }
    };
    const [invoiceNo, setInvoiceNo] = useState("");
    const handleNextClick = () => {
      setActiveTab('credit'); // Update the activeTab state to 'credit'
    };
    const handleItemNextClick = () => {
      setActiveTab1('stocks'); // Update the activeTab state to 'credit'
    };
    const handlePreviousClick = () => {
      setActiveTab('gst'); // Update the activeTab state to 'credit'
    };
    const[gst1,setGST] = useState('')
    
    // const handleGSTChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormData({ ...formData, [name]: value });
  
    //   if (name === "gstin") {
    //     if (isValidGSTIN(value)) {
    //       setErrors({ ...errors, gstin: "" });
    //     } else {
    //       setErrors({
    //         ...errors,
    //         gstin: "Invalid GSTIN format. Example: 29ABCDE1234F2Z5",
    //       });
    //     }
    //   }
    // };
    const handleGSTChange = async (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  
      if (name === "gstin") {
          // Validate GSTIN format
          if (isValidGSTIN(value)) {
              setErrors({ ...errors, gstin: "" });
              console.log("Valid GSTIN format entered");
              const user = Cookies.get("role");
              const user_id = Cookies.get("user_id");
              // Check if GSTIN already exists in the database
              try {
                  const response = await axios.get(`${config.base_url}api/check_gstin/`, {
                      params: { gstin: value ,'user_id':user_id,"role":user}, // Pass GSTIN as query parameters
                  });
  
                  if (response.data.exists) {
                      setErrors((prevErrors) => ({
                          ...prevErrors,
                          gstin: "GSTIN already exists.",
                      }));
                      setFormData((prev) => ({
                        ...prev,
                        // partyName: value, // Correctly update state
                      }));
                    
                      const user = Cookies.get("role");
                      const user_id = Cookies.get("user_id");
                    
                      console.log("Checking party name:", value);
                    
                      try {
                        const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                          params: {
                            // party_name: value,
                            gstin: value , 
                            party_name:formData.partyName,// Use value directly instead of formData.party_name
                            user_id: user_id,
                            role: user,
                          },
                        });
                    
                        console.log("API Response:", response.data);
                    
                        if (response.data.party_exists) {
                          setErrors4((prev) => ({
                            ...prev,
                            party: "Party name already exists", // Show validation error
                          }));
                        } else {
                          setErrors4((prev) => ({
                            ...prev,
                            party: "", // Clear error when valid
                          }));
                        }
                      } catch (error) {
                        console.error("Validation error:", error.response ? error.response.data : error.message);
                        setErrors4((prev) => ({
                          ...prev,
                          party: "", // Show generic error message
                        }));
                      }
                  } 
                  else {
                      setErrors((prevErrors) => ({ ...prevErrors, gstin: "" }));
                      setErrors4((prev) => ({
                        ...prev,
                        party: "", // Clear error when valid
                      }));
                  }
              } catch (error) {
                  console.error("Error checking GSTIN existence:", error);
                  setErrors((prevErrors) => ({
                      ...prevErrors,
                      gstin: "Unable to check GSTIN availability. Please try again.",
                  }));
                  setErrors4((prev) => ({
                    ...prev,
                    party: "", // Clear error when valid
                  }));
              }
          } else {
              setErrors({
                  ...errors,
                  gstin: "Invalid GSTIN format. Example: 29ABCDE1234F2Z5",
              });
              setErrors4((prev) => ({
                ...prev,
                party: "", // Clear error when valid
              }));
          }
      }
  };
  const handleEmailChange = async (value) => {
    // Update formData with the new value
    setFormData((prev) => ({
      ...prev,
      email: value, // Correctly update state
    }));
    console.log("email",value)
    // if (name === "email") {
    //   console.log(1)
        // Validate GSTIN format
        if (!emailRegex.test(value)) {
          // alert("Invalid email format");
          setErrors1((prev) => ({
            ...prev,
            email: "Invalid email format", // Show validation error
          }));
          setErrors4((prev) => ({
            ...prev,
            party: "", // Clear error when valid
          }));
          return; // Stop further processing if email is invalid
        }else {
          try {
            const response = await axios.get(`${config.base_url}api/validate_email/`, {
              params: {
                email: value, // Use value directly instead of formData.party_name
                user_id: user_id,
                role: user,
              },
            });
        
            console.log("API Response:", response.data);
        
            if (response.data.email_exists) {
              setErrors1((prev) => ({
                ...prev,
                email: "email  already exists", // Show validation error
              }));
              const user = Cookies.get("role");
                      const user_id = Cookies.get("user_id");
                    
                      console.log("Checking party name:", value);
                    
                      try {
                        const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                          params: {
                            // party_name: value,
                            // gstin: value , 
                            party_name:formData.partyName,// Use value directly instead of formData.party_name
                            user_id: user_id,
                            role: user,
                            email: value,
                          },
                        });
                    
                        console.log("API Response:", response.data);
                    
                        if (response.data.party_exists) {
                          setErrors4((prev) => ({
                            ...prev,
                            party: "Party name already exists", // Show validation error
                          }));
                        } else {
                          setErrors4((prev) => ({
                            ...prev,
                            party: "", // Clear error when valid
                          }));
                        }
                      } catch (error) {
                        console.error("Validation error:", error.response ? error.response.data : error.message);
                        setErrors4((prev) => ({
                          ...prev,
                          party: "", // Clear error when valid
                        }));
                      }
                  
            } else {
              setErrors1((prev) => ({
                ...prev,
                email: "", // Clear error when valid
              }));
              setErrors4((prev) => ({
                ...prev,
                party: "", // Clear error when valid
              }));
            }
          } catch (error) {
            console.error("Validation error:", error.response ? error.response.data : error.message);
            setErrors1((prev) => ({
              ...prev,
              email: "", // Show generic error message
            }));
            setErrors4((prev) => ({
              ...prev,
              party: "", // Clear error when valid
            }));
          }
        }
    // }
};
const handlePhoneNoChange = async (value) => {
  // Update formData with the new value
  setFormData((prev) => ({
    ...prev,
    partyPhNo: value, // Correctly update state
  }));
  console.log("email",value)
  // if (name === "email") {
  //   console.log(1)
      // Validate GSTIN format
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(value)) {
        // alert("Invalid email format");
        setErrors2({
          ...errors2,
          partyPhNo: "Please enter a valid 10-digit phone number.",
        });
        setErrors4((prev) => ({
          ...prev,
          party: "", // Clear error when valid
        }));
        return; // Stop further processing if email is invalid
      }else {
        try {
          const response = await axios.get(`${config.base_url}api/validate_phone/`, {
            params: {
              partyPhNo: value, // Use value directly instead of formData.party_name
              user_id: user_id,
              role: user,
            },
          });
      
          console.log("API Response:", response.data);
      
          if (response.data.phone_exists) {
            setErrors2((prev) => ({
              ...prev,
              partyPhNo: "Phone number  already exists", // Show validation error
            }));
            const user = Cookies.get("role");
            const user_id = Cookies.get("user_id");
          
            console.log("Checking party name:", value);
          
            try {
              const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
                params: {
                  // party_name: value,
                  partyPhNo: value, 
                  party_name:formData.partyName,// Use value directly instead of formData.party_name
                  user_id: user_id,
                  role: user,
                },
              });
          
              console.log("API Response:", response.data);
               
              if (response.data.party_exists) {
                console.log("party exists")
                setErrors4((prev) => ({
                  ...prev,
                  party: "Party name already exists", // Show validation error
                }));
              }
              else {
                setErrors4((prev) => ({
                  ...prev,
                  party: "", // Clear error when valid
                }));
              }
            } catch (error) {
              console.error("Validation error:", error.response ? error.response.data : error.message);
              setErrors4((prev) => ({
              ...prev,
              party: "", // Clear error when valid
            }));
            }
          } else {
            setErrors2((prev) => ({
              ...prev,
              partyPhNo: "", // Clear error when valid
            }));
            setErrors4((prev) => ({
              ...prev,
              party: "", // Clear error when valid
            }));
          }
        } catch (error) {
          console.error("Validation error:", error.response ? error.response.data : error.message);
          setErrors2((prev) => ({
            ...prev,
            partyPhNo: "", // Show generic error message
          }));
        }
       
      }
  // }
}; 
    const isValidGSTIN = (gstin) => {
      const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
      return gstinRegex.test(gstin);
    };
    
    useEffect(() => {
      const fetchLastInvoiceNo = async () => {
        try {
          //  `${config.base_url}/api/get_last_invoice/`
          const user = Cookies.get("role");
          const user_id = Cookies.get("user_id");
          // const response = await fetch( `${config.base_url}api/get_last_invoice/`);
          const response = await axios.get(
            `${config.base_url}api/get_last_invoice/`,
            {
              headers: {
                "Content-Type": "application/json",
              }
              , params:{
                'user_id':user_id,"role":user
              }
            }
          );
          console.log(response.data);
          console.log("++",response.data.status);
          if (response.data.status === 200) {
            console.log("+++",response.data.data)
            // const data = await response.json();
            
            const lastInvoiceNo = response.data.data || 0; // Default to 0 if no invoices
            setInvoiceNo(lastInvoiceNo + 1); // Update invoice number
          } else {
            console.error("Failed to fetch last invoice number");
            setInvoiceNo(1); // Default to 1 if the call fails
          }
        } catch (error) {
          console.error("Error fetching last invoice number:", error);
          setInvoiceNo(1); // Fallback value
        }
      };
  
      fetchLastInvoiceNo();
    }, []); 


    const [items, setItems] = useState([]);
 
    // useEffect(() => {
    //     // Fetch all items on component mount
    //     axios.get(`${config.base_url}/api/items/`)
    //         .then(response => setItems(response.data))
    //         .catch(error => console.error(error));
    // }, []);

    const handleItemChange = (index, itemId) => {
      const stateOfSupply = document.getElementById("stateOfSupply").value;
      // const isDuplicate = rows.some(row => row.id === itemId);
      // if (isDuplicate) {
      //   alert("This item has already been added.");
      //   return; // Exit the function if duplicate
      // }
     
      axios.get(`${config.base_url}api/items/${itemId}/`)
      // axios.get(`http://localhost:8000/api/items/${itemId}/`)
        .then(response => {
          const selectedItem = response.data;
          const isDuplicate = rows.some(
            (row, i) => row.item_hsn === selectedItem.item_hsn && i !== index
          );
    
          if (isDuplicate) {
            alert("Duplicate item detected: This item's HSN code has already been added.");
            return; // Exit the function if duplicate is found
          }
          const extractTaxValue = (taxString) => {
            return parseFloat(taxString.replace(/[^0-9.]/g, '')); // Remove non-numeric characters
          };
          const updatedRows = [...rows];
          updatedRows[index] = {
            ...updatedRows[index],
            id: itemId, // Set the id field
            item_hsn: selectedItem.item_hsn,
            item_sac: selectedItem.item_sac,
            // tax: stateOfSupply === "State" 
            //           ? extractTaxValue(selectedItem.item_gst)  // Extract integer from GST
            //           : extractTaxValue(selectedItem.item_igst),
                      // ? selectedItem.item_gst.replace('%', '') 
                      // : selectedItem.item_igst.replace('%', ''),
                      
            // tax : stateOfSupply === "State"
            //             ? `GST${extractTaxValue(selectedItem.item_gst|| "0")}[${extractTaxValue(selectedItem.item_gst|| "0")}%]`  // Extract integer from GST
            //             : `IGST${extractTaxValue(selectedItem.item_igst|| "0")}[${extractTaxValue(selectedItem.item_igst|| "0")}%]`,
            tax: stateOfSupply === "State"
  ? `GST${extractTaxValue(selectedItem.item_gst || "0") || 0}[${extractTaxValue(selectedItem.item_gst || "0") || 0}%]`
  : `IGST${extractTaxValue(selectedItem.item_igst || "0") || 0}[${extractTaxValue(selectedItem.item_igst || "0") || 0}%]`,

            // // tax: stateOfSupply === "State"
            //           ? `GST ${tax_value}%`
            //           : `IGST ${tax_value}%`,
                
            gst:selectedItem.item_gst,
            igst:selectedItem.item_igst,
                      // ? extractTaxValue(selectedItem.item_igst)  // Extract integer from GST
                      // : extractTaxValue(selectedItem.item_gst),
            // igst: stateOfSupply=== "State" 
            //  ? extractTaxValue(selectedItem.item_igst):extractTaxValue(selectedItem.item_gst),
                      
            item_sale_price: selectedItem.item_sale_price,
            qty: 0,
            discount: 0,
            total: 0,
            item_name:selectedItem.item_name
          };
          console.log(updatedRows)
          console.log("sac is",selectedItem.item_sac)
          setRows(updatedRows);
        })
        .catch(error => {
          console.error("Error fetching item details:", error);
        });
    };
    const [showStock, setShowStock] = useState(false);
    useEffect(() => {
      async function fetchdata() {
        try {
          const user = Cookies.get("role");
          const user_id = Cookies.get("user_id");
          console.log("---",user)
          const response = await axios.get(
            `${config.base_url}all_unit1`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params:{
                'user_id':user_id,"role":user
              }
            }
          );
          if (response.data.status === 200) {
            console.log(response);
            setAllUnit(response.data.data);
          } else {
            console.log(response);
          }
        } catch (error) {
          console.log(error);
        }
      }
      fetchdata();
    }, [config.base_url,count]);
    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };
  // }
  const [isOpen, setIsOpen] = useState(false);

  // Open modal
  const openModal = () => setIsOpen(true);

  // Close modal
  const closeModal = () => {
    setShowModal(false); 
    setIsCredit(false) // Close modal by setting state to false
    setFormData({
      party_name: "",
      phone_number: "",
      gstin: "",
      gst_type: "",
      billing_address: "",
      state: "",
      email: "",
      opening_balance: "",
      credit_limit: "",
      to_pay: false,
      to_receive: false,
      date: "",
      company: "",
      staff: "",
    });
    setErrors({
      gstin: ""
    });
    setErrors1({
      email: ""
    });
    setErrors2({
     partyPhNo: ""
    });
    setErrors4({
      party: ""
     });
  };
  const closeModal1 = () => {
    setShowModalItem1(false); // Close modal by setting state to false
  };
  // const closeModal2 = () => {
  //   setShowModalUnit(false); // Close modal by setting state to false
  // };
  const [rows, setRows] = useState([]); // Initialize as an empty array

  
  // const [rows, setRows] = useState([{ item_hsn: '', item_sale_price: '', tax: '', discount: '', total: 0 }]);
  // const [rows, setRows] = useState([
  //   { id: '', item_hsn: '', item_sale_price: '', item_tax: '', qty: 0, discount: 0, total: 0 }
  // ]);
  
  const [subTotal, setSubTotal] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [description, setDescription] = useState('');
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedState, setSelectedState] = useState("");  // Declare selectedState as a state variable
  const [isModal1Open, setModal1Open] = useState(false);
  const [isModal1Open1, setModal1Open1] = useState(false);
  // const [isModal2Open, setModal2Open] = useState(false);
  // const handleStateOfSupplyChange = (e) => {
  //   const selectedState = e.target.value; // Get the selected state from the input
  //   setSelectedState(selectedState); // Update selectedState
  
  //   // Now, you can use selectedState to update rows or other actions
  //   const updatedRows = rows.map((row) => ({
  //     ...row,
  //     item_tax: selectedState === "state" ? row.item_gst : row.item_igst,
  //   }));
  //   setRows(updatedRows); // Update rows with the new tax values
  // };
  
  // Calculate totals when rows change
  // /04-01-2024/ 
//   const [stateOfSupply, setStateOfSupply] = useState("State"); // Default to "state"
// const handleStateOfSupplyChange = (e) => {
//       // const stateType = e.target.value; // Get the selected state type
//       setStateOfSupply(e.target.value);   
//       console.log("state value",e.target.value)  // Update stateOfSupply state
//       updateTaxValues(e.target.value);      // Update tax values dynamically
//     };
// const updateTaxValues = (stateType) => {
//   const updatedRows = rows.map((row) => {
//     // Check if gst or igst exists and is a valid number before replacing the percentage sign
//     console.log()
//     const taxValue = stateType === "State"
//       ? (row.gst && !isNaN(row.gst.replace('%', '')) ? row.gst.replace('%', '') : '0')
//       : (row.igst && !isNaN(row.igst.replace('%', '')) ? row.igst.replace('%', '') : '0');

//     // Format tax as GST or IGST
//     const taxLabel = stateType === "State"
//       ? `GST ${taxValue}%`
//       : `IGST ${taxValue}%`;

//     return {
//       ...row,
//       tax: taxLabel, // Update the tax label in the row
//     };
//   });

// }
// const [stateOfSupply, setStateOfSupply] = useState("State"); // Default to "State"
const [stateOfSupply, setStateOfSupply] = useState("State");
const handleStateOfSupplyChange = (e) => {
  const stateType = e.target.value; // Get the selected state type
  setStateOfSupply(stateType);  // Update stateOfSupply state
  console.log("state value", stateType);
  updateTaxValues(stateType);  // Update tax values dynamically
};

// Update tax values based on state type (State or OtherState)
const updateTaxValues = (stateType) => {
  const updatedRows = rows.map((row) => {
    // const taxValue = stateType === "State"
    //   ? (row.gst && !isNaN(row.gst.replace('%', '')) ? row.gst.replace('%', '') : '0')
    //   : (row.igst && !isNaN(row.igst.replace('%', '')) ? row.igst.replace('%', '') : '0');
    // const taxValue = stateType === "State"
    //     ? row.item__item_gst.replace(/\D/g, "") 
    //     : row.item__item_igst.replace(/\D/g, "")  ;
    //   console.log("tax value is",taxValue)
   
    const taxValue = stateType === "State"
    ? (row.gst ? row.gst.replace(/\D/g, "") : "") 
    : (row.igst ? row.igst.replace(/\D/g, "") : "");

console.log("tax value is", taxValue);

      // const taxLabel = stateType === "State"
      //   ? `GST${taxValue}[${taxValue}%]`
      //   : `IGST${taxValue}[${taxValue}%]`;
    // const taxLabel = stateType === "State"
    //   ? `GST${taxValue}[${taxValue}%]`
    //   : `IGST${taxValue}[${taxValue}%]`;
    const taxLabel = stateType === "State"
    ? `GST${taxValue || 0}[${taxValue || 0}%]`
    : `IGST${taxValue || 0}[${taxValue || 0}%]`;
  
    return {
      ...row,
      tax: taxLabel,  // Update the tax label in the row
    };
    
  });
  
  setRows(updatedRows);
  console.log("rows",updatedRows)
   // Set updated rows state if needed
   let totalSubTotal = 0;
   let totalTax = 0;

   // Calculate SubTotal and total tax
   rows.forEach((row) => {
    console.log("hjkas",row.item_sale_price)
     const itemTotal = (row.item_sale_price * row.qty) - row.discount; // Calculate item total
     totalSubTotal += itemTotal;
     // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, ''));
     // const taxAmountForRow = (itemTotal * row.tax) / 100;
     // const taxValue = parseFloat(row.tax.match(/(\d+)(?=%)/)[0]);
//       const taxMatch = row.tax.match(/(\d+)(?=%)/); // Match digits before %
// const taxValue = taxMatch ? parseFloat(taxMatch[0]) : 0;
// // Calculate tax amount
// print("//taxmatch",taxMatch)
     const text = row.tax;

     const match = text.match(/([a-zA-Z]+)(\d+)\[/); // Match letters followed by digits ending with '['
     const result = match ? parseInt(match[2], 10) : 0; // Extract the digits (group 2)

     console.log("bnbnl",result);
// const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, '')) || 0;
     const taxAmountForRow = (itemTotal * result) / 100;
     totalTax += taxAmountForRow;
   });

   // Calculate CGST and SGST (both are half of total tax)
   const totalCgst = totalTax / 2;
   const totalSgst = totalTax / 2;

   // Calculate Grand Total
   const totalAdjustment = parseFloat(adjustment) || 0;
   const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;

   // Set state with calculated values
   setSubTotal(totalSubTotal);
   setCgst(totalCgst);
   setSgst(totalSgst);
   setTaxAmount(totalTax);
   setGrandTotal(totalGrandTotal);
};
  useEffect(() => {
    
    calculateTotals();
  }, [rows]);
  const calculateTotals = () => {
    let totalSubTotal = 0;
    let totalTax = 0;

    // Calculate SubTotal and total tax
    rows.forEach((row) => {
      const itemTotal = (row.item_sale_price * row.qty) - row.discount; // Calculate item total
      totalSubTotal += itemTotal;
      // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, ''));
      // const taxAmountForRow = (itemTotal * row.tax) / 100;
      // const taxValue = parseFloat(row.tax.match(/(\d+)(?=%)/)[0]);
//       const taxMatch = row.tax.match(/(\d+)(?=%)/); // Match digits before %
// const taxValue = taxMatch ? parseFloat(taxMatch[0]) : 0;
// // Calculate tax amount
// print("//taxmatch",taxMatch)
      const text = row.tax;

      const match = text.match(/([a-zA-Z]+)(\d+)\[/); // Match letters followed by digits ending with '['
      const result = match ? parseInt(match[2], 10) : 0; // Extract the digits (group 2)

      console.log("bnbnl",result);
// const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, '')) || 0;
      const taxAmountForRow = (itemTotal * result) / 100;
      totalTax += taxAmountForRow;
    });

    // Calculate CGST and SGST (both are half of total tax)
    const totalCgst = totalTax / 2;
    const totalSgst = totalTax / 2;

    // Calculate Grand Total
    const totalAdjustment = parseFloat(adjustment) || 0;
    const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;

    // Set state with calculated values
    setSubTotal(totalSubTotal);
    setCgst(totalCgst);
    setSgst(totalSgst);
    setTaxAmount(totalTax);
    setGrandTotal(totalGrandTotal);
  };
  const handleAdjustmentChange = (event) => {
    const adjustmentValue = parseFloat(event.target.value) || 0; // Ensure value is a number
    setAdjustment(adjustmentValue);
    setGrandTotal(subTotal + taxAmount - adjustmentValue);
  };

//   const handleDecriptionChange = (event) => {
//     const descriptionValue = parseFloat(event.target.value) || 0; // Ensure value is a number
//     setDescription(descriptionValue);
//     // setGrandTotal(subTotal + taxAmount - adjustmentValue); // Uncomment this if needed to update grand total
// };
const handleDecriptionChange = (event) => {
  const descriptionValue = event.target.value; // Treat value as text
  setDescription(descriptionValue); // Store text input directly
};

  // const handleAdjustmentChange = (e) => {
  //   setAdjustment(e.target.value);
  // };

  // const [selectedPayment, setSelectedPayment] = useState(""); // To track payment type
  const [chequeId, setChequeId] = useState("");               // ChequeID state
  const [upiId, setUpiId] = useState("");                     // UPI ID state
  const [bankAccount, setBankAccount] = useState("");         // Bank account state

 
  useEffect(() => {
    async function fetchdata() {
      try {
        const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
        const response = await axios.get(
          `${config.base_url}api/parties_details/`,
          {
            headers: {
              "Content-Type": "application/json",
            }
            , params:{
              'user_id':user_id,"role":user
            }
          }
        );
        console.log(response);
        if (response.data.status === 200) {
         
          setParties(response.data.data);
          // setStockInHand(response.data.data.item_stock_in_hand)
          // setDate(response.data.data.item_date)
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, [config.base_url]);
  // const handleItemNameChange = (value) => {
    const [errors3, setErrors3] = useState({ itemName: '' });
    const [errors4, setErrors4] = useState({ party: '' });
    // const [errors6, setErrors6] = useState({ email: '' });
    const handleItemNameChange = async (value) => {
      setItemName(value); // Update the item name state
      const user = Cookies.get("role");
      const user_id = Cookies.get("user_id");
    
      try {
        // Make the API call
        const response = await axios.get(`${config.base_url}api/validate_item_name/`, {
          params: {
            item_name: value,
            user_id: user_id,
            role: user,
          },
        });
    
        console.log("API Response:", response.data); // Debugging: check the response
    
        // Check if the item exists
        if (response.data.item_exists) {
          setErrors3((prev) => ({
            ...prev,
            itemName: "Item already exists", // Set the error for item name
          }));
        } else {
          // Clear the error if item is valid
          setErrors3((prev) => ({
            ...prev,
            itemName: "",
          }));
        }
      } catch (error) {
        console.error("Validation error:", error); // Log the error for debugging
        setErrors3((prev) => ({
          ...prev,
          itemName: "An error occurred. Please try again.", // Set a generic error message
        }));
      }
    };
    // const handlePartyNameChange = async (value) => {
    // const handlePartyNameChange = async (value) => {
    //   // Update formData with the new value
    //   setFormData((prev) => ({
    //     ...prev,
    //     partyName: value, // Correctly update state
    //   }));
    
    //   const user = Cookies.get("role");
    //   const user_id = Cookies.get("user_id");
    
    //   console.log("Checking party name:", value);
    
    //   try {
    //     const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
    //       params: {
    //         party_name: value, // Use value directly instead of formData.party_name
    //         user_id: user_id,
    //         role: user,
    //       },
    //     });
    
    //     console.log("API Response:", response.data);
    
    //     if (response.data.party_exists) {
    //       setErrors4((prev) => ({
    //         ...prev,
    //         party: "Party name already exists", // Show validation error
    //       }));
    //     } else {
    //       setErrors4((prev) => ({
    //         ...prev,
    //         party: "", // Clear error when valid
    //       }));
    //     }
    //   } catch (error) {
    //     console.error("Validation error:", error.response ? error.response.data : error.message);
    //     setErrors4((prev) => ({
    //       ...prev,
    //       party: "party name is required", // Show generic error message
    //     }));
    //   }
    // };
    const handlePartyNameChange = async (value) => {
      // Update formData with the new value
      setFormData((prev) => ({
        ...prev,
        partyName: value, // Correctly update state
      }));
    
      const user = Cookies.get("role");
      const user_id = Cookies.get("user_id");
    
      console.log("Checking party name:", value);
    
      // ✅ Check if any of the three fields contain a value before making the API call
      if (!formData.gstin && !formData.partyPhNo && !formData.email) {
        console.log("No GSTIN, Phone Number, or Email provided. Skipping validation.");
        return; // Exit function early
      }
    
      try {
        const response = await axios.get(`${config.base_url}api/validate_party_name/`, {
          params: {
            party_name: value,
            user_id: user_id,
            role: user,
            gstin:formData.gstin,
            email:formData.email,
            partyPhNo:formData.partyPhNo
          },
        });
    
        console.log("API Response:", response.data);
    
        if (response.data.party_exists) {
          setErrors4((prev) => ({
            ...prev,
            party: "Party name already exists", // Show validation error
          }));
        } else {
          setErrors4((prev) => ({
            ...prev,
            party: "", // Clear error when valid
          }));
        }
      } catch (error) {
        console.error("Validation error:", error.response ? error.response.data : error.message);
        setErrors4((prev) => ({
          ...prev,
          party: "", // Show generic error message
        }));
      }
    };
    
  // }
      //  catch (error) {
      //   console.error("Validation error:", error);
      //   setErrors3({
      //     ...errors3,
      //     itemName: "Please enter a valid email address.",
      //   });
    //   }
    // };
    
// const handleHsnChange = (value) => {
//     setHsn(value);
//     setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
// };

// const handleSacChange = (value) => {
//     setSac(value);
//     setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
// };

const handleHsnChange = async (value) => {
  setHsn(value);
  const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
  // Check if the HSN already exists in the database
  if (/^\d+$/.test(value)) {
  const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
      params: { hsn: value ,'user_id':user_id,"role":user },
  });
  
  if (response.data.hsn_exists) {
    setErrors((prev) => ({ ...prev, hsn: 'HSN already exists' }));
} else {
    setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
  //   setErrors3({
  //     itemName: ''
  //    // Reset other error fields as needed
  //  });
}
}
  else{
    setHsn(value);
    setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
  }
};

const handleSacChange = async (value) => {
  setSac(value);
  const user = Cookies.get("role");
  const user_id = Cookies.get("user_id");
  // Check if the SAC already exists in the database
  if (/^\d+$/.test(value)) {
  const response = await axios.get(`${config.base_url}api/check_existing_hsn_sac/`, {
      params: { sac: value  ,'user_id':user_id,"role":user},
  });

  if (response.data.sac_exists) {
      setErrors((prev) => ({ ...prev, sac: 'SAC already exists' }));
  } else {
      setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
      setErrors3({
        itemName: ''
       // Reset other error fields as needed
     });
  }}
  else{
    setSac(value);
    setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
  }
};

// validations
const validateItemName = (value) => {
  if (!value.trim()) return "Item name is required.";
  if (allItems.find(item=>item.item_name===value)) {   
      setValid(true)
      return (
      <p style={{width:'65%'}} className='text-danger'>Item Name already exist. Choose another name</p>
      );
  } 
  setValid(false)
  return (
      <p className='text-success'>Valid</p>
  ); // Returning string "Valid" for successful validation
};

const validateHsn = (value) => {
  if (!value.trim()) return "HSN is required.";
  if (!/^\d{6,}$/.test(value)) return "HSN must be 6 digits.";
  if (allItems.find(item=>item.item_hsn==value)){
      setValid(true)
      return "hsn already exist"
  } 
  setValid(false)
  setErrors3({
    itemName: ''
   
 });
  return (
      <p className='text-success'>Valid</p>
  ); // Return an empty string if validation is successful
};

const validateSac = (value) => {
  if (!value.trim()) return "SAC is required.";
  if (!/^\d{6,}$/.test(value)) return "SAC must be 6 digits.";
  if (allItems.find(item=>item.item_sac==value)){
      setValid(true)
      return "SAC already exist"
  } 
  setValid(false)
  setErrors3({
    itemName: ''
   
 });
  return (
      <p className='text-success'>Valid</p>
  );;
};
  // useEffect(() => {
  //   fetch(`${config.base_url}/api/parties/`) // Replace with your backend API URL
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setParties(data);
  //     })
  //     .catch((error) => console.error("Error fetching party data:", error));
  // }, []);


  // useEffect(() => {
  //   async function fetchdata() {
  //     try {
  //       const user = Cookies.get("role");
  //       var is_company = false;
  //       if (user === "Company") {
  //         is_company = true;
  //       }
  //       const ID = Cookies.get("user_id");
  //       const user_id = ID
  //       const response = await axios.get(
  //         `${config.base_url}all_items_detail?user_id=${user_id}&role=${user}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           }
  //         }
  //       );
  //       console.log(response);
  //       if (response.data.status === 200) {
  //         console.log(response);
  //         setItems(response.data.data);
  //         // setStockInHand(response.data.data.item_stock_in_hand)
  //         // setDate(response.data.data.item_date)
  //       } else {
  //         console.log(response);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchdata();
  // }, [config.base_url]);
  // useEffect(() => {
  //   async function fetchdata() {
  //     try {
  //       const user = Cookies.get("role");
  //       const user_id = Cookies.get("user_id");
  //       const itemName = '';  // You can optionally get the item name if needed
  
  //       const response = await axios.get(
  //         `${config.base_url}all_items_detail?user_id=${user_id}&role=${user}&item_name=${itemName}`, 
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  
  //       if (response.data.status === 200) {
  //         setItems(response.data.data);
  //       } else {
  //         console.error("Error: No data found", response);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  
  //   fetchdata();
  // }, [config.base_url]);
  useEffect(() => {
    async function fetchdata() {
      try {
        console.log("abcd")
        const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
        // const itemName = '';  // Optional item name
       
        const url = `${config.base_url}all_items_filter`;
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            
          },
          params:{
            'user_id':user_id,"role":user
          }
        });
        console.log(response.data)
        if (response.data.status === 200) {
          console.log(response.data)
          setItems(response.data.data);
        } else {
          console.error("Error: No data found", response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchdata();
  }, [config.base_url]);
  
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, i) => i !== index);
    setRows(updatedRows);
  };
  // const [stateOfSupply, setStateOfSupply] = useState("state"); // Manage selected state

  
  // const [selectedState, setSelectedState] = useState('');

  // List of states (you can also fetch this from an API)
  const states = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
   ' Assam',
   ' Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Maharashtra',
    'Madhya Pradesh',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    
    // Add more states as needed
  ];
  
  
  // useEffect(() => {
  //   fetch('http://localhost:8000/items')  // URL of your Django API
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setItems(data);  // Set fetched items to state
  //     })
  //     .catch((error) => console.error('Error fetching items:', error));
  // }, []);
  const getLogDetails = () => {
    axios
      .get(`${config.base_url}user/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.data;
          var logImg = null;
          if (details.image) {
            logImg = `${config.base_url}${details.image}`;
          }
          setLoginImage(logImg);
          setLoginName(details.name);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };
 
  // const handlesave = async() =>{
  //   const data={

  //   }
  // }

  const handleQuantityChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].qty = value;
    // Recalculate the total with discount
    updatedRows[index].total = updatedRows[index].item_sale_price * value - updatedRows[index].discount;
    setRows(updatedRows);
    console.log("rows",updatedRows)
   // Set updated rows state if needed
   let totalSubTotal = 0;
   let totalTax = 0;

   // Calculate SubTotal and total tax
   rows.forEach((row) => {
    console.log("hjkas",row.item_sale_price)
    const itemTotal = (row.item_sale_price * row.qty) - row.discount; // Calculate item total
    totalSubTotal += itemTotal;

     // const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, ''));
     // const taxAmountForRow = (itemTotal * row.tax) / 100;
     // const taxValue = parseFloat(row.tax.match(/(\d+)(?=%)/)[0]);
//       const taxMatch = row.tax.match(/(\d+)(?=%)/); // Match digits before %
// const taxValue = taxMatch ? parseFloat(taxMatch[0]) : 0;
// // Calculate tax amount
// print("//taxmatch",taxMatch)
    const text = row.tax;
    console.log("-----",text)
    const match = text.match(/([a-zA-Z]+)(\d+)\[/);
    console.log(":::",match) // Match letters followed by digits ending with '['
    const result = match ? parseInt(match[2], 10) : 0; // Extract the digits (group 2)

    console.log("bnbnl",result);
// const taxValue = parseFloat(row.tax.replace(/[^0-9.]/g, '')) || 0;
    const taxAmountForRow = (itemTotal * result) / 100;
    totalTax += taxAmountForRow;
   });

   // Calculate CGST and SGST (both are half of total tax)
   const totalCgst = totalTax / 2;
   const totalSgst = totalTax / 2;

   // Calculate Grand Total
   const totalAdjustment = parseFloat(adjustment) || 0;
   const totalGrandTotal = totalSubTotal + totalTax - totalAdjustment;

   // Set state with calculated values
   setSubTotal(totalSubTotal);
   setCgst(totalCgst);
   setSgst(totalSgst);
   setTaxAmount(totalTax);
   setGrandTotal(totalGrandTotal);
  };

  const handlePriceChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].item_sale_price = value;
    // Recalculate the total with discount
    updatedRows[index].total = updatedRows[index].item_sale_price * updatedRows[index].qty - updatedRows[index].discount;
    setRows(updatedRows);
    
  };

  const handleDiscountChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].discount = value;
    // Recalculate the total with discount
    updatedRows[index].total = updatedRows[index].item_sale_price * updatedRows[index].qty - value;
    setRows(updatedRows);
  };

  
  // Fetch item names from the backend
  // useEffect(() => {
  //   axios.get(`${config.base_url}get_item_names/`)
  //     .then(response => {
  //       setItems(response.data); // Populate item names
  //     })
  //     .catch(error => {
  //       console.error('Error fetching items:', error);
  //     });
  // }, []);
  // const [formData, setFormData] = useState({ gstin: '' });
  // const [errors, setErrors] = useState({});

  // GSTIN validation regex (valid for Indian GSTIN format)
  const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handlePartyChange = (e) => {
    const selectedPartyId = e.target.value;
    // console.log("1111",selectedPartyId)
    // const { name, value } = e.target;
    setSelectedParty(selectedPartyId);
    
    // Find the selected party details
    const selectedPartyDetails = parties.find(
      (party) => party.id === parseInt(selectedPartyId)
    );

    if (selectedPartyDetails) {
      const email = selectedPartyDetails.email;

      // Check if the email is valid
      if (!emailRegex.test(email)) {
        alert("Invalid email format");
        return; // Stop further processing if email is invalid
      }
      setPhoneNumber(selectedPartyDetails.phone_number);
      setBillingAddress(selectedPartyDetails.billing_address);
    } else {
      setPhoneNumber("");
      setBillingAddress("");
    }
  };


  useEffect(() => {
    getLogDetails();
  }, []);

  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );

  useEffect(() => {
    const handleResize = () =>
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isTaxable, setIsTaxable] = useState(true);
  // const [activeTab, setActiveTab] = useState('pricing');

  // const [isTaxable, setIsTaxable] = useState(true);
  // const [activeTab, setActiveTab] = useState('pricing');

  const[itemName,setItemName] = useState('')
  const[hsn,setHsn] = useState('')
  const[sac,setSac] = useState('')
  const[itemType,setItemType] = useState('')
  // const[unit,setUnit] = useState('')
  const[gst,setGst] = useState('')
  const[igst,setIgst] = useState('')
  const[salesPrice,setSalesPrice] = useState(0)
  const[nonTaxableSalePrice,setnonTaxableSalePrice] = useState(0)  
  const[nonTaxablePurchasePrice,setnonTaxablePurchasePrice] = useState(0) 
  const[purchasePrice,setPurchasePrice] = useState(0)
  const[atPrice,setAtprice] = useState(0)
  const[stockInHand,setStockInHand] = useState(0)
  const[minStockMaintain,setminStockMaintain] = useState(0)
  const[date,setDate] = useState(new Date().toISOString().split('T')[0])

  const [allItems,setAllItems] = useState([])
  // const [addunit,setAddUnit] = useState('')
  // const [allUnit,setAllUnit] = useState([])
  // const [count,setCount] = useState(0)
  const [valid,setValid] = useState(false)
  const [errors, setErrors] = useState({
      itemName: "",
      hsn: "",
      sac: ""
  });
  const [errors5, setErrors5] = useState({
    addunit:''
});
//   const handleAddUnit = async(e,id)=>{
//     e.preventDefault();
//     const ID = Cookies.get("user_id");
//     const user = Cookies.get("role");
//     const user_id = ID
//     const data={
//         unit_name:addunit,
//         user: user_id,
//         role: user
//     }
//     try {
//         const response = await axios.post(`${config.base_url}add_unit1`,data,{
//           headers:{
//             'Content-Type':'multipart/form-data',
//           }
//         });
//         console.log(response)
//         console.log("+++",response.data)
//         if(response.data.status===200){
//             setCount(id)
//           console.log(response)
//           alert("Unit added successfully!");
//           setModal1Open(false)
//           setModal1Open1(false)
//           fetchUnits()
//           // window.location.reload(); 

//         }
//         else{
//           console.log("error1")
          
//         }
        
//       } catch (err) {
//         console.log("error2",err)
       
//       }
// }
const handleAddUnit = async (e, id) => {
  e.preventDefault();
  const ID = Cookies.get("user_id");
  const user = Cookies.get("role");
  const data = {
      unit_name: addunit, // Ensure addunit is not empty
      user: ID, // Ensure ID is valid
      role: user, // Ensure role is not undefined
  };

  console.log("Data being sent:", data); // Debugging

  try {
      // const response = await axios.post(`${config.base_url}add_unit1`, data, {
      //     headers: {
      //         "Content-Type": "application/json", // Updated to JSON
      //     },
      // });
      const response = await axios.post(`${config.base_url}add_unit1`, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
    
      console.log(response.data);
      if (response.data.status === 200) {
          alert("Unit added successfully!");
          setModal1Open(false);
          setModal1Open1(false);
          fetchUnits();
          setAddUnit('');
          setErrors5({
           addunit:''
            // Reset other error fields as needed
          });
      } else {
          console.log("Error:", response.data.message);
      }
  } catch (err) {
    // alert("unit already exist")
    setErrors5((prev) => ({
      ...prev,
      addunit: "Unit already exists", // Set the error for item name
    }));
      console.error("Error:", err);
  }
};

const fetchUnits = async () => {
  try{
    const user = Cookies.get("role");
    const user_id = Cookies.get("user_id");
    console.log("---",user)
    const response = await axios.get(
      `${config.base_url}all_unit1`,
      {
        headers: {
          "Content-Type": "application/json",
        }
        , params:{
          'user_id':user_id,"role":user
        }
      }
    );
    if (response.data.status === 200) {
      console.log(response);
      console.log(response.data)
      setAllUnit(response.data.data);
      return response.data.data;
      // console.log()
   
    } else {
      console.log(response);
    }
  }
  catch (err) {
    console.error("Fetch Error:", err);
  }

} 
const fetchParty=async()=>{
  try {
    const user = Cookies.get("role");
    const user_id = Cookies.get("user_id");
    const response = await axios.get(
      `${config.base_url}api/parties_details/`,
      {
        headers: {
          "Content-Type": "application/json",
        }
        , params:{
          'user_id':user_id,"role":user
        }
      }
    );
    console.log(response);
    if (response.data.status === 200) {
     
      setParties(response.data.data);
      // setStockInHand(response.data.data.item_stock_in_hand)
      // setDate(response.data.data.item_date)
    } else {
      console.log(response);
    }
  } catch (error) {
    
    console.log(error);
  }
}
const fetchItems = async () => {
  try {
    const user = Cookies.get("role");
    const user_id = Cookies.get("user_id");

    const url = `${config.base_url}all_items_filter`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        'user_id': user_id,
        "role": user
      }
    });

    console.log("Fetched Items:", response.data); // Log the fetched items
    setItems(response.data.data);
  } catch (err) {
    console.error("Fetch Error:", err);
  }
};
const handleSave = async(e,id)=>{
  // if(valid===false){
      e.preventDefault();
      const user = Cookies.get("role");
      var is_company = false;
      if (user === "Company") {
        is_company = true;
      }
      const ID = Cookies.get("user_id");
      const user_id = ID
      
  const data={
      item_name: itemName,
      item_hsn: hsn,
      item_sac:sac,
      done_by_name:user_id,
      item_type: itemType,
      item_unit: ItemData.unit_name,
      item_gst: gst,
      item_igst: igst,
      item_taxable: isTaxable ? true:false,
      item_sale_price: isTaxable ? salesPrice : nonTaxableSalePrice, // Use conditional value
      item_purchase_price: isTaxable ? purchasePrice : nonTaxablePurchasePrice, // Use conditional value
      item_opening_stock: stockInHand,
      item_current_stock: stockInHand,
      item_at_price: atPrice,
      item_min_stock_maintain: minStockMaintain,
      item_date: date,
      trans_qty: stockInHand, 
      // company_id:user_id,
      // staff_id:user_id,
      role:user
  }
  console.log(data)
  // const missingFields = Object.entries(data).filter(([key, value]) => !value && value !== 0);

  // if (missingFields.length > 0) {
  //   const missingFieldNames = missingFields.map(([key]) => key).join(", ");
  //   alert(`Please fill in the following fields: ${missingFieldNames}`);
  //   return; // Exit function if validation fails
  // }
  const missingFields = [];

  if (!itemName) missingFields.push("Item Name");
  if (!hsn && !sac) missingFields.push("HSN or SAC");
  if (hsn && sac) missingFields.push("Only one of HSN or SAC should be entered (not both)");
  if (!itemType) missingFields.push("Item Type");
  // if (!gst && !igst) missingFields.push("Tax (GST or IGST)");
  // if (!salesPrice && !nonTaxableSalePrice) missingFields.push("Sales Price (Taxable or Non-Taxable)");
  // if (!purchasePrice && !nonTaxablePurchasePrice) missingFields.push("Purchase Price");
  if (isTaxable && !gst && !igst) {
    missingFields.push("Tax (GST or IGST)");
  }
  
  // Validate sales price based on taxable or non-taxable
  if (isTaxable && !salesPrice) {
    missingFields.push("Sales Price (Taxable)");
  } else if (!isTaxable && !nonTaxableSalePrice) {
    missingFields.push("Sales Price (Non-Taxable)");
  }
  
  // Validate purchase price based on taxable or non-taxable
  if (isTaxable && !purchasePrice) {
    missingFields.push("Purchase Price (Taxable)");
  } else if (!isTaxable && !nonTaxablePurchasePrice) {
    missingFields.push("Purchase Price (Non-Taxable)");
  }
  
  if (!stockInHand) missingFields.push("Stock in Hand");
  if (!atPrice) missingFields.push("At Price");
  // if (!minStockMaintain) missingFields.push("Minimum Stock to Maintain");
  if (!date) missingFields.push("Date");

  if (missingFields.length > 0) {
    alert(`Please fill the following fields before saving:\n- ${missingFields.join("\n- ")}`);
    return; // Stop execution if validation fails
  }
  if (
    Object.values(errors).some(error => error !== "Valid") && 
    Object.values(errors3).some(error => error !== "")
) {
    alert("Please resolve the errors before saving.");
    return; // Stop execution if there are errors
}

  try {
      const response = await axios.post(`${config.base_url}add_item_detail`,data,{
        headers:{
          'Content-Type':'multipart/form-data',
        }
      });
      console.log(response)
      if(response.data.status===200){
        console.log(response)
        await fetchItems();
        setShowStock(false)
        alert("Item details added successfully!");
        
        // navigate('/invoice')
        // window.location.reload(); 
        // setCount(id)
        // toast.success("Item Added Successfully!",{
        //   autoClose:1000,
        //   position:'top-right',
        // });
      }
      else{
        console.log("error1")
       
      }
      setItemName('')
      setHsn("");
      setSac('')
      setItemType('')
      setGst('')
      setIgst('')
      setSalesPrice(0)
      setPurchasePrice(0)
      setAtprice(0)
      setStockInHand(0)
      setminStockMaintain(0)
      setnonTaxablePurchasePrice(0)
      setnonTaxableSalePrice(0)
      // setUnit('')
      setItemData({
        item_name: "",
  item_hsn: "",
  item_type: "",
unit_name:"",
  opening: "",
  at_price: "",
  date: "",
  min_stock: "",
  igst:"",gst:"",
  taxable:"",
  gst:"",
  igst:"",
  sale_price:"",
  purchase_price: "",
  unit_name:"",
      });
      setErrors({
        hsn: "",
        sac: "",
        // Reset other error fields as needed
      });
      setErrors3({
        itemName: ''
       // Reset other error fields as needed
     });
    } catch (err) {
      console.log("error2",err)
      alert("failed to save invoice");
      setItemName('')
      setHsn("");
      setSac('')
      setItemType('')
      setGst('')
      setIgst('')
      setSalesPrice(0)
      setPurchasePrice(0)
      setAtprice(0)
      setStockInHand(0)
      setminStockMaintain(0)
      // setUnit('')
      setItemData({
        item_name: "",
  item_hsn: "",
  item_type: "",
unit_name:"",
  opening: "",
  at_price: "",
  date: "",
  min_stock: "",
  igst:"",gst:"",
  taxable:"",
  gst:"",
  igst:"",
  sale_price:"",
  purchase_price: "",
  unit_name:"",
      });
      setErrors({
        hsn: "",
        sac: "",
        // Reset other error fields as needed
      });
      // toast.error("Item cannot be added",{
      //   autoClose:1000,
      //   position: "top-right",
      // })
    }
  // }else{
  //     console.log("error3")
  //     // toast.error("Item not added",{
  //     //   autoClose:1000,
  //     //   position: "top-right",
  //     // })

  // }
  
} 
//  const handleSave = async(e,id)=>{
//       // if(valid===false){
//           e.preventDefault();
//           const user = Cookies.get("role");
//           var is_company = false;
//           if (user === "Company") {
//             is_company = true;
//           }
//           const ID = Cookies.get("user_id");
//           const user_id = ID
//           const data={
//             item_name: itemName,
//             item_hsn: hsn,
//             item_sac:sac,
//             done_by_name:user_id,
//             item_type: itemType,
//             item_unit: ItemData.unit_name,
//             item_gst: gst,
//             item_igst: igst,
//             item_sale_price: isTaxable ? salesPrice : nonTaxableSalePrice, // Use conditional value
//             item_purchase_price: isTaxable ? purchasePrice : nonTaxablePurchasePrice, // Use conditional value
//             item_opening_stock: stockInHand,
//             item_at_price: atPrice,
//             item_min_stock_maintain: minStockMaintain,
//             item_date: date,
//             trans_qty: stockInHand, 
//             // company_id:user_id,
//             // staff_id:user_id,
//             role:user
//         }
//           if (
//             !itemName ||
//             !itemType ||
//             (!gst && !igst) || // At least one tax should be entered
//             (!salesPrice && !nonTaxableSalePrice) || // At least one price should be entered
//             !purchasePrice ||
//             !stockInHand ||
//             !atPrice ||
//             // !minStockMaintain ||
//             !date ||
//             (!hsn && !sac) || // At least one of HSN or SAC should be entered
//             (hsn && sac) // Both HSN and SAC cannot be entered
//           ) {
//             const missingFields = [];
          
//             if (!itemName) missingFields.push("Item Name");
//             if (!itemType) missingFields.push("Item Type");
//             if (!gst && !igst) missingFields.push("Tax (GST or IGST)");
//             if (!salesPrice && !nonTaxableSalePrice) missingFields.push("Sales Price (Taxable or Non-Taxable)");
//             if (!purchasePrice) missingFields.push("Purchase Price");
//             if (!stockInHand) missingFields.push("Stock in Hand");
//             if (!atPrice) missingFields.push("At Price");
//             // if (!minStockMaintain) missingFields.push("Minimum Stock to Maintain");
//             if (!date) missingFields.push("Date");
//             if (!hsn && !sac) missingFields.push("Either HSN or SAC");
//             if (hsn && sac) missingFields.push("Only one of HSN or SAC should be entered (not both)");
          
//             alert(`Please address the following issues before saving:\n- ${missingFields.join("\n- ")}`);
//             return; // Stop execution if validation fails
//           }
//       console.log("hai")
//         try {
//           const response = await axios.post(`${config.base_url}add_item_detail`,data,{
//             headers:{
//               'Content-Type':'multipart/form-data',
//             }
//           });
//           console.log(response)
//           if(response.data.status===200){
//             console.log(response)
//             await fetchItems();
//   //           const modal = document.querySelector('.modal'); // Select your modal by class or ID
//   // const bootstrapModal = bootstrap.Modal.getInstance(modal); // Get the Bootstrap modal instance
//   // bootstrapModal.hide();
       
//             // navigate('/invoice')
//             // window.location.reload(); 
//             // setCount(id)
//             // toast.success("Item Added Successfully!",{
//             //   autoClose:1000,
//             //   position:'top-right',
//             // });
//           }
//           else{
//             console.log("error1")
           
//           }
//           setItemName('')
//           setHsn("");
//           setSac('')
//           setItemType('')
//           setGst('')
//           setIgst('')
//           setSalesPrice(0)
//           setPurchasePrice(0)
//           setAtprice(0)
//           setStockInHand(0)
//           setminStockMaintain(0)
//           // setUnit('')
//           setItemData({
//             item_name: "",
//       item_hsn: "",
//       item_type: "",
//     unit_name:"",
//       opening: "",
//       at_price: "",
//       date: "",
//       min_stock: "",
//       igst:"",gst:"",
//       taxable:"",
//       gst:"",
//       igst:"",
//       sale_price:"",
//       purchase_price: "",
//       unit_name:"",
//           });
//           setErrors({
//             hsn: "",
//             sac: "",
//             // Reset other error fields as needed
//           });
//         } catch (err) {
//           console.log("error2",err)
//           alert("Same Item Already Exist");
//           setItemName('')
//           setHsn("");
//           setSac('')
//           setItemType('')
//           setGst('')
//           setIgst('')
//           setSalesPrice(0)
//           setPurchasePrice(0)
//           setAtprice(0)
//           setStockInHand(0)
//           setminStockMaintain(0)
//           // setUnit('')
//           setItemData({
//             item_name: "",
//       item_hsn: "",
//       item_type: "",
//     unit_name:"",
//       opening: "",
//       at_price: "",
//       date: "",
//       min_stock: "",
//       igst:"",gst:"",
//       taxable:"",
//       gst:"",
//       igst:"",
//       sale_price:"",
//       purchase_price: "",
//       unit_name:"",
//           });
//           setErrors({
//             hsn: "",
//             sac: "",
//             // Reset other error fields as needed
//           });
//           // toast.error("Item cannot be added",{
//           //   autoClose:1000,
//           //   position: "top-right",
//           // })
//         }
//       // }else{
//       //     console.log("error3")
//       //     // toast.error("Item not added",{
//       //     //   autoClose:1000,
//       //     //   position: "top-right",
//       //     // })

//       // }
      
//   }
  // const [rows, setRows] = useState([]);

  // Function to add rows to the table
  // const addItem = () => {
  //   const newRow = {
  //     id: rows.length + 1,
  //     product: "Sample Product",
  //     hsn: "",
  //     qty: "",
  //     price: 0,
  //     tax: 0,
  //     discount: 0,
  //     total: 0,
  //   };
  //   setRows([...rows, newRow]);
  // };
//   const [rows, setRows] = useState([
//     { hsn: '', qty: 0, price: 0, tax: 0, discount: 0, total: 0 },
// ]);

const clearModalInputs = () => {
  // setIsCredit(false) 
setItemName('')
setHsn("");
setSac('')
setItemType('')
setGst('')
setIgst('')
setSalesPrice(0)
setPurchasePrice(0)
setAtprice(0)
setStockInHand(0)
setminStockMaintain(0)
// setUnit('')
setShowStock(false)
setnonTaxablePurchasePrice(0)
setnonTaxableSalePrice(0)
setErrors({
  hsn: "",
  sac: "",
  // Reset other error fields as needed
});
setErrors3({
   itemName: ''
  // Reset other error fields as needed
});
setItemData({
  item_name: "",
item_hsn: "",
item_type: "",
unit_name:"",
opening: "",
at_price: "",
date: "",
min_stock: "",
igst:"",gst:"",
taxable:"",
gst:"",
igst:"",
sale_price:"",
purchase_price: "",
unit_name:"",
});
};

const addItem = () => {
  const newRow = {
    id: Date.now().toString(), // Unique ID based on timestamp
    item_name: "",
    item_hsn: "",
    tax: "",
    item_sale_price: 0,
    qty: 0,
    discount: 0,
    total: 0,
  };
  setRows([...rows, newRow]);
};

// const addItem = () => {
//   const newRow = {
//     id: "",
//     item_name: "",
//     item_hsn: "",
//     tax: "",
//     item_sale_price: 0,
//     qty: 0,
//     discount: 0,
//     total: 0,
//   };
//   setRows([...rows, newRow]);
// };
const [isCredit, setIsCredit] = useState(false);
const handleGstNext = () => {
  setIsCredit(true);
};
const handleCloseModalUnit = () => {
  setAddUnit(""); // Clear the input value
  setModal1Open1(false);
  setModal1Open(false)
  setErrors5({
    addunit:''
     // Reset other error fields as needed
   });
};
// Update handleTaxableChange
const handleTaxableChange = (e) => {
  const isTaxable = e.target.value === "taxable"; // Ensure boolean value
  console.log(isTaxable)
  setItemData({ ...ItemData, taxable: isTaxable });
};
// const [showModal, setShowModal] = useState(false);  // Modal visibility state

// Function to close the modal
const handleCloseModal = () => {
  setShowModal(false);  // Set modal visibility to false, which will close the modal
};

const handleCreditPrev = () => {
  setIsCredit(false);
};
  // State for toggling the button
  const [buttonToggled, setButtonToggled] = useState(false);

  // const handleSubmit = async (e) => {
    const handleSubmit = async (e, navigateToInvoice) => {
    e.preventDefault(); // Prevent default form submission
    const companyId = Cookies.get("companyId"); // Retrieve company ID
    const staffId = user === "staff" ? Cookies.get("staffId") : null;
    let paymentDetails = {};
    if (selectedPayment === "Cheque") {
      paymentDetails.paymentType = "Cheque"; // Ensure payment type is set
      paymentDetails.chequeId = document.getElementById("chequeId").value;
    } else if (selectedPayment === "UPI") {
      paymentDetails.paymentType = "UPI"; // Ensure payment type is set
      paymentDetails.upiId = document.getElementById("upiId").value;
    } else if (selectedPayment === "Bank") {
      paymentDetails.paymentType = "Bank"; // Ensure payment type is set
      paymentDetails.bankAccount = document.getElementById("bankAccount").value;
    } else if (selectedPayment === "Cash") {
      paymentDetails.paymentType = "Cash"; // Ensure payment type is set for Cash
    } else if (selectedPayment === "") {
      paymentDetails.paymentType = "Cash"; 
    }
    let supplyDetails = "";
    
    // if (selectedState === "state") {
    //   supplyDetails = "state";  // Just pass the value as a string
    // } else if (selectedState === "otherState") {
    //   supplyDetails = "other state";  // Just pass the value as a string
    // }

    console.log("Rows before submission:", rows);
    const hasInvalidQuantity = rows.some((row) => row.qty === 0);
    if (hasInvalidQuantity) {
      alert("Quantity cannot be 0. Please update the quantities before saving.");
      return; // Exit the function early
    }

    const invoiceItems = rows.map((row) => ({
      
      itemId: row.id,
      hsn: row.item_hsn,
      sac: row.item_sac,
      quantity: row.qty,
      rate: row.item_sale_price,
      discount: row.discount,
      tax: row.tax,
      total: row.total,
      state_of_supply:stateOfSupply
    }));
// const [isCredit, setIsCredit] = useState(false);
   
  // const handleFieldChange = (e) => {
  //     const { name, value } = e.target;
  //     setItemData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   };
    
    const invoiceNo = document.getElementById("invoiceNo").value;
    const invoiceData = {
      invoiceNo: invoiceNo,
      party: selectedParty,
      subtotal: subTotal,
      cgst: cgst,
      sgst: sgst,
      taxAmount: taxAmount,
      adjustment: adjustment,
      grandTotal: grandTotal,
      description: description,
      user_id: user_id,
      role:user,
      paymentDetails: paymentDetails,
      contact:phoneNumber,
      address:billingAddress,
      state_of_supply: supplyDetails,
      invoiceItems: invoiceItems, 
      // company_id: user === "company" ? companyName : companyId, // Company ID is always needed
      // staff_id: user === "staff" ? staffId : null,
      date: new Date().toISOString().split('T')[0], // Add current date
      
    };
    
    
    try {
      // `${config.base_url}/api/save_invoice/`
      const response = await fetch( `${config.base_url}api/save_invoice/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(invoiceData),
    });
    
      // });
    
    
      // const responseData = await response.json();
      // console.log(responseData);
      console.log("response is ",response)
      if (response.ok) {
        // If the response is successful, reload the page to reflect the changes
        // window.location.reload();
        if (navigateToInvoice) {
          // Navigate to /show_invoice after successful save
          window.location.href = "/show_invoice";
        } else {
          // Reload the current page for "Save and Next"
          window.location.reload();
        }
       
      } else {
        // If the response is not successful, show an error message
        // alert('Failed to save the invoice. Please try again.');
        return response.json().then(err => {
          alert(err.error); // Show error message in an alert
          // throw new Error(err.error); // Stop further execution
      });
      }
     
    // If the invoice was saved successfully, fetch the last invoice number and update the invoice number
   } catch (error) {
      console.error('Error:', error.message); // Log error
    }
    //  catch (error) {
    //   console.error('Error:', error);
    //   alert(`Error: ${error.message}`);
    // }
    
    
};
const handleBack = () => {
  navigate(-1); // Navigate to the previous page
};

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel container-fluid p-1"
          style={{ background: "#636e99"}}
        >
          
         
            
          <div
            className="card p-4 m-4 responsive-card"
            style={{
              backgroundColor: "#ffffff",
              // borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              // marginTop:"0px"
              paddingBottom:"400px"
            }}
          >
          <div className="row mb-3">
      <div className="col-12 ">
        <h3
          className="text-light text-center d-flex justify-content-between align-items-center flex-wrap"

          // className="text-light text-center d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#3d4465",
            padding: "10px",
            borderRadius: "8px", // Rounded corners for header
            margin: "0", // Remove extra margins
          }}
        >
          <span style={{ fontWeight: "bold", flex: 1, textAlign: "center" }}>
            Add Sales Invoice
          </span>
          <button
            className="btn btn-sm"
            onClick={handleBack}
            style={{
              borderRadius: "8px",
              marginLeft: "auto",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <i
              className="fas fa-arrow-left"
              style={{ marginRight: "8px" }}
            ></i>
            Back
          </button>
        </h3>
      </div>
    </div>
            <div className="d-flex justify-content-start">
            <button
                onClick={() => setIsOn(!isOn)} 
                // onClick={() => setButtonToggled(!buttonToggled)}// Toggle state
                style={{
                    width: "60px",
                    height: "30px",
                    borderRadius: "30px",
                    border: "2px solid #ccc",
                    backgroundColor: isOn ? "#4caf50" : "#ccc", // Green if ON, gray if OFF
                    position: "relative",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isOn ? "flex-end" : "flex-start", // Switch position
                    padding: "0",
                    transition: "background-color 0.3s, justify-content 0.3s",
                }}
            >
                <div
                    style={{
                        width: "25px",
                        height: "25px",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        transition: "all 0.3s",
                    }}
                ></div>
            </button>
</div>
<br /><br />
            {/* Conditional rendering for content */}
            {isOn ? (
              <div>
                <form>
                <div className="row mb-5 align-items-center">
  {/* Party Name */}
 
  <div className="col-md-4 mb-4">
    <div className="floating-label d-flex gap-2 ">
      <label
        htmlFor="partyName"
        className="form-label mb-5 pb-5 "
        // style={{
        //   display: "block",
        //   marginBottom: "8px",
        //   padding: "12px",
        // }}
      >
        Party Name
      </label>
      <select
        id="partyName"
        className="form-control ml-2 w-100"
        value={selectedParty}
        onChange={handlePartyChange} 
        style={{border: "1px solid rgb(86, 4, 248)" }}// Attach the handler
      >
        <option value="" disabled>
          Select Party
        </option>
        {parties.map((party) => (
          <option key={party.id} value={party.id}>
            {party.party_name}
          </option>
        ))}
      </select>
      <button
      type="button"
      className="btn " style={{ backgroundColor: '#3d4465',color:'white',width:"40px"}}
      // data-bs-toggle="modal"
      // data-bs-target="#customercreation"
      onClick={() => setShowModal(true)}
    >
  <b>+</b> 
    </button>
    {showModal && (
         <div className="modal" id="customercreation" aria-modal="true" style={{ display: 'block',overflow:"auto" }}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-body" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
            <form action="" method="POST" autoComplete="off" id="modalCustomer">
              <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
              <div className="modal-header" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
                <h3 className="m-3 text" style={{ color: 'white' }}>ADD PARTY</h3>
                <button
            type="button"
            className="btn-close"
            onClick={closeModal}
            style={{ border: 'none', background: 'none' }}
          ><i class="fas fa-times"></i></button>
              </div>

              <div className="bg-light bs p-4 rounded mb-5 ">
                <div id="main_form_div">
                  <div className="row" style={{ marginLeft: '-1vh' }}>
                    <div className="col-md-4 ">
                      <label className="col-form-label mb-5 pb-5 ml-2" htmlFor="partyname" style={{ color: 'black' }}>
                        Party Name
                      </label>
                      <input
                        type="text"
                        className="form-control border-secondary "
                        id="partyname"
                        name="partyName"
                        value={formData.partyName}

                        // onChange={handleChangeparty}
                        // onChange={handlePartyNameChange}
                        onChange={(e) => handlePartyNameChange(e.target.value)}

                        style={{ color: 'black' }}
                        required
                      />
                      {errors4.party && (
  <small style={{ color: 'red' }}>{errors4.party}</small> // Render the error message
)}
                    </div>
                    <div className="col-md-4">
                      <label className="col-form-label mb-5  pb-5 ml-2" style={{ color: 'black' }}>
                        GSTIN
                      </label>
                      <input
                        type="text"
                        // className="form-control border-secondary mb-5"
                        className={`form-control border-secondary ${
                          errors.gstin ? "is-invalid" : ""
                        }`}
                        id="gstin"
                        name="gstin"
                        value={formData.gstin}
                        // onChange={handleChangeparty}
                        // onChange={(e) => handleGSTChange(e.target.value)} 
                        // value={formData.gstin || ""}
                        onChange={handleGSTChange}
                        style={{ color: 'black' }}
                        placeholder="29APPCK7465F1Z1"
                        required
                      />
                     {errors.gstin && (
                        <div className="invalid-feedback" style={{ color: "red" }}>
                          {errors.gstin}
                        </div>
                      )}
                      {/* </div>
          </div>
        )} */}
                    </div>
                    <div className="col-md-4">
                      <label className="col-form-label mb-5 pb-5 ml-2" htmlFor="partyphno" style={{ color: 'black' }}>
                        Mobile
                      </label>
                      <input
                        type="text"
                        className="form-control border-secondary mb-1"
                        id="partyphno"
                        name="partyPhNo"
                        value={formData.partyPhNo
                        }
                        // onChange={handleChangeparty}
                        // onChange={handleEmailChange}
                        onChange={(e) => handlePhoneNoChange(e.target.value)}
                        
                        style={{ color: 'black' }}
                        required
                      />
                     {errors2.partyPhNo && <span style={{ color: 'red' }}>{errors2.partyPhNo}</span>}
                    </div>
                  </div>

                  <div>
                    <p></p>
                    <hr className="p-0 m-0" />
                    <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                      <div className="row pb-0 mb-0">
                        <div id="gsthead" className="col text-center pb-2"style={{
       borderBottom: !isCredit 
         ? '3px solid rgba(0, 0, 0, 0.55)' // Border when isCredit is true
         : 'none' 
        // No border when isCredit is false
     }}>
                          <button
                            className="fw-bolder fs-4 "
                            style={{ color: 'white', background: 'none', border: 'none' ,color: !isCredit ? 'black' : 'white' }}
                            // onClick={() => setActiveTab('gst')}
                            // onClick={() => setIsCredit(false)}
                            // onClick={handleCreditPrev}
                            onClick={(e) => {e.preventDefault();handleCreditPrev()}}
                          >
                            GST & ADDRESS
                          </button>
                        </div>
                        <div id="credithead" className="col text-center pb-2"  style={{
       borderBottom: isCredit 
         ? '3px solid rgba(0, 0, 0, 0.55)' // Border when isCredit is true
         : 'none' // No border when isCredit is false
     }}>
                          <button
                            className="fw-bolder fs-4"
                            style={{ color: 'white', background: 'none', border: 'none' ,color: !isCredit ? 'white' : 'black' }}
                            // onClick={() => setActiveTab('credit')}
                            onClick={(e) =>{e.preventDefault(); setIsCredit(true)}}
                            // onClick={() => setIsCredit(true)}
                          >
                            CREDIT & BALANCES
                          </button>
                        </div>
                      </div>
                    </div>

                    {!isCredit && (
                     <div id="gsttemp">
                     <div className="row ">
                       {/* First Column - GST Type, Supply State, Email Section */}
                       <div className="col-md-6  d-flex flex-wrap">
  {/* GST Type */}
  <label className="col-floating-label mt-4 pb-5 ml-2 pt-2 mb-5" style={{ color: 'black' }}>
    GST Type
  </label>
  <select
    className="form-control border-secondary mt-4 mb-2"
    name="gstType"
    value={formData.gstType}
    onChange={handleChangeparty}
    style={{ color: 'black', backgroundColor: 'white' }}
  >
    <option value="" hidden>Select</option>
    <option value="Registered Party">Registered Party</option>
    <option value="Unregistered or Consumer">Unregistered or Consumer</option>
    <option value="Registered Business or Combosision">Registered Business or Combosision</option>
  </select>

  {/* Supply State */}
  <label className="col-floating-label mt-5 pt-5   ml-2" style={{ color: 'black' }}>
    Supply State
  </label>
  <select
    className="form-control border-secondary mt-4 mb-3 "
    name="splyState"
    value={formData.splyState}
    onChange={(e) => {
      setSelectedState(e.target.value);
      handleChangeparty(e);
    }}
    style={{ color: 'black', backgroundColor: 'white' }}
  >
    <option value="">Select a State</option>
    {states.map((state, index) => (
      <option key={index} value={state}>
        {state}
      </option>
    ))}
  </select>

  {/* Email Section */}
  <label className="col-floating-label mt-5 pb-3  ml-2" style={{ color: 'black',paddingTop:"200px" }}>
    Email Address
  </label>
  <input
    type="email"
    className="form-control border-secondary mt-4"
    name="email"
    value={formData.email}
    // onChange={handleChangeparty}
    // onChange={handleEmailChange}
    onChange={(e) => handleEmailChange(e.target.value)}
    style={{ color: 'black', backgroundColor: 'white' }}
    placeholder="example@email.com"
  />
  {errors1.email && <span style={{ color: 'red' }}>{errors1.email}</span>}
</div>

                   
                       {/* Second Column - Billing Address */}
                       <div className="col-md-6 mt-4">
                         <label className="col-form-label mb-4 pb-5 ml-2" style={{ color: 'black' }} htmlFor="baddress">
                           Billing Address
                         </label>
                         <textarea
                           className="form-control border-secondary"
                           name="bAddress"
                           value={formData.
                            bAddress}
                           onChange={handleChangeparty}
                           id="baddress"
                           rows="6"
                           style={{ color: 'black', backgroundColor: 'white' }}
                         />
                       </div>
                     </div>
                   
                     <div className="text-start mt-5">
                       <button
                         id="gstnxt"
                         className="btn"
                         style={{ backgroundColor: 'rgb(61, 68, 101)' ,color:"white"}}
                         type="button"
                         onClick={() => setIsCredit(true)}
                       >
                       <b>  NEXT</b>
                       </button>
                     </div>
                   </div>
                   
                    )}

                    {isCredit && (
                    <div id="credittemp">
                    <div className="row">
                      {/* Opening Balance with Radio Buttons */}
                   

<div className="col-md-6 ">
<div className='d-flex flex-wrap '>
                    <div className="form-check d-flex align-items-center w-50">
                    <input
    className="form-check-input ml-5 pl-3 mt-4"
    type="radio"
    // name="flexRadioDefault"
    name="to_pay"
    id="flexRadioDefault1"
    // value="to_pay"
    // onChange={handleChangeparty}
     value="true"
    checked={formData.to_pay}  // Ensure the radio button is checked when to_pay is true
    onChange={handleChangeparty}
  />
  <label className="form-check-label text-black  mt-5 " htmlFor="flexRadioDefault1" style={{fontSize:"15px"}} >
  Opening Balance - To Pay
  </label>
  
</div>

                      <div className="form-check d-flex align-items-center ">
                       
                        <p className="form-check-label text-black mr-5 mt-4 " htmlFor="flexRadioDefault2"  style={{fontSize:"15px"}}>
                         To Receive
                        </p>
                        <input
                          className="form-check-input ml-3 mt-4"
                          type="radio"
                          // name="flexRadioDefault"
                           name="to_receive"
                          id="flexRadioDefault2"
                          // value="to_receive"
                          onChange={handleChangeparty}
                          value="true"
                          checked={formData.to_receive}  //
                          // checked={!isTaxable}
                          // onChange={() => setIsTaxable(false)}
                        />
                      </div>
                    </div>
                    <input
    type="text"
    className="form-control border-secondary mt-3 ml-2"
    id="openbalance1"
    name="openbalance1" // Name matches formData key
    value={formData.openbalance1}
    onChange={handleChangeparty}
    style={{ color: 'black', backgroundColor: 'white' }}
  />
 
  </div>
                  
                      {/* Credit Limit Field */}
                       <div className="col-md-6 ">
                       <label className="col-form-label ml-1 mt-4" style={{ color: 'black' }} htmlFor="crd_lmt">
                          Credit Limit
                        </label>
                        <br/><input
                          type="number"
                          className="form-control border-secondary mt-5 p-3"
                          id="crd_lmt"
                          name="crdLmt"
                          value={formData.crdLmt}
                          onChange={handleChangeparty}
                          style={{ color: 'black', backgroundColor: 'white' }}
                        />
                      </div>
                    </div>
                  
                    {/* Date Field */}
                    <div className="row">
                      <div className="col-md-6">
                        <label className="col-form-label mt-3 ml-3" style={{ color: 'black' }} htmlFor="partydate">
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control border-secondary mt-5 ml-2"
                          id="partydate"
                          name="partyDate"
                          value={formData.partyDate}
                          onChange={handleChangeparty}
                          style={{ color: 'black', backgroundColor: 'white' }}
                        />
                      </div>
                    </div>
                  
                    {/* Buttons for Save and Previous */}
                    <div className="text-start mt-5 ">
                      <button
                        id="creditprev"
                        className="btn fw-bold"
                        style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                        type="button"
                        onClick={handleCreditPrev}
                      >
                        PREVIOUS
                      </button>
                      <button
                        className="btn btn-primary fw-bold ml-3"
                        style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                        type="button"
                        id="customersave"
                        onClick={handleSubmitparty}
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                  
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>  
    )}
    </div>
    
   
    </div>
   
  {/* + Button */}
  {/* <div className="col-md-1 justify-content-center ">
  
    
  </div> */}

  {/* Phone Number */}
  <div className="col-md-4 mb-4">
    <div className="floating-label">
      <label
        htmlFor="phoneNumber"
        className="form-label pb-5 mb-5 "
        style={{
          display: "block",
          // marginBottom: "8px",
          // padding: "12px",
        }}
      >
        Phone Number</label>
      <input
        type="text"
        id="phoneNumber"
        className="form-control ml-2"
        placeholder="Enter Phone Number"
        style={{
          borderRadius: "4px",
          // padding: "10px",
          fontSize: "14px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
        }}
        value={phoneNumber}
        readOnly
      />
    </div>
  </div>

  {/* Billing Address */}
 <div className="col-md-4">
    <div className="floating-label">
     <label
        htmlFor="billingAddress"
        className="form-label pb-5 mb-5"
        style={{
          display: "block",
          // marginBottom: "8px",
          // padding: "12px"
        }}
      >
        Billing Address 
      </label> 
      <textarea
        id="billingAddress"
        className="form-control ml-2 mb-2 pb-2"
        rows="3"
        placeholder="Enter Billing Address"
        style={{
          borderRadius: "4px",
          // padding: "10px",
          fontSize: "14px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
        }}
        value={billingAddress}
        readOnly
      ></textarea>
    </div>
  </div>
</div>

<div className="row mb-3">
                    <div className="col-md-4 mb-4">
                      <div className="floating-label " >
                        
                        <label htmlFor="invoiceNo" className="mb-5 pb-5" >Invoice No</label>
                        <input
                          type="text"
                          className="form-control ml-2 "
                          id="invoiceNo"
                          value={invoiceNo}
                          readOnly
                          // placeholder="Enter Invoice No"
                          // defaultValue="1"
                          // className="pt-5 mb-5 pl-2"
                          style={{ borderRadius: "4px",marginleft: "12px",padding: "15px" ,border: "1px solid rgb(86, 4, 248)",background:"white" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="floating-label">
                      <label htmlFor="date" className="mb-5 pb-5 ">Date</label>
                    
                        <input
                          type="date"
                          className="form-control ml-2"
                          id="date"
                          defaultValue={new Date().toISOString().split("T")[0]}
                          style={{ borderRadius: "4px",padding: "15px" ,border: "1px solid rgb(86, 4, 248)"}}
                        />
                          </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-label">
                      <label htmlFor="stateOfSupply" className="mb-5 pb-5">State Of Supply</label>
                    
                        <select
                          className="form-control ml-2"
                          id="stateOfSupply"
                          value={stateOfSupply}
                          style={{ borderRadius: "4px" ,border: "1px solid rgb(86, 4, 248)"}}
                          onChange={handleStateOfSupplyChange}
                        >
                          <option value="State">State</option>
                          <option value="otherState">Other State</option>
                        </select>
                         </div>
                    </div>
                  </div>
                
                  <div className="row mb-2 d-flex">
  {/* Payment Type */}
  <div className="col-md-6 mb-2"> {/* Adjusted width */}
    <div className="floating-label">
      <label htmlFor="paymentType" className="form-label mb-5 pb-5 ">
        Payment Type
      </label>
      <select
        className="form-select mt-2 ml-2"
        id="paymentType"
        style={{
          borderRadius: "4px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid rgb(86, 4, 248)",
        }}
        value={selectedPayment} // Controlled input
        onChange={(e) => setSelectedPayment(e.target.value)}
      >
        <option value="" disabled>
          Select Payment Type
        </option>
        <option value="Cash">Cash</option>
        <option value="Cheque">Cheque</option>
        <option value="UPI">UPI</option>
        <option value="Bank">Bank</option>
      </select>
    </div>
  </div>

  {/* Conditional Inputs */}
  {selectedPayment === "Cheque" && (
    <div className="col-md-6"> {/* Same row */}
      <div className="floating-label ">
        <label htmlFor="chequeId" className="form-label  mb-5 pb-5">
          Cheque ID
        </label>
        <input
          type="text"
          id="chequeId"
          className="form-control mt-1 ml-2"
          style={{
            borderRadius: "4px",
              border: "1px solid rgb(86, 4, 248)"
            // padding: "5px",
            // border: "1px solid #ccc",
          }}
          value={chequeId} // Controlled input
          onChange={(e) => setChequeId(e.target.value)} // Update state
        />
      </div>
    </div>
  )}

  {selectedPayment === "UPI" && (
    // <div className="col-md-3"> {/* Same row */}
    //   <div className="floating-label pb-5 mb-5">
    //     <label htmlFor="upiId" className="form-label">
    //       UPI ID
    //     </label>
    //     <input
    //       type="text"
    //       id="upiId"
    //       className="form-control mt-2 ml-2"
    //       style={{
    //         borderRadius: "4px",
    //         padding: "5px",
    //         border: "1px solid #ccc",
    //       }}
    //       value={upiId} // Controlled input
    //       onChange={(e) => setUpiId(e.target.value)} // Update state
    //     />
    //   </div>
    // </div>
    <div className="col-md-6"> {/* Same row */}
    <div className="floating-label ">
      <label htmlFor="upiId" className="form-label  mb-5 pb-5">
      UPI ID
      </label>
      <input
        type="text"
        id="upiId"
        className="form-control mt-1 ml-2"
        style={{
          borderRadius: "4px",
            border: "1px solid rgb(86, 4, 248)"
          // padding: "5px",
          // border: "1px solid #ccc",
        }}
        value={upiId} // Controlled input
        onChange={(e) => setUpiId(e.target.value)} // Update state
      />
    </div>
  </div>
  )}

  {selectedPayment === "Bank" && (
    // <div className="col-md-3"> {/* Same row */}
    //   <div className="floating-label">
    //     <label htmlFor="bankAccount" className="form-label pb-5 mb-5">
    //       Bank Account Number
    //     </label>
    //     <input
    //       type="text"
    //       id="bankAccount"
    //       className="form-control mt-2 ml-2"
    //       style={{
    //         borderRadius: "4px",
    //         padding: "5px",
    //         border: "1px solid #ccc",
    //       }}
    //       value={bankAccount} // Controlled input
    //       onChange={(e) => setBankAccount(e.target.value)} // Update state
    //     />
    //   </div>
    // </div>
    <div className="col-md-6"> {/* Same row */}
    <div className="floating-label ">
      <label htmlFor="bankAccount" className="form-label  mb-5 pb-5">
      Bank Account Number
      </label>
      <input
        type="text"
        id="bankAccount"
        className="form-control mt-1 ml-2"
        style={{
          borderRadius: "4px",
          border: "1px solid rgb(86, 4, 248)", backgroundColor: "white"
          // padding: "5px",
          // border: "1px solid #ccc",
        }}
        value={bankAccount} // Controlled input
        onChange={(e) => setBankAccount(e.target.value)} // Update state
      />
    </div>
  </div>
  )}
</div>

<br/>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <label
                          htmlFor="barcodeInput"
                          className="form-label  pb-5 mb-5"
                          style={{
                            display: "block",
                            // marginBottom: "8px",
                            fontSize: "16px",
                          //  padding: "15px",marginBottom:"30px" 
                          }}
                        >
                          Add Item With Barcode
                        </label>
                        <input
                          type="text"
                          id="barcodeInput"
                          className="form-control ml-2"
                          placeholder="Scan or enter barcode"
                          style={{
                            borderRadius: "4px",
                            padding: "10px",
                            fontSize: "14px",
                            fontStyle: "italic",border: "1px solid rgb(86, 4, 248)"
                          }}
                        />
                      </div>
                    </div>
                  </div>
</form>

<div>
<div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead
                      style={{ backgroundColor: "#3d4465", color: "#ffffff" }}
                    >
                      <tr style={{backgroundColor: "#3d4465", color: "white"}}>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>#</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Product</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>HSN/SAC</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Qty</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Price</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Tax (%)</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Discount</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Total</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Field</th>
                      </tr>
                    </thead>
                    <tbody>
        {rows.map((row, index) => (
          <tr key={row.id} onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")} // Hover color
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")} // Reset color
      >
            <td>{index+1}</td>
            <td>
  <div style={{ display: "flex", alignItems: "center", gap: "8px"}}>
  <select
  className="form-control"
  onChange={(e) => handleItemChange(index, e.target.value)}
  style={{ flex: "1", border: "1px solid rgb(86, 4, 248)" }}
  value={row.id || ""}
>
  <option value="" selected>Select Item</option>
  {items.map((item) => (
    <option key={item.id} value={item.id}>
      {item.item_name}
    </option>
  ))}
</select>

    <button
      type="button"
      className="btn"
      style={{
        backgroundColor: "#3d4465",
        color: "white",
        padding: "8px 12px",
        width:"40px"
      }}
      // onClick={() => setShowModalItem1(true)}
      data-bs-toggle="modal"
      data-bs-target="#showItem"
      // onClick={() => setShowModalItem(true)}
    >
      +
    </button>

     <div class="modal fade" id="showItem" tabindex="-1" aria-labelledby="exampleModalLabel" 
    //  aria-hidden="true"
     >
    <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <form 
          // onSubmit={handleSubmit} 
          className="needs-validation" noValidate autoComplete="off" id="modalItem">
            <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
            <div className="bs p-4 rounded mb-5" style={{ backgroundColor: '#3d4465' }}>
              <div className="row d-md-flex d-sm-block">
                <h5 className="col mt-3">
                  <strong className="fw-bolder text-white fs-3">ADD ITEM</strong>
                </h5>
                <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={clearModalInputs} 
                            ></button>
                {/* <a type="button" className="btn-close mr-5" data-dismiss="modal" aria-label="Close"
                onClick={closeModal1}><b><i class="fas fa-times"></i></b></a> */}
              </div>
              <br /><br />
              <div id="main_form_div" style={{ backgroundColor: 'white', padding: '3%' }}>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <p>Item Type:</p>
                    <select
                      style={{ color: 'black' }}
                      className="form-control border-dark bg-light"
                      // name="type"
                      // id="type"
                      // value={ItemData.type}
                      // onChange={SChangeItem}
                      value={itemType}
                      onChange={(e)=>setItemType(e.target.value)} name="item-type" id="" 
                      // onChange={(e) => setItemType(e.target.value)}
                      required
                    >
                      <option selected value="" hidden>Select Type</option>
                      <option value="Goods">Goods</option>
                            <option value="Service">Service</option>

                    </select>
                  </div>
                  <div className="col-md-3">
                  <p style={{color:'#3d4465'}} className=''>Item Name:</p>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="text"
                      value={itemName} 
                    onChange={(e) => handleItemNameChange(e.target.value)}   placeholder='Item Name' 

            
                      required
                    />
                 {errors3.itemName && (
  <small style={{ color: 'red' }}>{errors3.itemName}</small> // Render the error message
)}
                  </div>
                  <div className="col-md-3">
                  {itemType === "" && (
                            <div >
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)}  
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='border-dark form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                
                            </div>
                        )}
                    {itemType === "Goods" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)} 
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='item-input form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                            </div>
                        )}
                        {itemType === "Service" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item SAC :</p>
                                <input 
                                    value={sac} 
                                    onChange={(e)=>handleSacChange(e.target.value)}
                                    type="text" 
                                    placeholder='Item SAC' 
                                    className='item-input form-control ' 
                                />
                                {errors.sac && <small className="text-danger">{errors.sac}</small>}
                            </div>
                        )}</div>
                  {/* <div className="col-md-3">
                    <label id="hsnLabel">Item HSN:<br/><br/><br/><br/></label>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="number"
                      placeholder="ITEM HSN"
                      id="hsn"
                      // name="hsn"
                      name="item_hsn"
                      value={ItemData.item_hsn}
                      onChange={handleChangeItem}
     
                      // value={hsn}
                      // onChange={(e) => setHsn(e.target.value)}
                      required
                    />
                  </div> */}
                  <div className="col-md-3">
                    <p>Item Unit:</p>
                    <div className="d-flex">
                      <select
                        style={{ color: 'black' }}
                        className="form-control border-dark bg-light mr-1 "
                        id="units"
                        name="unit_name"  
                        value={ItemData.unit_name}
                        
                        // onChange={(e)=>setUnit(e.target.value)}  
                        onChange={handleChangeItem}
                        required
                      >
                                                       <option value="" disabled selected>Select Unit</option>
                                <option value="Packages" >Packages</option>
                                <option value="Numbers" >Numbers</option>
                                <option value="Box" >Box</option>
                                
                                {allUnit.map((itm)=>(
                                    <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                ))
                                    }
                                


                      </select>
                      <button type="button" 
                      className="btn " style={{ backgroundColor: 'rgb(60, 61, 101)' }}
                      data-toggle="modal" data-target="#exampleModal"
                      onClick={()=>{setModal1Open(true)}}
                       >
                         <span className="fa fa-plus" style={{ color: '#ffffff' }}
                          // aria-hidden="true"
                          ></span>
                      </button>
                      {isModal1Open &&(
      <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      // aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                            style={{ backgroundColor: "#3d4465" }}
                            class="modal-header"
                          >
                            <h1
                              className="modal-title text-white fw-bold fs-5"
                              id="exampleModalLabel"
                            >
                              Add Unit
                            </h1>
                            <button
                              type="button"
                              // class="btn-close"
                              // data-bs-dismiss="modal"
                              // aria-label="Close"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModalUnit}
                              // onClick={()=>{setModal1Open(false)}}
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div>
                                <p >New unit :</p>
                                <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                            </div>
                            {errors5.addunit && (
  <small style={{ color: 'red' }}>{errors5.addunit}</small> // Render the error message
)}
                          </div>
                          <div class="modal-footer">
                            {/* <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button> */}
                            <button style={{ background:"#3d4465" }} onClick={(e) => handleAddUnit(e)} type="button" className="btn btn-primary">
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>)}
                      {/* <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                            style={{ backgroundColor: "#3d4465" }}
                            class="modal-header"
                          >
                            <h1
                              className="modal-title text-white fw-bold fs-5"
                              id="exampleModalLabel"
                            >
                              Add Unit
                            </h1>
                            <button
                              type="button"
                              // class="btn-close"
                              // data-bs-dismiss="modal"
                              // aria-label="Close"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div>
                                <p >New unit :</p>
                                <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button style={{ background:"#3d4465" }} onClick={(e) => handleAddUnit(e)} type="button" className="btn btn-primary">
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="p-0 m-0" />
                  <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                    <div className="row pb-0 mb-0" >
                      <div className="col text-center pb-2" style={{ borderBottom: !showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }}>
                        <a className="fw-bolder fs-4 " 
                         onClick={() => setShowStock(false)}
                         style={{ color: showStock ? 'white' : 'black' , cursor: 'pointer'
                          
                          }}
                        // onClick={() => setActiveTab1('pricing')}
                        // style={{ color: showStock ? 'black' : 'white' }}

                         ><b>PRICING</b></a>
                      </div>
                      <div className="col text-center pb-2"style={{ borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }} >
                        <a className="fw-bolder fs-4" 
                                        // onClick={() => setActiveTab1('stocks')}
                                        onClick={() => setShowStock(true)}
                                        style={{ color: showStock ? 'black' : 'white' , cursor: 'pointer' ,
                                          // borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none'
                                        }} 
                        ><b>STOCKS</b></a>
                      </div>
                    </div>
                  </div>

                  {showStock ? (
                           <form action=""
                          //  onSubmit={handleSave} 
                           className='stocks-field p-4'>
                           <div className=' s-input'>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>Opening Stock</p>
                                   <input value={stockInHand}onChange={(e)=>setStockInHand(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>AT PRICE</p>
                                   <input value={atPrice} onChange={(e)=>setAtprice(e.target.value)} type="text" className='form-control'  />
                                 </div>
                             </div>
                             <div className='mt-3 s-input'>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>Date</p>
                                   <input onChange={(e)=>setDate(e.target.value)} type="date" defaultValue={new Date().toISOString().split("T")[0]}
                                     className='form-control'  />
                             </div>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>MINIMUM STOCK TO MAINTAIN</p>
                                   <input value={minStockMaintain} onChange={(e)=>setminStockMaintain(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 
                             </div>
                             <button 
  className="btn btn-primary btn-sm mb-1 pt-0 pb-0 mt-3" 
  type="button" 
  // onClick={() => openBarcode()} 
  title="ADD BARCODE"
>
  ADD <i className="fa fa-barcode"
  //  aria-hidden="true"
   ></i>
</button>
                           <div className='d-flex flex-wrap gap-3 mt-2 justify-content-center'>
                         

                 
                               {/* <button
                               onClick={(e) => handleSaveNew(e)}
                                 className='btn btn-primary mt-3'
                               >
                                 Save & New
                               </button> */}
                 
                               <button
                               type='submit'
                                 className='btn  btn-primary mt-3 w-25'   data-bs-dismiss="modal"
                              aria-label="Close"
                              style={{background:" #3d4465",color:"white"}}
                            
                                 onClick={(e) => handleSave(e)}
                               >
                                Save
                               </button>
                           </div>
                         </form>
                 
                  ) : (
                    <form action=""
                    // onSubmit={handleSave} 
                    className='pricing-field p-4'>
                    {/* Radio Buttons */}
                    <div className='d-flex flex-wrap gap-5'>
                    <div className="form-check d-flex align-items-center gap-5">
  <input
    className="form-check-input"
    type="radio"
    name="flexRadioDefault"
    id="flexRadioDefault1"
    checked={isTaxable}
    onChange={() => setIsTaxable(true)}
  />
  <label className="form-check-label text-black ml-4" htmlFor="flexRadioDefault1">
    Taxable
  </label>
</div>

                      <div className="form-check d-flex align-items-center gap-5">
                        <input
                          className="form-check-input ml-3"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={!isTaxable}
                          onChange={() => setIsTaxable(false)}
                        />
                        <p className="form-check-label text-black ml-5" htmlFor="flexRadioDefault2">
                          Non Taxable
                        </p>
                      </div>
                    </div>
          
                    {/* Conditional Fields */}
                    {isTaxable ? (
                      <form onSubmit={handleSave} className='fields-taxable mt-5'>
                        <div className=' taxable '>
                          <div className='w-50'>
                            <p className='text-black fs-5'>GST</p>
                            <select value={gst} onChange={(e)=>setGst(e.target.value)} className='form-control non-t-i'>
                              <option>GST@0%</option>
                              <option>GST@3%</option>
                              <option>GST@5%</option>
                              <option>GST@12%</option>
                              <option>GST@18%</option>
                              <option>GST@28%</option>
                            </select>
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>IGST</p>
                            <select value={igst} onChange={(e)=>setIgst(e.target.value)} className='form-control non-t-i'>
                              <option>IGST@0%</option>
                              <option>IGST@3%</option>
                              <option>IGST@5%</option>
                              <option>IGST@12%</option>
                              <option>IGST@18%</option>
                              <option>IGST@28%</option>
                            </select>
                          </div>
                        </div>
          
                        <form onSubmit={handleSave} className=' taxable mt-4'>
                          <div className='w-50'>
                            <p className='text-black fs-5'>SALES PRICE</p>
                            <input value={salesPrice} onChange={(e)=>setSalesPrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>PURCHASE PRICE</p>
                            <input value={purchasePrice} onChange={(e)=>setPurchasePrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                        </form>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}

                        >
                          Next
                        </button>
                      </form>
                    ) : (
                      <form className='fields-non-taxable mt-5'>
                        <div className='d-flex non-t gap-3'>
                          <div className='w-50'>
                            <p className='text-black fs-5 sale-p'>SALES PRICE</p>
                            <input value={nonTaxableSalePrice}  onChange={(e)=>setnonTaxableSalePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Sales Price" />
                          </div>
                          <div className='w-50 '>
                            <p className='text-black fs-5 sale-p'>PURCHASE PRICE</p>
                            <input value={nonTaxablePurchasePrice}  onChange={(e)=>setnonTaxablePurchasePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Purchase Price" />
                          </div>
                        </div>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}
                        >
                          Next
                        </button>
                      </form>
                    )}
                  </form>
          
                  )}
                </div>
               
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  
    {showModalItem1 && (  <div className="modal fade show" id="exampleModal123" aria-modal="true" style={{ display: 'block',overflow:"auto" }}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <form 
          // onSubmit={handleSubmit} 
          className="needs-validation" noValidate autoComplete="off" id="modalItem">
            <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
            <div className="bs p-4 rounded mb-5" style={{ backgroundColor: '#3d4465' }}>
              <div className="row d-md-flex d-sm-block">
                <h5 className="col mt-3">
                  <strong className="fw-bolder text-white fs-3">ADD ITEM</strong>
                </h5>
                <a type="button" className="btn-close mr-5" data-dismiss="modal" aria-label="Close"
                onClick={closeModal1}><b><i class="fas fa-times"></i></b></a>
              </div>
              <br /><br />
              <div id="main_form_div" style={{ backgroundColor: 'white', padding: '3%' }}>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <p>Item Type:</p>
                    <select
                      style={{ color: 'black' }}
                      className="form-control border-dark bg-light"
                      // name="type"
                      // id="type"
                      // value={ItemData.type}
                      // onChange={handleChangeItem}
                      value={itemType}
                      onChange={(e)=>setItemType(e.target.value)} name="item-type" id="" 
                      // onChange={(e) => setItemType(e.target.value)}
                      required
                    >
                      <option selected value="" hidden>Select Type</option>
                      <option value="Goods">Goods</option>
                            <option value="Service">Service</option>

                    </select>
                  </div>
                  <div className="col-md-3">
                  <p style={{color:'#3d4465'}} className=''>Item Name:</p>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="text"
                      value={itemName} 
                    onChange={(e) => handleItemNameChange(e.target.value)}   placeholder='Item Name' 

            
                      required
                    />
                    {errors3.itemName && (
  <small style={{ color: 'red' }}>{errors3.itemName}</small> // Render the error message
)}
                  </div>
                  <div className="col-md-3">
                  {itemType === "" && (
                            <div >
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)}  
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='border-dark form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                
                            </div>
                        )}
                    {itemType === "Goods" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)} 
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='item-input form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                            </div>
                        )}
                        {itemType === "Service" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item SAC :</p>
                                <input 
                                    value={sac} 
                                    onChange={(e)=>handleSacChange(e.target.value)}
                                    type="text" 
                                    placeholder='Item SAC' 
                                    className='item-input form-control ' 
                                />
                                {errors.sac && <small className="text-danger">{errors.sac}</small>}
                            </div>
                        )}</div>
                  {/* <div className="col-md-3">
                    <label id="hsnLabel">Item HSN:<br/><br/><br/><br/></label>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="number"
                      placeholder="ITEM HSN"
                      id="hsn"
                      // name="hsn"
                      name="item_hsn"
                      value={ItemData.item_hsn}
                      onChange={handleChangeItem}
     
                      // value={hsn}
                      // onChange={(e) => setHsn(e.target.value)}
                      required
                    />
                  </div> */}
                  <div className="col-md-3">
                    <p>Item Unit:</p>
                    <div className="d-flex">
                      <select
                        style={{ color: 'black' }}
                        className="form-control border-dark bg-light"
                        id="units"
                        name="unit_name"  
                        value={ItemData.unit_name}
                        onChange={(e)=>setUnit(e.target.value)}  
                        // onChange={handleChangeItem}
                        required
                      >
                                                       <option value="" disabled selected>Select Unit</option>
                                <option value="Packages" >Packages</option>
                                <option value="Numbers" >Numbers</option>
                                <option value="Box" >Box</option>
                                
                                {allUnit.map((itm)=>(
                                    <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                ))
                                    }
                                


                      </select>
                      <button type="button" className="btn" style={{ backgroundColor: 'rgb(60, 61, 101)', height: '6vh' }} 
                      data-toggle="modal"  data-target="#exampleModal"
                      onClick={()=>{setModal1Open1(true)}}>
                        <span className="fa fa-plus" style={{ color: '#ffffff' }} 
                        // aria-hidden="true"
                        ></span>
                      </button>
                      {isModal1Open1 &&(   
                         <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      // aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                            style={{ backgroundColor: "#3d4465" }}
                            class="modal-header"
                          >
                            <h1
                              className="modal-title text-white fw-bold fs-5"
                              id="exampleModalLabel"
                            >
                              Add Unit
                            </h1>
                            <button
                              type="button"
                              // class="btn-close"
                              // data-bs-dismiss="modal"
                              // aria-label="Close"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModalUnit}
                              // onClick={()=>{setModal1Open1(false)}}
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div>
                                <p>New unit :</p>
                                <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                            </div>
                            {errors5.addunit && (
  <small style={{ color: 'red' }}>{errors5.addunit}</small> // Render the error message
)}
                          </div>
                          <div class="modal-footer">
                            {/* <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button> */}
                            <button style={{ background:"#3d4465" }} onClick={(e) => handleAddUnit(e)} type="button" class="btn btn-close btn-primary"
                                
                                data-bs-dismiss="modal">
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>)}
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="p-0 m-0" />
                  <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                    <div className="row pb-0 mb-0" >
                      <div className="col text-center pb-2" style={{ borderBottom: !showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }}>
                        <a className="fw-bolder fs-4 " 
                         onClick={() => setShowStock(false)}
                         style={{ color: showStock ? 'white' : 'black' , cursor: 'pointer'
                          
                          }}
                        // onClick={() => setActiveTab1('pricing')}
                        // style={{ color: showStock ? 'black' : 'white' }}

                         ><b>PRICING</b></a>
                      </div>
                      <div className="col text-center pb-2"style={{ borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }} >
                        <a className="fw-bolder fs-4" 
                                        // onClick={() => setActiveTab1('stocks')}
                                        onClick={() => setShowStock(true)}
                                        style={{ color: showStock ? 'black' : 'white' , cursor: 'pointer' ,
                                          // borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none'
                                        }} 
                        ><b>STOCKS</b></a>
                      </div>
                    </div>
                  </div>

                  {showStock ? (
                           <form 
                          //  onSubmit={handleSave}
                            className='stocks-field p-4'>
                           <div className=' s-input'>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>Opening Stock</p>
                                   <input value={stockInHand}onChange={(e)=>setStockInHand(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>AT PRICE</p>
                                   <input value={atPrice} onChange={(e)=>setAtprice(e.target.value)} type="text" className='form-control'  />
                                 </div>
                             </div>
                             <div className='mt-3 s-input'>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>Date</p>
                                   <input onChange={(e)=>setDate(e.target.value)} type="date" defaultValue={new Date().toISOString().split("T")[0]}
                                     className='form-control'  />
                             </div>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>MINIMUM STOCK TO MAINTAIN</p>
                                   <input value={minStockMaintain} onChange={(e)=>setminStockMaintain(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 
                             </div>
                             <button 
  className="btn btn-primary btn-sm mb-1 pt-0 pb-0 mt-3" 
  type="button" 
  // onClick={() => openBarcode()} 
  title="ADD BARCODE"
>
  ADD <i className="fa fa-barcode" aria-hidden="true"></i>
</button>
                           <div className='d-flex flex-wrap gap-3 mt-2 justify-content-center'>
                         

                 
                               {/* <button
                               onClick={(e) => handleSaveNew(e)}
                                 className='btn btn-primary mt-3'
                               >
                                 Save & New
                               </button> */}
                 
                               <button
                               type='submit'
                                
                                 className='btn  mt-3'   data-bs-dismiss="modal"
                              aria-label="Close"
                                 onClick={(e) => handleSave(e)}style={{background:" #3d4465",color:"white"}}
                               >
                                Save
                               </button>
                           </div>
                         </form>
                 
                  ) : (
                    <form 
                    // onSubmit={handleSave} 
                    className='pricing-field p-4'>
                    {/* Radio Buttons */}
                    <div className='d-flex flex-wrap gap-4'>
                      <div className="form-check d-flex  align-items-center">
                        <input
                          className="form-check-input ml-5"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={isTaxable}
                          onChange={() => setIsTaxable(true)}
                        />
                        <p className="form-check-label text-black" htmlFor="flexRadioDefault1">
                          Taxable
                        </p>
                      </div>
                      <div className="form-check d-flex align-items-center ml-5">
                        <input
                          className="form-check-input ml-5"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={!isTaxable}
                          onChange={() => setIsTaxable(false)}
                        />
                        <p className="form-check-label text-black" htmlFor="flexRadioDefault2">
                          Non Taxable
                        </p>
                      </div>
                    </div>
          
                    {/* Conditional Fields */}
                    {isTaxable ? (
                      <form 
                      // onSubmit={handleSave} 
                      className='fields-taxable mt-5'>
                        <div className=' taxable '>
                          <div className='w-50'>
                            <p className='text-black fs-5'>GST</p>
                            <select value={gst} onChange={(e)=>setGst(e.target.value)} className='form-control non-t-i'>
                              <option>GST@0%</option>
                              <option>GST@3%</option>
                              <option>GST@5%</option>
                              <option>GST@12%</option>
                              <option>GST@18%</option>
                              <option>GST@28%</option>
                            </select>
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>IGST</p>
                            <select value={igst} onChange={(e)=>setIgst(e.target.value)} className='form-control non-t-i'>
                              <option>IGST@0%</option>
                              <option>IGST@3%</option>
                              <option>IGST@5%</option>
                              <option>IGST@12%</option>
                              <option>IGST@18%</option>
                              <option>IGST@28%</option>
                            </select>
                          </div>
                        </div>
          
                        <form 
                        // onSubmit={handleSave} 
                        className=' taxable mt-4'>
                          <div className='w-50'>
                            <p className='text-black fs-5'>SALES PRICE</p>
                            <input value={salesPrice} onChange={(e)=>setSalesPrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>PURCHASE PRICE</p>
                            <input value={purchasePrice} onChange={(e)=>setPurchasePrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                        </form>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}

                        >
                          Next
                        </button>
                      </form>
                    ) : (
                      <form className='fields-non-taxable mt-5'>
                        <div className='d-flex non-t gap-3'>
                          <div className='w-50'>
                            <p className='text-black fs-5 sale-p'>SALES PRICE</p>
                            <input value={nonTaxableSalePrice}  onChange={(e)=>setnonTaxableSalePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Sales Price" />
                          </div>
                          <div className='w-50 '>
                            <p className='text-black fs-5 sale-p'>PURCHASE PRICE</p>
                            <input value={nonTaxablePurchasePrice}  onChange={(e)=>setnonTaxablePurchasePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Purchase Price" />
                          </div>
                        </div>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}
                        >
                          Next
                        </button>
                      </form>
                    )}
                  </form>
          
                  )}
                </div>
               
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>)}
  </div>
</td>

            <td> <input className="form-control "  value={row.item_hsn !== null ? row.item_hsn : row.item_sac} 
            /></td>
            <td>
              <input
                type="number"
                value={row.qty} 
                onChange={(e) => handleQuantityChange(index, e.target.value)}
                className="form-control "
                min="1"
              />
            </td>
            <td>
              <input
                type="number"
                value={row.item_sale_price}
                onChange={(e) => handlePriceChange(index, e.target.value)}
                className="form-control" readOnly style={{background:"white"}}
                min="1"
              />
            </td>
            <td>
            {/* {row.tax} */}
            <input className="form-control" value= {row.tax}/>
              
            </td>
            <td> <input
                type="number"
                value={row.discount}
                onChange={(e) => handleDiscountChange(index, e.target.value)}
                className="form-control"
                min="1"
              /></td>
            <td><input className="form-control" value={row.total}/></td>
            <td> <i class="fas fa-xmark text-danger"onClick={() => handleRemoveRow(index)} ></i></td>
          </tr>
        ))}
      </tbody>
                  </table>
                  <button
                    className="btn  mb-4"
                    onClick={addItem}
                    style={{
                      borderRadius: "4px",
                      fontWeight: "bold",
                      backgroundColor: "#3d4465",
                      color: "white",
                    }}
                  >
                    +
                  </button>
                </div>
                <div className="row mt-4 ">
                  <div className="col-md-6 p-3" style={{ backgroundColor: "#3d4465", color: "black"}}>
                  <table className="table">
                      <tbody style={{ backgroundColor: "#3d4465", color: "black"}}>
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Sub Total</td>
                          {/* <td className="text-end text-light"> */}
                        <input
                          type="text"
                          className="form-control"
                          id="SubTotal"
                          value={subTotal} readOnly
                          style={{ borderRadius: "4px",marginleft: "12px",background:"white" }}
                        />
                      </tr>
                        {/* </td> */}
                        {/* </tr>
                        <tr>
                          <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "black" }}>CGST</td>
                          {/* <td className="text-end text-light"> */}
                        {/* <input
                          type="text"
                          className="form-control"
                          id="cgst"  value={cgst}
                          readOnly
                          style={{ borderRadius: "8px",marginleft: "12px",padding: "15px" }}
                        /> */}
                        {/* </td> */}
                        {/* </tr>
                        <tr>
                          <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "black" }}>SGST</td>
                          {/* <td className="text-end text-light"> */}
                        {/* <input
                          type="text"
                          className="form-control"
                          id="sgst"  value={sgst}
                          readOnly
                          style={{ borderRadius: "8px",marginleft: "12px",padding: "15px" }}
                        /> */}
                        {/* </td> */}
                        {/* </tr> */} 
                        {stateOfSupply === "State" && ( 
  <>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        CGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="cgst"
          value={cgst} // Display CGST value
          readOnly
          style={{background:"white"}}
          // style={{
          //   borderRadius: "4px",
          //   // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
          //   // padding: "15px"
          // }}
        />
      {/* </td> */}
      
    </tr>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        SGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="sgst"
          value={sgst} // Display SGST value
          readOnly
          style={{
            background:"white"}}
          //   // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
          //   // padding: "15px"
          // }}
        />
      {/* </td> */}
    </tr>
  </>
)}

{/* Conditionally render IGST if stateOfSupply is "otherState" */}

  {stateOfSupply === "otherState" && (
    <tr>
    <td 
      className="text-light" 
      style={{ border: "none", backgroundColor: "#3d4465", color: "black" }}
    >
      IGST
    </td>
    {/* <td> */}
      <input
        type="text"
        className="form-control"
        id="igst"
        value={taxAmount} // Display IGST value
        readOnly
        style={{
          background:"white"}}
        //   // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
        //   // padding: "15px"
        // }}
      />
    {/* </td> */}
    </tr>
)}
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Tax Amount</td>
                          <input
                          type="text"
                          className="form-control"
                          id="sgst"  value={taxAmount}
                          readOnly
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Adjustment</td>
                          <input
                          type="text"
                          className="form-control"
                          id="adjustment" value={adjustment} onChange={handleAdjustmentChange}
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          {/* <td> */}
                            <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "black" }}>Grand Total</td>
                          {/* </td> */}
                          <input
                          type="text"
                          className="form-control"
                          id="gtotal" value={grandTotal}
                          readOnly
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          {/* <td> */}
                            <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Description</td>
                          {/* </td> */}
                          <input
                          type="text"
                          className="form-control"
                          value={description}  onChange={handleDecriptionChange}
                          style={{ marginleft: "12px",padding: "15px" }}
                        />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                 
                </div>
                <div
    className="d-flex flex-wrap justify-content-start mt-4 p-2"
    style={{ gap: "10px" }}
  >
    {/* <button
      className="btn btn-sm"
      onClick={(e) => handleSubmit(e, "/show_invoice")}
      style={{ background: "#3d4465", color: "white" }}
    >
      Save
    </button>

    <button
      className="btn btn-sm"
      onClick={(e) => handleSubmit(e)}
      style={{ background: "#3d4465", color: "white" }}
    >
      Save and Next
    </button> */}
    <button
  className="btn btn-sm"
  onClick={(e) => handleSubmit(e, true)} // Pass true for navigation
  style={{ background: "#3d4465", color: "white" }}
>
  Save
</button>

<button
  className="btn btn-sm"
  onClick={(e) => handleSubmit(e, false)} // Pass false for reload
  style={{ background: "#3d4465", color: "white" }}
>
  Save and Next
</button>

  </div>
        </div> 
              </div>
            ) : (
              <div >
                
                {/* Show 'Add Invoice' when the button is off */}

                {/* Invoice Form */}
                <form >
                  <div className="row mb-3">
                    <div className="col-md-4 mb-4">
                      <div className="floating-label " >
                        
                        <label htmlFor="invoiceNo" className="mb-5 pb-5" >Invoice No</label>
                        <input
                          type="text"
                          className="form-control ml-2 "
                          id="invoiceNo"
                          value={invoiceNo}
                          readOnly
                          // placeholder="Enter Invoice No"
                          // defaultValue="1"
                          // className="pt-5 mb-5 pl-2"
                          style={{ borderRadius: "4px",marginleft: "12px",padding: "15px" ,border: "1px solid rgb(86, 4, 248)",background:"white" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="floating-label">
                      <label htmlFor="date" className="mb-5 pb-5 ">Date</label>
                    
                        <input
                          type="date"
                          className="form-control ml-2"
                          id="date"
                          defaultValue={new Date().toISOString().split("T")[0]}
                          style={{ borderRadius: "4px",padding: "15px" ,border: "1px solid rgb(86, 4, 248)"}}
                        />
                          </div>
                    </div>
                    <div className="col-md-4">
                      <div className="floating-label">
                      <label htmlFor="stateOfSupply" className="mb-5 pb-5">State Of Supply</label>
                    
                        <select
                          className="form-control ml-2"
                          id="stateOfSupply"
                          value={stateOfSupply}
                          style={{ borderRadius: "4px" ,border: "1px solid rgb(86, 4, 248)"}}
                          onChange={handleStateOfSupplyChange}
                        >
                          <option value="State">State</option>
                          <option value="otherState">Other State</option>
                        </select>
                         </div>
                    </div>
                  </div>
                
                  <div className="row mb-2 d-flex">
  {/* Payment Type */}
  <div className="col-md-6 mb-2"> {/* Adjusted width */}
    <div className="floating-label">
      <label htmlFor="paymentType" className="form-label mb-5 pb-5 ">
        Payment Type
      </label>
      <select
        className="form-select mt-2 ml-2"
        id="paymentType"
        style={{
          borderRadius: "4px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid rgb(86, 4, 248)",
        }}
        value={selectedPayment} // Controlled input
        onChange={(e) => setSelectedPayment(e.target.value)}
      >
        <option value="" disabled>
          Select Payment Type
        </option>
        <option value="Cash">Cash</option>
        <option value="Cheque">Cheque</option>
        <option value="UPI">UPI</option>
        <option value="Bank">Bank</option>
      </select>
    </div>
  </div>

  {/* Conditional Inputs */}
  {selectedPayment === "Cheque" && (
    // <div className="col-md-6"> {/* Same row */}
    //   <div className="floating-label ">
    //     <label htmlFor="chequeId" className="form-label  mb-5 pb-5">
    //       Cheque ID
    //     </label>
    //     <input
    //       type="text"
    //       id="chequeId"
    //       className="form-control mt-2 ml-2"
    //       style={{
    //         // borderRadius: "4px",
    //         // padding: "5px",
    //         // border: "1px solid #ccc",
    //         borderRadius: "4px",
    //           // padding: "10px",
    //           width:"250px",
    //           fontSize: "16px",border: "1px solid rgb(86, 4, 248)", backgroundColor: "white",
    //       }}
    //       value={chequeId} // Controlled input
    //       onChange={(e) => setChequeId(e.target.value)} // Update state
    //     />
    //   </div>
    // </div>
    <div className="col-md-6"> {/* Same row */}
    <div className="floating-label ">
      <label htmlFor="chequeId" className="form-label  mb-5 pb-5">
        Cheque ID
      </label>
      <input
        type="text"
        id="chequeId"
        className="form-control mt-1 ml-2"
        style={{
          borderRadius: "4px",
          // padding: "5px",
          // border: "1px solid #ccc",
            border: "1px solid rgb(86, 4, 248)"
        }}
        value={chequeId} // Controlled input
        onChange={(e) => setChequeId(e.target.value)} // Update state
      />
    </div>
  </div>
  )}

  {selectedPayment === "UPI" && (
    // <div className="col-md-6"> {/* Same row */}
    //   <div className="floating-label pb-5 mb-5">
    //     <label htmlFor="upiId" className="form-label">
    //       UPI ID
    //     </label>
    //     <input
    //       type="text"
    //       id="upiId"
    //       className="form-control mt-2 ml-2"
    //       style={{
    //         borderRadius: "4px",
    //         // padding: "5px",
    //         // border: "1px solid #ccc",
    //          width:"550px"
    //       }}
    //       value={upiId} // Controlled input
    //       onChange={(e) => setUpiId(e.target.value)} // Update state
    //     />
    //   </div>
    // </div>
    <div className="col-md-6"> {/* Same row */}
    <div className="floating-label ">
      <label htmlFor="upiId" className="form-label  mb-5 pb-5">
      UPI ID
      </label>
      <input
        type="text"
        id="upiId"
        className="form-control mt-1 ml-2"
        style={{
          borderRadius: "4px",
          // padding: "5px",
          // border: "1px solid #ccc",
            border: "1px solid rgb(86, 4, 248)"
        }}
        value={upiId} // Controlled input
        onChange={(e) => setUpiId(e.target.value)} // Update state
      />
    </div>
  </div>
  )}

  {selectedPayment === "Bank" && (
    // <div className="col-md-6"> {/* Same row */}
    //   <div className="floating-label">
    //     <label htmlFor="bankAccount" className="form-label pb-5 mb-5">
    //       Bank Account Number
    //     </label>
    //     <input
    //       type="text"
    //       id="bankAccount"
    //       className="form-control mt-2 ml-2"
    //       style={{
    //         borderRadius: "4px",
    //         // padding: "5px",
    //         // border: "1px solid #ccc",
    //         width:"550px"
    //       }}
    //       value={bankAccount} // Controlled input
    //       onChange={(e) => setBankAccount(e.target.value)} // Update state
    //     />
    //   </div>
    // </div>
    <div className="col-md-6"> {/* Same row */}
    <div className="floating-label ">
      <label htmlFor="bankAccount" className="form-label  mb-5 pb-5">
      Bank Account Number
      </label>
      <input
        type="text"
        id="bankAccount"
        className="form-control mt-1 ml-2"
        style={{
          borderRadius: "4px",
            border: "1px solid rgb(86, 4, 248)"
          // padding: "5px",
          // border: "1px solid #ccc",
        }}
        value={bankAccount} // Controlled input
        onChange={(e) => setBankAccount(e.target.value)} // Update state
      />
    </div>
  </div>
  )}
</div>

<br/>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <label
                          htmlFor="barcodeInput"
                          className="form-label  pb-5 mb-5"
                          style={{
                            display: "block",
                            // marginBottom: "8px",
                            fontSize: "16px",
                          //  padding: "15px",marginBottom:"30px" 
                          }}
                        >
                          Add Item With Barcode
                        </label>
                        <input
                          type="text"
                          id="barcodeInput"
                          className="form-control ml-2"
                          placeholder="Scan or enter barcode"
                          style={{
                            borderRadius: "4px",
                            padding: "10px",
                            fontSize: "14px",
                            fontStyle: "italic",border: "1px solid rgb(86, 4, 248)"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>

                {/* Invoice Table */}
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead
                      style={{ backgroundColor: "#3d4465", color: "#ffffff" }}
                    >
                      <tr style={{backgroundColor: "#3d4465", color: "white"}}>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>#</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Product</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>HSN/SAC</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Qty</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Price</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Tax (%)</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Discount</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Total</th>
                        <th style={{backgroundColor: "#3d4465", color: "white"}}>Field</th>
                      </tr>
                    </thead>
                    <tbody>
        {rows.map((row, index) => (
          <tr key={row.id} onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")} // Hover color
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")} // Reset color
      >
            <td>{index+1}</td>
            <td>
  <div style={{ display: "flex", alignItems: "center", gap: "8px"}}>
    <select
      className="form-control"
      onChange={(e) => handleItemChange(index, e.target.value)}
      style={{ flex: "1" ,  border: "1px solid rgb(86, 4, 248)"}} /* Make the select box take up available space */
      value={row.id || ""}
    >
      <option value="" selected>Select Item</option>
      {items.map((item) => (
        <option key={item.id} value={item.id}>
          {item.item_name}
        </option>
      ))}
    </select>
    <button
      type="button"
      className="btn"
      style={{
        backgroundColor: "#3d4465",
        color: "white",
        padding: "8px 12px",
      }}
      // onClick={() => setShowModalItem1(true)}
      data-bs-toggle="modal"
      data-bs-target="#showItem"
    >
      +
    </button>
    <div class="modal fade" id="showItem" tabindex="-1" aria-labelledby="exampleModalLabel" 
    // aria-hidden="true"
    >
    <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <form 
          // onSubmit={handleSubmit} 
          className="needs-validation" noValidate autoComplete="off" id="modalItem">
            <input type="hidden" name="csrfmiddlewaretoken" value="your-csrf-token" />
            <div className="bs p-4 rounded mb-5" style={{ backgroundColor: '#3d4465' }}>
              <div className="row d-md-flex d-sm-block">
                <h5 className="col mt-3">
                  <strong className="fw-bolder text-white fs-3">ADD ITEM</strong>
                </h5>
                <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={clearModalInputs} 
                            ></button>
                {/* <a type="button" className="btn-close mr-5" data-dismiss="modal" aria-label="Close"
                onClick={closeModal1}><b><i class="fas fa-times"></i></b></a> */}
              </div>
              <br /><br />
              <div id="main_form_div" style={{ backgroundColor: 'white', padding: '3%' }}>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <p>Item Type:</p>
                    <select
                      style={{ color: 'black' }}
                      className="form-control border-dark bg-light"
                      // name="type"
                      // id="type"
                      // value={ItemData.type}
                      // onChange={handleChangeItem}
                      onChange={(e)=>setItemType(e.target.value)} name="item-type" id="" 
                      // onChange={(e) => setItemType(e.target.value)}
                      value={itemType}
                      required
                    >
                      <option selected value="" hidden>Select Type</option>
                      <option value="Goods">Goods</option>
                            <option value="Service">Service</option>

                    </select>
                  </div>
                  <div className="col-md-3">
                  <p style={{color:'#3d4465'}} className=''>Item Name:</p>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="text"
                      value={itemName} 
                    onChange={(e) => handleItemNameChange(e.target.value)}   placeholder='Item Name' 

            
                      required
                    />
                   {errors3.itemName && (
  <small style={{ color: 'red' }}>{errors3.itemName}</small> // Render the error message
)}
                  </div>
                  <div className="col-md-3">
                  {itemType === "" && (
                            <div >
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)}  
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='border-dark form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                
                            </div>
                        )}
                    {itemType === "Goods" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)} 
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='item-input form-control ' 
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                            </div>
                        )}
                        {itemType === "Service" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item SAC :</p>
                                <input 
                                    value={sac} 
                                    onChange={(e)=>handleSacChange(e.target.value)}
                                    type="text" 
                                    placeholder='Item SAC' 
                                    className='item-input form-control ' 
                                />
                                {errors.sac && <small className="text-danger">{errors.sac}</small>}
                            </div>
                        )}</div>
                  {/* <div className="col-md-3">
                    <label id="hsnLabel">Item HSN:<br/><br/><br/><br/></label>
                    <input
                      style={{ color: 'black' }}
                      className="form-control border-dark"
                      type="number"
                      placeholder="ITEM HSN"
                      id="hsn"
                      // name="hsn"
                      name="item_hsn"
                      value={ItemData.item_hsn}
                      onChange={handleChangeItem}
     
                      // value={hsn}
                      // onChange={(e) => setHsn(e.target.value)}
                      required
                    />
                  </div> */}
                  <div className="col-md-3">
                    <p>Item Unit:</p>
                    <div className="d-flex">
                      <select
                        style={{ color: 'black' }}
                        className="form-control border-dark bg-light mr-1 "
                        id="units"
                        name="unit_name"  
                        value={ItemData.unit_name}
                        // onChange={(e)=>setUnit(e.target.value)}  
                        onChange={handleChangeItem}
                        required
                      >
                                                       <option value="" disabled selected>Select Unit</option>
                                <option value="Packages" >Packages</option>
                                <option value="Numbers" >Numbers</option>
                                <option value="Box" >Box</option>
                                
                                {allUnit.map((itm)=>(
                                    <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                ))
                                    }
                                


                      </select>
                      <button type="button" className="btn" style={{ backgroundColor: 'rgb(60, 61, 101)' ,height:"40px" }} data-toggle="modal"  data-target="#exampleModal"
                            onClick={()=>{setModal1Open1(true)}}>
                        <span className="fa fa-plus" style={{ color: '#ffffff' }} aria-hidden="true"></span>
                      </button>
                      {isModal1Open1 &&(<div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      // aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                            style={{ backgroundColor: "#3d4465" }}
                            class="modal-header"
                          >
                            <h1
                              className="modal-title text-white fw-bold fs-5"
                              id="exampleModalLabel"
                            >
                              Add Unit
                            </h1>
                            <button
                              type="button"
                             
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModalUnit}
                              // onClick={()=>{setModal1Open1(false)}}
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div>
                                <p>New unit :</p>
                                <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                            </div>
                            {errors5.addunit && (
  <small style={{ color: 'red' }}>{errors5.addunit}</small> // Render the error message
)}
                          </div>
                          <div class="modal-footer">
                            {/* <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button> */}
                            <button style={{ background:"#3d4465" }} onClick={(e) => handleAddUnit(e)} type="button" class="btn btn-primary">
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>)}
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="p-0 m-0" />
                  <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                    <div className="row pb-0 mb-0" >
                      <div className="col text-center pb-2" style={{ borderBottom: !showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }}>
                        <a className="fw-bolder fs-4 " 
                         onClick={() => setShowStock(false)}
                         style={{ color: showStock ? 'white' : 'black' , cursor: 'pointer'
                          
                          }}
                        // onClick={() => setActiveTab1('pricing')}
                        // style={{ color: showStock ? 'black' : 'white' }}

                         ><b>PRICING</b></a>
                      </div>
                      <div className="col text-center pb-2"style={{ borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none' }} >
                        <a className="fw-bolder fs-4" 
                                        // onClick={() => setActiveTab1('stocks')}
                                        onClick={() => setShowStock(true)}
                                        style={{ color: showStock ? 'black' : 'white' , cursor: 'pointer' ,
                                          // borderBottom: showStock ?'3px solid rgba(0, 0, 0, 0.55)' :'none'
                                        }} 
                        ><b>STOCKS</b></a>
                      </div>
                    </div>
                  </div>

                  {showStock ? (
                           <form onSubmit={handleSave} className='stocks-field p-4'>
                           <div className=' s-input'>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>Opening Stock</p>
                                   <input value={stockInHand}onChange={(e)=>setStockInHand(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 <div className='w-50'>
                                   <p className='text-black fs-5'>AT PRICE</p>
                                   <input value={atPrice} onChange={(e)=>setAtprice(e.target.value)} type="text" className='form-control'  />
                                 </div>
                             </div>
                             <div className='mt-3 s-input'>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>Date</p>
                                   <input onChange={(e)=>setDate(e.target.value)} type="date" defaultValue={new Date().toISOString().split("T")[0]}
                                     className='form-control'  />
                             </div>
                             <div className='w-50'>
                                   <p className='text-black fs-5'>MINIMUM STOCK TO MAINTAIN</p>
                                   <input value={minStockMaintain} onChange={(e)=>setminStockMaintain(e.target.value)} type="text" className='form-control'  />
                                 </div>
                                 
                             </div>
                             <button 
  className="btn btn-primary btn-sm mb-1 pt-0 pb-0 mt-3" 
  type="button" 
  // onClick={() => openBarcode()} 
  title="ADD BARCODE"
>
  ADD <i className="fa fa-barcode" aria-hidden="true"></i>
</button>
                           <div className='d-flex flex-wrap gap-3 mt-2 justify-content-center'>
                         

                 
                               {/* <button
                               onClick={(e) => handleSaveNew(e)}
                                 className='btn btn-primary mt-3'
                               >
                                 Save & New
                               </button> */}
                 
                               <button
                               type='submit'
                                 className='btn  mt-3'  
                                  data-bs-dismiss="modal"
                              aria-label="Close"
                                 onClick={(e) => handleSave(e)}style={{background:" #3d4465",color:"white"}}
                               >
                                Save
                               </button>
                           </div>
                         </form>
                 
                  ) : (
                    <form onSubmit={handleSave} className='pricing-field p-4'>
                    {/* Radio Buttons */}
                    <div className='d-flex flex-wrap gap-5'>
                    <div className="form-check d-flex align-items-center gap-5">
  <input
    className="form-check-input"
    type="radio"
    name="flexRadioDefault"
    id="flexRadioDefault1"
    checked={isTaxable}
    onChange={() => setIsTaxable(true)}
  />
  <label className="form-check-label text-black ml-4" htmlFor="flexRadioDefault1">
    Taxable
  </label>
</div>

                      <div className="form-check d-flex align-items-center gap-5">
                        <input
                          className="form-check-input ml-3"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={!isTaxable}
                          onChange={() => setIsTaxable(false)}
                        />
                        <p className="form-check-label text-black ml-5" htmlFor="flexRadioDefault2">
                          Non Taxable
                        </p>
                      </div>
                    </div>
          
                    {/* Conditional Fields */}
                    {isTaxable ? (
                      <form onSubmit={handleSave} className='fields-taxable mt-5'>
                        <div className=' taxable '>
                          <div className='w-50'>
                            <p className='text-black fs-5'>GST</p>
                            <select value={gst} onChange={(e)=>setGst(e.target.value)} className='form-control non-t-i'>
                              <option>GST@0%</option>
                              <option>GST@3%</option>
                              <option>GST@5%</option>
                              <option>GST@12%</option>
                              <option>GST@18%</option>
                              <option>GST@28%</option>
                            </select>
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>IGST</p>
                            <select value={igst} onChange={(e)=>setIgst(e.target.value)} className='form-control non-t-i'>
                              <option>IGST@0%</option>
                              <option>IGST@3%</option>
                              <option>IGST@5%</option>
                              <option>IGST@12%</option>
                              <option>IGST@18%</option>
                              <option>IGST@28%</option>
                            </select>
                          </div>
                        </div>
          
                        <form onSubmit={handleSave} className=' taxable mt-4'>
                          <div className='w-50'>
                            <p className='text-black fs-5'>SALES PRICE</p>
                            <input value={salesPrice} onChange={(e)=>setSalesPrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                          <div className='w-50'>
                            <p className='text-black fs-5'>PURCHASE PRICE</p>
                            <input value={purchasePrice} onChange={(e)=>setPurchasePrice(e.target.value)} type="text" className='form-control non-t-i' />
                          </div>
                        </form>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}

                        >
                          Next
                        </button>
                      </form>
                    ) : (
                      <form className='fields-non-taxable mt-5'>
                        <div className='d-flex non-t gap-3'>
                          <div className='w-50'>
                            <p className='text-black fs-5 sale-p'>SALES PRICE</p>
                            <input value={nonTaxableSalePrice}  onChange={(e)=>setnonTaxableSalePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Sales Price" />
                          </div>
                          <div className='w-50 '>
                            <p className='text-black fs-5 sale-p'>PURCHASE PRICE</p>
                            <input value={nonTaxablePurchasePrice}  onChange={(e)=>setnonTaxablePurchasePrice(e.target.value)} type="text" className='form-control non-t-i' placeholder="Enter Purchase Price" />
                          </div>
                        </div>
                        <button
                          className='btn  mt-3'
                          onClick={() => setShowStock(true)} style={{background:" #3d4465",color:"white"}}
                          // onClick={() => setActiveTab1('stocks')}
                        >
                          Next
                        </button>
                      </form>
                    )}
                  </form>
          
                  )}
                </div>
               
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    {/*  */}
  </div>
</td>

            <td> <input className="form-control" value={row.item_hsn !== null ? row.item_hsn : row.item_sac} /></td>
            <td>
              <input
                type="number"
                value={row.qty}
                onChange={(e) => handleQuantityChange(index, e.target.value)}
                className="form-control"
                min="1"
              />
            </td>
            <td>
              <input
                type="number"
                value={row.item_sale_price}
                onChange={(e) => handlePriceChange(index, e.target.value)}
                className="form-control" readOnly style={{background:"white"}}
                min="1" 
              />
            </td>
            <td>
            {/* {row.tax} */}
            <input className="form-control" value= {row.tax}/>
              
            </td>
            <td> <input
                type="number"
                value={row.discount}
                onChange={(e) => handleDiscountChange(index, e.target.value)}
                className="form-control"
                min="1"
              /></td>
            <td><input className="form-control" value={row.total}/></td>
            <td> <i class="fas fa-xmark text-danger"onClick={() => handleRemoveRow(index)} ></i></td>
          </tr>
        ))}
      </tbody>
                  </table>
                  <button
                    className="btn  mb-4"
                    onClick={addItem}
                    style={{
                      borderRadius: "4px",
                      fontWeight: "bold",
                      backgroundColor: "#3d4465",
                      color: "white",
                    }}
                  >
                    +
                  </button>
                </div>

                {/* Summary Section */}
                <div className="row mt-4 ">
                  <div className="col-md-6 p-3" style={{ backgroundColor: "#3d4465", color: "black"}}>
                  <table className="table">
                      <tbody style={{ backgroundColor: "#3d4465", color: "black"}}>
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Sub Total</td>
                          {/* <td className="text-end text-light"> */}
                        <input
                          type="text"
                          className="form-control"
                          id="SubTotal"
                          value={subTotal} readOnly
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        {/* </td> */}
                        </tr>
                        {stateOfSupply === "State" && ( 
  <>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        CGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="cgst"
          value={cgst} // Display CGST value
          readOnly
          style={{
            background:"white"}}
            // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
            // padding: "15px"
          // }}
        />
      {/* </td> */}
    </tr>
    <tr>
      <td 
        className="text-light" 
        style={{ border: 'none', backgroundColor: "#3d4465", color: "black" }}
      >
        SGST
      </td>
      {/* <td> */}
        <input
          type="text"
          className="form-control"
          id="sgst"
          value={sgst} // Display SGST value
          readOnly
          style={{
            background:"white"}}
            // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
            // padding: "15px"
          // }}
        />
      {/* </td> */}
    </tr>
  </>
)}

{/* Conditionally render IGST if stateOfSupply is "otherState" */}
{stateOfSupply === "otherState" && (
  <tr>
    <td 
      className="text-light" 
      style={{ border: "none", backgroundColor: "#3d4465", color: "black" }}
    >
      IGST
    </td>
    {/* <td> */}
      <input
        type="text"
        className="form-control"
        id="igst"
        value={taxAmount} // Display IGST value
        readOnly
        style={{
          background:"white"}}
          // marginLeft: "12px", // Fixed typo (marginleft -> marginLeft)
          // padding: "15px"
        // }}
      />
    {/* </td> */}
  </tr>
)}

                      
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Tax Amount</td>
                          <input
                          type="text"
                          className="form-control"
                          id="sgst"  value={taxAmount}
                          readOnly
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Adjustment</td>
                          <input
                          type="text"
                          className="form-control"
                          id="adjustment" value={adjustment} onChange={handleAdjustmentChange}
                          style={{ 
                            // borderRadius: "8px",
                            marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          {/* <td> */}
                            <td className="text-light"  style={{ border: 'none',backgroundColor: "#3d4465", color: "black" }}>Grand Total</td>
                          {/* </td> */}
                          <input
                          type="text"
                          className="form-control"
                          id="gtotal" value={grandTotal}
                          readOnly
                          style={{ marginleft: "12px",background:"white" }}
                        />
                        </tr>
                        <tr>
                          {/* <td> */}
                            <td className="text-light"  style={{ border: 'none' ,backgroundColor: "#3d4465", color: "black"}}>Description</td>
                          {/* </td> */}
                          <input
                          type="text"
                          className="form-control"
                          value={description}  onChange={handleDecriptionChange}
                          style={{ marginleft: "12px",padding: "15px" }}
                        />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                 
                </div>
                {/* <div className="d-flex justify-content-start mt-4 p-2" style={{ gap: "10px" }}> */}
                <div
    className="d-flex flex-wrap justify-content-start mt-4 p-2"
    style={{ gap: "10px" }}
  > 
  {/* <button
    className="btn "
    // onClick={handlesave()}
    onClick={(e) => handleSubmit(e, '/show_invoice')} 
    style={{background:" #3d4465",color:"white"}}
  >
    Save
  </button>
  
  <button
    className="btn " style={{background:" #3d4465",color:"white"}}
    // onClick={(e) => handleSubmit(e)}
    onClick={(e) => handleSubmit(e)}
  >
    Save and Next
  </button> */}
   <button
  className="btn btn-sm"
  onClick={(e) => handleSubmit(e, true)} // Pass true for navigation
  style={{ background: "#3d4465", color: "white" }}
>
  Save
</button>

<button
  className="btn btn-sm"
  onClick={(e) => handleSubmit(e, false)} // Pass false for reload
  style={{ background: "#3d4465", color: "white" }}
>
  Save and Next
</button>
</div>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Invoice;
